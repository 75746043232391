import React, {type FC, useMemo} from 'react';
import {type LearnState, isMastered, objectSize, wordsRecordToArray} from '@englex/trainer';

import PageControls from 'components/PageControls';

import {ContinueOrExitBtn} from '../../WriteTrainer/components';

interface Props {
  listId: string;
  state: LearnState;
  studentId: number;
  continueCallback(): void;
}

export const ContinueOrExit: FC<Props> = ({continueCallback, listId, state, studentId}) => (
  <PageControls pullRight={true}>
    <ContinueOrExitBtn
      callback={continueCallback}
      completedCount={useMemo(
        () => wordsRecordToArray(state.words).filter(isMastered).length,
        [state.words]
      )}
      listId={listId}
      studentId={studentId}
      total={objectSize(state.words)}
    />
  </PageControls>
);
