import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import React, {type FC} from 'react';

import Icon from 'components/Icon';
import './Counts.scss';

interface Props {
  className?: string;
  count: number;
}

export const IncorrectCount: FC<Props> = ({children, className, count}) => (
  <div className={classNames('count-component count-incorrect', className)}>
    <Icon name="minus-circle" />
    <span className="title">
      <FormattedMessage id="Trainer.Incorrect" />:
    </span>
    {count}
    {children}
  </div>
);

export const CorrectCount: FC<Props> = ({className, count}) => (
  <div className={classNames('count-component count-correct', className)}>
    <Icon name="plus-circle" />
    <span className="title">
      <FormattedMessage id="Trainer.Correct" />:
    </span>
    {count}
  </div>
);

export const RemainingCount: FC<Props> = ({children, className, count}) => (
  <div className={classNames('count-component count-remaining', className)}>
    <Icon name="circle-o" />
    <span className="title">
      <FormattedMessage id="Trainer.Remaining" />:
    </span>
    {count}
    {children}
  </div>
);

export const InProgressCount: FC<Props> = ({children, className, count}) => (
  <div className={classNames('count-component count-in-progress', className)}>
    <Icon name="dot-circle-o" />
    <span className="title">
      <FormattedMessage id="Trainer.InProgress" />:
    </span>
    {count}
    {children}
  </div>
);

export const MasteredCount: FC<Props> = ({className, count}) => (
  <div className={classNames('count-component count-mastered', className)}>
    <Icon name="check-circle-o" />
    <span className="title">
      <FormattedMessage id="Trainer.Learned" />:
    </span>
    {count}
  </div>
);
