import {useMemo} from 'react';
import {compose} from 'redux';
import {stripWordArticle, stripWordVerbParticle, stripWordCurlyBrackets} from '@englex/trainer';
import {toLowerCase} from '@englex/utils';

import {type DictionaryEntryInstance, type ListSort} from 'components/Dictionary/shared/interface';

const fromCoursebookFilter =
  (search: URLSearchParams) =>
  (entries: DictionaryEntryInstance[]): DictionaryEntryInstance[] => {
    const fromCoursebookParam = search.get('fromCoursebook') || '';
    if (fromCoursebookParam === 'true') return entries.filter(e => !!e.exerciseInstanceId);
    if (fromCoursebookParam === 'false') return entries.filter(e => !e.exerciseInstanceId);
    return entries;
  };

const searchFilter = (search: URLSearchParams) => (entries: DictionaryEntryInstance[]) => {
  const filter = search.get('search')?.toLowerCase().trim() || '';
  return !filter
    ? entries
    : entries.filter(
        e =>
          e.dictionaryEntry.original.toLowerCase().includes(filter) ||
          e.dictionaryEntry.translation.toLowerCase().includes(filter)
      );
};

const sortEntries =
  (listSort: ListSort) =>
  (entries: DictionaryEntryInstance[]): DictionaryEntryInstance[] => {
    const entriesCopy = [...entries];
    switch (listSort) {
      case 'newest_at_the_bottom':
        return entries;
      case 'newest_at_the_top':
        return entriesCopy.reverse();
      case 'alphabetic':
      default:
        const stripWord = compose(
          stripWordVerbParticle,
          stripWordArticle,
          stripWordCurlyBrackets,
          toLowerCase
        );
        return entriesCopy.sort((l, r) => {
          const {original: leftO, translation: leftT} = l.dictionaryEntry;
          const {original: rightO, translation: rightT} = r.dictionaryEntry;
          return (
            stripWord(leftO).localeCompare(stripWord(rightO)) ||
            stripWord(leftT).localeCompare(stripWord(rightT))
          );
        });
    }
  };
export const useListMutator = (
  wordEntries: DictionaryEntryInstance[],
  search: string,
  listSort: ListSort
): DictionaryEntryInstance[] =>
  useMemo(() => {
    const searchParams = new URLSearchParams(search);
    if (!wordEntries.length) return [];
    return compose(
      sortEntries(listSort),
      searchFilter(searchParams),
      fromCoursebookFilter(searchParams)
    )(wordEntries);
  }, [listSort, search, wordEntries]);
