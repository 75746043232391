import React, {type FC, useCallback, useContext, useEffect, useMemo, memo} from 'react';
import {useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import classNames from 'classnames';

import {type AppState, type Profile, type Role} from 'store/interface';
import PageControls from 'components/PageControls';
import {CreateEntryButton} from 'components/Dictionary/shared/CreateEntry/CreateEntryButton';
import {EditEntryForm} from 'components/Dictionary/shared/CreateEntry/EditEntryForm';
import {AddEntryToListModal} from 'components/Dictionary/shared/CreateEntry/AddEntryToListModal/AddEntryToListModal';
import {
  clearDeleteEntry,
  exitSelectionMode
} from 'components/Dictionary/shared/contexts/entry/actions';
import {useDeleteEntryFromDictionary} from 'components/Dictionary/hooks/useDeleteEntryFromDictionary';
import {
  EditEntryActionsContext,
  EditEntryStateContext
} from 'components/Dictionary/shared/contexts/entry/EditEntryContext';
import {SelectionControls} from 'components/Dictionary/shared/SelectionControls';
import {useDeleteEntryFromList} from 'components/Dictionary/hooks/useDeleteEntryFromList';
import Confirm from 'components/modals/Confirm';
import * as toastr from 'components/toastr';
import {
  type DictionaryEntryInstance,
  type DictionaryList
} from 'components/Dictionary/shared/interface';
import {useSearchingInODContext} from 'components/Dictionary/Sidebar/DictionaryArticle/contexts/searchingInODContext';

import {TrainListButton} from './ListPage/TrainListButton';
import {SharingButton} from '../../../components/ui/SharingButton/SharingButton';
import {clipboardCopy} from '../../../helpers/navigator';
import {useSentChatMessage} from '../../../hooks/chat/useSentChatMessage';

enum SharingButtonActions {
  Copy = 'copy',
  Send = 'send'
}

interface Props {
  entries: DictionaryEntryInstance[];
  hasLists: boolean;
  listId?: string;
  listEntryCount?: number;
  studentId: number;
  isTeacherDictionary: boolean;
  dictionaryIsReadonly?: boolean;
}

interface UserProps {
  userId: number;
  role: Role;
}

export const EntryActions: FC<Props> = memo(
  ({
    entries,
    hasLists,
    listId,
    listEntryCount,
    studentId,
    isTeacherDictionary,
    dictionaryIsReadonly
  }) => {
    const intl = useIntl();

    const {send} = useSentChatMessage();

    const {isSearchingInOD} = useSearchingInODContext();

    const {userId, role} = useSelector<AppState, UserProps>(state => ({
      userId: state.user.id!,
      role: state.user.role!
    }));

    const list = useSelector<AppState, DictionaryList | undefined>(s =>
      s.dictionary?.lists?.find(l => l.id === listId)
    );
    const {listToDeleteFromId, entryToDeleteId, selectionMode, showForm, deletingFromListPage} =
      useContext(EditEntryStateContext);
    const {dispatch} = useContext(EditEntryActionsContext);

    const showCreateEntry = useMemo(() => {
      if (userId === studentId || !list || list?.exerciseInstanceId) return true;
      return list?.createdById === userId;
    }, [userId, studentId, list]);

    const sharingButtonItems = useMemo(() => {
      return {
        [SharingButtonActions.Copy]: {
          value: intl.formatMessage({id: 'XPlayer.Grammar.CopyLinkToClipboard'}),
          icon: 'paste'
        },
        [SharingButtonActions.Send]: {
          value: intl.formatMessage({id: 'XPlayer.Grammar.SendLinkToChat'}),
          icon: 'comment-o'
        }
      };
    }, [intl]);

    const deleteClear = useCallback(() => dispatch(clearDeleteEntry()), [dispatch]);

    const [deletingEntryFromList, deleteEntryFromList] = useDeleteEntryFromList(
      () => {
        toastr.success(
          '',
          intl.formatMessage({id: 'Dictionary.Toastr.EntryDeletedFromListSuccess'})
        );
        deleteClear();
      },
      () =>
        toastr.error('', intl.formatMessage({id: 'Dictionary.Toastr.EntryDeletedFromListError'})),
      listToDeleteFromId,
      entryToDeleteId ? [entryToDeleteId] : undefined
    );

    const [deletingEntryFromDictionary, deleteEntryFromDictionary] = useDeleteEntryFromDictionary(
      () => {
        toastr.success(
          '',
          deletingFromListPage
            ? intl.formatMessage({id: 'Dictionary.Toastr.EntryDeletedFromDictionaryAndListSuccess'})
            : intl.formatMessage({id: 'Dictionary.Toastr.EntryDeletedFromDictionarySuccess'})
        );
        deleteClear();
      },
      () =>
        toastr.error(
          '',
          deletingFromListPage
            ? intl.formatMessage({id: 'Dictionary.Toastr.EntryDeletedFromDictionaryAndListError'})
            : intl.formatMessage({id: 'Dictionary.Toastr.EntryDeletedFromDictionaryError'})
        ),
      entryToDeleteId
    );

    const onChangeSharingButton = useCallback(
      (key: SharingButtonActions) => {
        if (key === SharingButtonActions.Copy) {
          clipboardCopy(window.location.href)
            .then(() =>
              toastr.success('', intl.formatMessage({id: 'Dictionary.Toastr.CopyTextSuccess'}))
            )
            .catch(() =>
              toastr.error('', intl.formatMessage({id: 'Dictionary.Toastr.CopyTextError'}))
            );
        }

        if (key === SharingButtonActions.Send) {
          const message = `[${list?.title || 'Glossary'}](${window.location.href})`;

          send(message)
            .then((profile: Profile) => {
              const {first_name: firstName, last_name: lastName} = profile;

              toastr.success(
                '',
                intl.formatMessage(
                  {id: 'XPlayer.Grammar.SendLinkToChat.SuccessToastr'},
                  {lastName, firstName}
                )
              );
            })
            .catch(() => {
              toastr.error(
                '',
                intl.formatMessage({id: 'XPlayer.Grammar.SendLinkToChat.ErrorToastr'})
              );
            });
        }
      },
      [intl, send, list]
    );

    const submitting = deletingEntryFromList || deletingEntryFromDictionary;

    useEffect(() => {
      if (!entries.length && selectionMode) {
        dispatch(exitSelectionMode());
      }
    }, [dispatch, entries.length, selectionMode]);

    return (
      <>
        <PageControls
          className={classNames({
            'pull-right': isSearchingInOD,
            'selection-mode': !!selectionMode,
            'should-hide': showForm
          })}
        >
          <div className="left-group">
            {!selectionMode && showCreateEntry && (
              <>
                {!isSearchingInOD && !dictionaryIsReadonly && (
                  <CreateEntryButton listEntryCount={listEntryCount} listId={listId} />
                )}
                {!!(listEntryCount && listId && userId === studentId) && !isSearchingInOD && (
                  <TrainListButton
                    listId={listId}
                    listEntryCount={listEntryCount}
                    studentId={studentId}
                  />
                )}
              </>
            )}
            <SharingButton
              items={sharingButtonItems}
              isHidden={
                Boolean(selectionMode) ||
                role !== 'teacher' ||
                isSearchingInOD ||
                isTeacherDictionary
              }
              onChange={onChangeSharingButton}
            />
          </div>
          {!!entries.length && !isSearchingInOD && !dictionaryIsReadonly && (
            <SelectionControls
              hasLists={hasLists}
              entries={entries}
              listId={listId}
              deletionForbidden={!showCreateEntry}
              role={role}
              isTeacherDictionary={isTeacherDictionary}
            />
          )}
        </PageControls>
        {showForm && <EditEntryForm />}
        <AddEntryToListModal listId={listId} />
        <Confirm
          headerText={intl.formatMessage({id: 'Dictionary.DeleteEntryFromListModalHeader'})}
          disableButtons={submitting}
          onAccept={deleteEntryFromList}
          onDecline={deleteClear}
          show={!!(listToDeleteFromId && entryToDeleteId)}
        />
        <Confirm
          headerText={
            deletingFromListPage
              ? intl.formatMessage({id: 'Dictionary.DeleteEntryFromDictionaryAndListModalHeader'})
              : intl.formatMessage({id: 'Dictionary.DeleteEntryFromDictionaryModalHeader'})
          }
          disableButtons={submitting}
          onAccept={deleteEntryFromDictionary}
          onDecline={deleteClear}
          show={!!(!listToDeleteFromId && entryToDeleteId)}
        />
      </>
    );
  }
);
