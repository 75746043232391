import React, {type FC} from 'react';
import classNames from 'classnames';

import Icon from 'components/Icon';

interface Props {
  correct?: boolean;
  i: number;
  makeChoice(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  value: string;
}

export const Choice: FC<Props> = ({correct, i, makeChoice, value}) => {
  const id = `multiple-choice-index-${i}`;
  const longer = value.length >= 128;
  const long = value.length >= 64 && !longer;
  return (
    <div
      key={i}
      id={id}
      className={classNames('choice', {correct, long, longer})}
      onClick={makeChoice}
    >
      <div className="hint" id={id}>
        {correct ? <Icon name="check" /> : i + 1}
      </div>
      <div className="text">{value}</div>
    </div>
  );
};
