import React, {type FC, useCallback, useContext, useEffect, useState, memo} from 'react';
import {FormattedMessage} from 'react-intl';
import {Navigate, useOutletContext} from 'react-router-dom';
import {
  setCurrentTrainerType,
  TrainerActionsContext,
  TrainerContext,
  useDisableTrainer,
  LEARN,
  type LearnState,
  LearnStatus,
  setMeta,
  type TrainerType
} from '@englex/trainer';

import Icon from 'components/Icon';
import PageControls from 'components/PageControls';
import {useDictionaryContext} from 'components/Dictionary/shared/contexts';

import {generateKey, trainerPathCreator} from '../../utils';
import {RefreshButton, StartScreen, TrainerLayout} from '../shared';
import {
  CardsHint,
  ContinueOrExit,
  FirstHint,
  LearnResults,
  LearnTrainerStage,
  SecondHint
} from './components';
import {type TrainerOutletContext} from '../interface';

export const LearnTrainer: FC = memo(() => {
  const listEntriesCount = useOutletContext<TrainerOutletContext>()?.list?.entryCount;
  const {listId, studentId, trainerState} = useContext(TrainerContext);
  const {dispatch} = useContext(TrainerActionsContext);
  const {dictionaryOwnerRole} = useDictionaryContext();

  const {learn: state, meta, loading} = trainerState;

  const skip = Boolean(meta.skipStartScreen?.learn);

  const [key, setKey] = useState(generateKey());
  const [started, setStarted] = useState(skip);
  const [showResults, setShowResults] = useState(false);

  const shouldRedirect = useDisableTrainer(!!state, listEntriesCount || 0);

  const startRound = useCallback(() => {
    dispatch(setMeta({...meta, skipStartScreen: {...meta.skipStartScreen, learn: true}}));
    setShowResults(false);
    setStarted(true);
    setKey(generateKey());
  }, [dispatch, meta]);

  const finishRoundCallback = useCallback(() => setShowResults(true), []);

  const setSkipStartScreen = useCallback(
    (type: TrainerType) =>
      dispatch(setMeta({...meta, skipStartScreen: {...meta.skipStartScreen, [type]: true}})),
    [dispatch, meta]
  );

  useEffect(() => dispatch(setCurrentTrainerType(LEARN)), [dispatch]);

  if (shouldRedirect)
    return (
      <Navigate replace={true} to={trainerPathCreator(studentId, listId, dictionaryOwnerRole)} />
    );

  const page = getPageFromState(showResults, started, state);

  const isLoading = loading || trainerState.currentType !== LEARN;

  return (
    <TrainerLayout className="learn">
      {page === 'start-page' ? (
        <StartScreen
          CardsHint={CardsHint}
          FirstHint={FirstHint}
          SecondHint={SecondHint}
          loading={isLoading}
          trainerState={trainerState}
          continueCallback={startRound}
          stateExists={!!state}
          setStarted={setStarted}
          setSkipStartScreen={setSkipStartScreen}
        />
      ) : (
        <>
          {page === 'final-page' && <LearnResults words={state!.words} />}
          {page === 'stage-page' && (
            <LearnTrainerStage key={key} state={state!} finishRound={finishRoundCallback} />
          )}
          <PageControls>
            <RefreshButton onRefreshed={startRound}>
              <Icon name="undo" />
              <FormattedMessage id="Trainer.StartOver" />
            </RefreshButton>
          </PageControls>
          {!!(page === 'final-page' && state) && (
            <ContinueOrExit
              continueCallback={startRound}
              state={state}
              listId={listId}
              studentId={studentId}
            />
          )}
        </>
      )}
    </TrainerLayout>
  );
});

const getPageFromState = (
  showResults: boolean,
  started: boolean,
  state?: LearnState
): 'start-page' | 'final-page' | 'stage-page' => {
  if (!state) return 'start-page';

  if (showResults) return 'final-page';

  const {words} = state;
  for (const id in words) {
    if (words[id].status !== LearnStatus.mastered) {
      return started ? 'stage-page' : 'start-page';
    }
  }

  return 'final-page';
};
