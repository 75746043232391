import React, {type ComponentType, type FC, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {type Immutable} from 'immer';
import {type LearnWord, isMastered, isRemaining, wordsRecordToArray} from '@englex/trainer';

import {InProgressCount, MasteredCount, RemainingCount, ResultsPage} from '../../shared';

interface Props {
  words: Immutable<Record<string, LearnWord>>;
}

type Key = 'remaining' | 'inProgress' | 'mastered';

export const LearnResults: FC<Props> = ({words}) => {
  const intl = useIntl();

  const getDefaultKey = (): Key => {
    const wordsArray = wordsRecordToArray(words);
    const notMastered = wordsArray.filter(w => !isMastered(w));
    if (!notMastered.length) return 'mastered';
    return notMastered.filter(isRemaining).length ? 'remaining' : 'inProgress';
  };

  const map = useMemo(
    () =>
      wordsRecordToArray(words).reduce<
        Record<Key, {ids: string[]; LabelComponent: ComponentType<{count: number}>}>
      >(
        (acc, w) => {
          isMastered(w)
            ? acc.mastered.ids.push(w.id)
            : isRemaining(w)
              ? acc.remaining.ids.push(w.id)
              : acc.inProgress.ids.push(w.id);
          return acc;
        },
        {
          remaining: {ids: [], LabelComponent: RemainingCount},
          inProgress: {ids: [], LabelComponent: InProgressCount},
          mastered: {ids: [], LabelComponent: MasteredCount}
        }
      ),
    [words]
  );

  return (
    <ResultsPage<Key>
      congratulations={intl.formatMessage({id: 'Trainer.Learn.Congratulations'})}
      defaultKey={getDefaultKey()}
      map={map}
      targetKey="mastered"
      words={words}
    />
  );
};
