import React, {type FC, useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';

import {longTextBoundary} from '../../static';
import './IncorrectInfo.scss';

interface Props {
  original: string;
  value: string;
}

export const IncorrectInfo: FC<Props> = ({original, value}) => {
  const breakAll = useMemo(
    () => value.split(' ').reduce((breakAll, word) => breakAll || word.length > 30, false),
    [value]
  );
  return (
    <div className="incorrect-info">
      {!!value.length && (
        <div className="incorrect">
          <div className="title">
            <FormattedMessage id="Trainer.YouAnswered" />:
          </div>
          <div
            className={classNames('text', {
              long: value.length > longTextBoundary,
              'break-all': breakAll
            })}
          >
            {value}
          </div>
        </div>
      )}
      <div className="correct">
        <div className="title">
          <FormattedMessage id="Trainer.CorrectAnswer" />:
        </div>
        <div className={classNames('text', {long: original.length > longTextBoundary})}>
          {original}
        </div>
      </div>
    </div>
  );
};
