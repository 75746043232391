import React, {type FC, useMemo} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {sortWordsAlphabetically, type Word} from '@englex/trainer';

import logoGray from 'components/CoursebookContentsViewer/assets/logo-gray.svg';

import {WordComponent} from './WordComponent';
import './List.scss';

interface Props {
  ids: readonly string[];
  words: Record<string, Word>;
}

const renderWord = (word: Word) => <WordComponent key={word.id} word={word} />;

export const List: FC<Props> = ({ids, words}) => {
  const sortedWords = useMemo(
    () => sortWordsAlphabetically(ids.map(id => words[id])),
    [ids, words]
  );
  return (
    <Scrollbars autoHide={true} className="results-scrollbar">
      {sortedWords.length ? (
        <div className="results-canvas">
          <div className="results-list">{sortedWords.map(renderWord)}</div>
        </div>
      ) : (
        <div className="results-no-entries">
          <img src={logoGray} alt="" />
        </div>
      )}
    </Scrollbars>
  );
};
