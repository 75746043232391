import React, {type FC, useCallback, useEffect} from 'react';
import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import isHotkey from 'is-hotkey';

import Icon from 'components/Icon';

import './Footer.scss';

interface Props {
  correct?: boolean;
  valueIsEmpty: boolean;
  check(): void;
  ff(): void;
  submit(forceCorrect?: true): void;
}

export const Footer: FC<Props> = React.memo(({correct, valueIsEmpty, check, ff, submit}) => {
  const untouched = correct === undefined;
  const incorrect = correct === false;
  const incorrectWithValue = incorrect && !valueIsEmpty;

  const onSubmit = useCallback(() => {
    if (incorrect || (correct && !valueIsEmpty)) return submit();
    if (untouched) return check();
  }, [check, correct, incorrect, submit, untouched, valueIsEmpty]);

  const resubmit = useCallback(() => {
    if (incorrect) submit(true);
    if (untouched) ff();
  }, [ff, incorrect, submit, untouched]);

  const keyboardListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.repeat) return;

      if (isHotkey('enter', e)) {
        const target = e.target as HTMLElement;
        if (
          ['TEXTAREA', 'INPUT', 'BUTTON', 'A'].includes(target?.tagName) &&
          !target.className.includes('flashcard-textarea')
        ) {
          return;
        }

        onSubmit();
      }
    },
    [onSubmit]
  );

  useEffect(() => {
    document.addEventListener('keydown', keyboardListener);
    return () => document.removeEventListener('keydown', keyboardListener);
  }, [keyboardListener]);

  return (
    <div className="footer">
      <div className="left">
        {(untouched || incorrectWithValue) && (
          <Button
            className={classNames('btn-link', {green: incorrect, red: untouched})}
            onClick={resubmit}
          >
            <Icon name="exclamation-circle" />
            {incorrectWithValue && <FormattedMessage id="Trainer.IWasRight" />}
            {untouched && <FormattedMessage id="Trainer.IDontKnow" />}
          </Button>
        )}
      </div>
      <Button
        className="submit"
        bsStyle={correct ? 'success' : 'primary'}
        bsSize="lg"
        onClick={onSubmit}
      >
        {untouched ? (
          <FormattedMessage id="Trainer.Answer" />
        ) : correct ? (
          <>
            <FormattedMessage id="Trainer.Correct" />
            {'!'}
          </>
        ) : (
          <FormattedMessage id="Trainer.Continue" />
        )}
      </Button>
    </div>
  );
});
