import React, {memo} from 'react';
import MenuItem from 'react-bootstrap/lib/MenuItem';

import Icon from 'components/Icon';

import styles from './DropdownItem.module.scss';

interface Props {
  iconName: string;
  text: string;
  onClick: () => void;
}

export const DropdownItem: React.FC<Props> = memo(({iconName, text, onClick}) => {
  return (
    <MenuItem onClick={onClick}>
      <span className={styles.dropdownItem}>
        <Icon name={iconName} />
        <span>{text}</span>
      </span>
    </MenuItem>
  );
});
