import React, {type FC, useCallback, useRef, useState} from 'react';
import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';

import PronunciationSoundPlayer, {
  type IntlPronunciationSoundPlayer,
  type PronunciationPlayerStatus
} from 'components/XPlayer/widgets/Vocabulary/PronunciationSoundPlayer';
import Icon from 'components/Icon';

import './PronunciationBtn.scss';

interface Props {
  pronunciationUrl: string;
}

export const PronunciationBtn: FC<Props> = ({pronunciationUrl}) => {
  const [playStatus, setPlayStatus] = useState<PronunciationPlayerStatus>(null);
  const playerRef = useRef<IntlPronunciationSoundPlayer | null>(null);

  const playPronunciation = useCallback((e: React.MouseEvent<Button, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    playerRef.current?.playSound();
  }, []);

  return (
    <PronunciationSoundPlayer
      ref={playerRef}
      onStatusChange={setPlayStatus}
      soundUrl={pronunciationUrl}
    >
      <Button className={classNames('pronunciation-btn', playStatus)} onClick={playPronunciation}>
        <Icon name="volume-up" />
      </Button>
    </PronunciationSoundPlayer>
  );
};
