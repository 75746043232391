import React, {type FC, useCallback, useEffect, useMemo, useRef, useState, memo} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import {splitSearchByFirstBraces} from '@englex/trainer';

import {requestDictionaryList} from 'store/dictionary/requests';
import {type AppState} from 'store/interface';
import {setListEntries} from 'store/dictionary/actions';
import {useApiRequest} from 'hooks/rest/useApiRequest';
import {type AxiosRequestError} from 'services/axios/interface';
import {PageNotFound} from 'routes/PageNotFound/components/PageNotFound';
import Loader from 'components/Loader';
import {
  type DictionaryEntryInstance,
  type DictionaryList,
  type DictionaryListWithEntries,
  type ExerciseInstance,
  type ListSort
} from 'components/Dictionary/shared/interface';
import {WordEntry} from 'components/Dictionary/shared/WordsList/WordEntry';
import {WordsList} from 'components/Dictionary/shared/WordsList/WordsList';
import WampErrorMask from 'components/WampErrorMask';
import {NoEntries} from 'components/Dictionary/shared/NoEntries';
import {useSearchingInODContext} from 'components/Dictionary/Sidebar/DictionaryArticle/contexts/searchingInODContext';
import {DictionaryArticle} from 'components/Dictionary/Sidebar/DictionaryArticle/DictionaryArticle';
import {useDictionaryContext} from 'components/Dictionary/shared/contexts';

import {EntryActions} from '../EntryActions';
import {useListMutator} from './useListMutator';
import ListHeader from './ListHeader';
import {useHasLists} from '../../useHasLists';
import './ListPage.scss';

export const ListPage: FC = memo(() => {
  const {search} = useLocation();
  const {listId} = useParams<{listId: string}>();
  const {isTeacherDictionary, dictionaryOwnerId, setError} = useDictionaryContext();

  const hasLists = useHasLists();

  const userId = useSelector<AppState, number>(s => s.user.id!);
  const dictionaryIsReadonly =
    useSelector<AppState, boolean>(s => !!s.studentTeachers?.isInactive) && !isTeacherDictionary;
  const list = useSelector<AppState, DictionaryList | undefined>(s =>
    s.dictionary?.lists?.find(l => l.id === listId)
  );
  const wordEntries = useSelector<AppState, DictionaryEntryInstance[]>(
    s => s.dictionary?.listEntries || []
  );
  const listSort = useSelector<AppState, ListSort>(s => s.dictionary?.listSort || 'alphabetic');

  const [show404, setShow404] = useState(false);
  const [exerciseInfo, setExerciseInfo] = useState<ExerciseInstance | undefined>();

  const dispatch = useDispatch();

  const mutatedWordEntries = useListMutator(wordEntries, search, listSort);

  const scrollbarRef = useRef<Scrollbars | null>(null);

  const {isSearchingInOD, isSearching} = useSearchingInODContext();

  const request = useMemo(
    () =>
      requestDictionaryList(listId!, [
        'entryInstances',
        'image',
        'exerciseInstance.unitInstance.unit.coursebook'
      ]),
    [listId]
  );
  const wordSearch = useMemo(() => {
    const s = new URLSearchParams(search).get('search')?.trim() || '';
    return s ? splitSearchByFirstBraces(s) : undefined;
  }, [search]);

  const successHandler = useCallback(
    (data: DictionaryListWithEntries) => {
      dispatch(setListEntries(data.entryInstances));
      setExerciseInfo(data.exerciseInstance);
    },
    [dispatch]
  );
  const failHandler = useCallback((e: AxiosRequestError) => {
    if (e.error.response?.status === 403) {
      setShow404(true);
    }
  }, []);

  const {isLoading, isError, reload} = useApiRequest(request, successHandler, failHandler);

  const hasButton = useMemo(() => {
    if (userId === dictionaryOwnerId || !list || list?.exerciseInstanceId) return true;
    return list?.createdById === userId;
  }, [userId, dictionaryOwnerId, list]);

  useEffect(() => {
    setError(isError);
  }, [setError, isError]);

  useEffect(
    () => () => {
      setError(false);
    },
    [setError]
  );

  useEffect(() => {
    if (isSearchingInOD) return;

    setTimeout(() => {
      return listSort === 'newest_at_the_bottom'
        ? scrollbarRef.current?.scrollToBottom()
        : scrollbarRef.current?.scrollToTop();
    }, 150);
  }, [listSort, isSearchingInOD]);

  if (show404) return <PageNotFound />;

  return (
    <div className="list-page">
      {isError ? (
        <WampErrorMask reload={reload} />
      ) : (
        <Scrollbars autoHide={true} ref={scrollbarRef} className="list-page-scrollbar">
          {!isLoading && list && !isSearchingInOD ? (
            <ListHeader
              list={{...list, exerciseInstance: exerciseInfo, entryCount: wordEntries.length}}
            />
          ) : null}
          {isLoading ? (
            <Loader />
          ) : isSearchingInOD ? (
            <DictionaryArticle dictionaryIsReadonly={dictionaryIsReadonly} />
          ) : !mutatedWordEntries.length && !isSearching ? (
            <NoEntries
              dictionaryIsReadonly={dictionaryIsReadonly}
              userId={dictionaryOwnerId}
              isList={true}
              hasButton={hasButton}
            />
          ) : (
            <>
              <WordsList numberOfWords={mutatedWordEntries.length} isLoading={isLoading}>
                {mutatedWordEntries.map(we => (
                  <WordEntry
                    key={we.id}
                    dictionaryIsReadonly={dictionaryIsReadonly}
                    search={wordSearch}
                    wordEntry={we}
                    list={list}
                    hasLists={hasLists}
                  />
                ))}
              </WordsList>
            </>
          )}
        </Scrollbars>
      )}
      {!isLoading && !isError && (
        <EntryActions
          hasLists={hasLists}
          listId={listId}
          entries={mutatedWordEntries}
          studentId={dictionaryOwnerId}
          listEntryCount={wordEntries.length}
          isTeacherDictionary={isTeacherDictionary}
          dictionaryIsReadonly={dictionaryIsReadonly}
        />
      )}
    </div>
  );
});
