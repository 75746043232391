import React from 'react';
import RetinaImage from '@englex/react-retina-image';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';

import Icon from 'components/Icon';

import {type DictionaryList} from '../../../../components/Dictionary/shared/interface';
import {ExerciseInfoTooltip} from '../HomePage/ExerciseInfoTooltip';

import './ListHeader.scss';

interface Props {
  list: DictionaryList;
}

const ListHeader: React.FC<Props> = ({list}) => {
  const {entryCount, image, title, exerciseInstance, deletedAt} = list;
  const printTitle = title.replace(/\s/g, ' ');

  return (
    <div className="dictionary-list-page-header">
      <figure className={classNames('list-cover', {'is-archived': deletedAt})}>
        {!image ? <Icon name="virc-note" /> : <RetinaImage src={image.urls} />}
      </figure>
      <div className="info">
        <h3 title={printTitle}>{printTitle}</h3>
        <div className="stats">
          <div className="words">
            <span className="counter-label">
              <FormattedMessage id="Dictionary.List.Header.Words" />:
            </span>
            {entryCount || 0}
            {exerciseInstance ? (
              <ExerciseInfoTooltip
                listId={list.id}
                list={list}
                tooltipContainerSelector=".stats .words"
                placement="top"
              />
            ) : null}
          </div>
          {exerciseInstance ? (
            <div className="coursebook" title={exerciseInstance.unitInstance.unit.coursebook.title}>
              <span className="counter-label">
                <FormattedMessage id="Dictionary.List.Header.Coursebook" />:
              </span>
              {exerciseInstance.unitInstance.unit.coursebook.title}
            </div>
          ) : null}
          {exerciseInstance ? (
            <div className="unit">
              <span className="counter-label">
                <FormattedMessage id="Coursebook.Unit" />:
              </span>
              {exerciseInstance.unitInstance.unit.title}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ListHeader;
