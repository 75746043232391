import React, {type FC} from 'react';
import {type LearnState} from '@englex/trainer';

import {Round} from './Round';
import {Stats} from './Stats';
import {Stage} from '../../shared';

interface Props {
  state: LearnState;
  finishRound(): void;
}

export const LearnTrainerStage: FC<Props> = ({finishRound, state}) => (
  <Stage className="learn">
    <Round finishRound={finishRound} {...state} />
    <Stats state={state} />
  </Stage>
);
