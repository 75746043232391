import React, {type FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import classNames from 'classnames';

import {push, replace} from 'store/router';
import Icon from 'components/Icon';
import DropDown from 'components/DropDown';
import {type AppState} from 'store/interface';

import {type Categories} from './Filter';

const mapDataWithCategories = (
  data: [string, string][],
  categories: Categories
): [string, string][] => {
  return data.map(([key, value]) => {
    const matchingKey = Object.keys(categories).find(category => {
      const categoryParameter = categories[category].parameter;
      return (
        categoryParameter && categoryParameter.key === key && categoryParameter.paramValue === value
      );
    });
    return [matchingKey || key, value];
  });
};

const clearParameters = (urlSearchParams: URLSearchParams, searchParams: string[]) => {
  searchParams.forEach(param => urlSearchParams.delete(param));
};

const checkKeyValueMatch = ([key, value]: [string, string], categories: Categories): boolean => {
  const category = categories[key];

  return category?.parameter?.paramValue === value;
};

interface Props {
  categories: Categories;
  isTeacherDictionary: boolean;
  disabled: boolean;
}

type Key = 'allWords' | 'fromCoursebook' | 'extraWords' | 'inLists';

const defaultKey = 'allWords';

export const FromCoursebookFilter: FC<Props> = ({categories, disabled, isTeacherDictionary}) => {
  const {search} = useLocation();
  const dispatch = useDispatch();
  const isTeacher = useSelector((state: AppState) => state.user.role === 'teacher');
  const [selectedKey, setSelectedKey] = useState<Key>(defaultKey);
  const getCategory = useCallback((key: string) => categories[key], [categories]);
  const params = useMemo(
    () =>
      Object.keys(categories).reduce((result: string[], key: string) => {
        const category = categories[key];
        const duplicate = result.includes(category.parameter?.key || '');

        if (category.parameter && !duplicate) {
          result.push(category.parameter.key);
        }
        return result;
      }, []),
    [categories]
  );

  const searchRef = useRef<string>();
  const selectedKeyRef = useRef<string>();
  searchRef.current = search;
  selectedKeyRef.current = selectedKey;

  const onChange = useCallback(
    (key: Key) => {
      if (key === selectedKey || disabled) return;

      const searchParams = new URLSearchParams(search);

      clearParameters(searchParams, params);

      if (key !== defaultKey) {
        const {key: categoryKey, paramValue: categoryValue} = getCategory(key).parameter!;

        searchParams.set(categoryKey, categoryValue);
      }

      setSelectedKey(key);
      dispatch(push({search: searchParams.toString()}));
    },
    [disabled, dispatch, getCategory, params, search, selectedKey]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(searchRef.current);
    const mappedCategories = mapDataWithCategories([...searchParams.entries()], categories);
    const firstParam = mappedCategories[0];
    const isKeyValueMatch = firstParam?.length ? checkKeyValueMatch(firstParam, categories) : false;
    const key = firstParam?.[0];

    if (mappedCategories.length && key !== selectedKeyRef.current && isKeyValueMatch)
      return setSelectedKey(key as Key);

    if (!mappedCategories.length && selectedKeyRef.current !== defaultKey) {
      setSelectedKey(defaultKey);
    }
    if (firstParam && !isKeyValueMatch) {
      clearParameters(searchParams, params);
      dispatch(replace({search: searchParams.toString()}));
    }
  }, [dispatch, categories, params]);

  return (
    <DropDown
      activeKey={selectedKey}
      className={classNames('rc-dropdown', 'words-filter', {
        'is-teacher-in-students-dictionary': !isTeacherDictionary && isTeacher
      })}
      disabled={disabled}
      items={categories}
      onChange={onChange}
      value={
        categories[selectedKey] && (
          <span>
            <Icon name={categories[selectedKey].icon} className="button-icon" />
            <span className="text">{categories[selectedKey].value}</span>
            <Icon name="angle-down" className="arrow" />
          </span>
        )
      }
      noCaret={true}
      placement="bottomRight"
      trigger={['click']}
    />
  );
};
