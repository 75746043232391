import React, {type FC, useCallback, useContext, useMemo} from 'react';
import {useDrop} from 'react-dnd';
import classNames from 'classnames';
import {shuffle, submitMatch, stripWordCurlyBrackets} from '@englex/trainer';

import {PoolPortalWrapper} from 'components/PoolPortalWrapper';
import DragGap from 'components/XPlayer/components/DragGap';
import {DndTypes} from 'components/dnd/interface';

import {ScrollContext} from '../../../shared/Stage';
import {type MatchingDragObject, type SelectionAndDragging} from './interface';
import {DragItem} from './DragItem';
import {type ExerciseProps, type MatchingExercise, type MatchingValues} from '../interface';

type Props = ExerciseProps<MatchingExercise> & SelectionAndDragging;

export const Pool: FC<Props> = ({closed, ...props}) => {
  const keys = useMemo(() => shuffle(Object.keys(props.exercise)), [props.words]); // eslint-disable-line react-hooks/exhaustive-deps
  const values = Object.values(props.exercise);
  return closed ? (
    <StaticPool exercise={props.exercise} keys={keys} values={values} words={props.words} />
  ) : (
    <DynamicPool {...props} keys={keys} values={values} />
  );
};

const DynamicPool: FC<Omit<Props, 'closed'> & {keys: string[]; values: MatchingValues}> = ({
  dispatch,
  draggingState,
  exercise: matching,
  keys,
  selectionState,
  values,
  words
}) => {
  const [selection, select] = selectionState;
  const [dragged] = draggingState;
  const {scrollTop, clientTop} = useContext(ScrollContext);

  // we can be in situation where drop ref is needed in two nodes at once
  // so the last (pool in portal) not steals this ref for good from first one (pool), which makes
  // one unresponsive dnd-wise. to avoid this situation we remount pool whenever
  // decision on portal changes.
  const [{isOver}, drop] = useDrop<MatchingDragObject, unknown, {isOver: boolean}>({
    accept: DndTypes.ASSESSMENT_ITEM,
    canDrop: item => !!item.sourceId && !!matching[item.sourceId],
    drop: item => dispatch(submitMatch(item.sourceId!, null)),
    collect: monitor => ({isOver: monitor.isOver()})
  });

  const onClick = useCallback(() => {
    if (selection?.sourceId) {
      dispatch(submitMatch(selection.sourceId, null));
    }
    if (selection) select(undefined);
  }, [dispatch, select, selection]);

  return (
    <PoolPortalWrapper
      getParent={useCallback(
        () => document.querySelector('.assessment-exercise-layout.matching .body'),
        []
      )}
      containerId="matching-portal"
      scrollFromOwnProps={true}
      scrollTop={scrollTop}
      playerTop={clientTop}
      portalWrapperClassName="assessment-pool-portal"
      childMayUnmount={true}
    >
      {inPortal => (
        <div
          key={inPortal ? '1' : '0'}
          ref={drop}
          className={poolClassName(isOver, !!selection?.sourceId, inPortal)}
          onClick={onClick}
        >
          {keys.map(key => {
            const hasAnswer = values.some(value => value.answer === key);

            return hasAnswer ? (
              <DragGap
                key={key}
                answer={stripWordCurlyBrackets(words[key].original)}
                flexHandle={true}
                className={classNames('x-dnd-card disabled', {
                  'is-dragging': dragged === key
                })}
              />
            ) : (
              <DragItem
                key={key}
                id={key}
                words={words}
                setDragged={draggingState[1]}
                selectionState={selectionState}
              />
            );
          })}
        </div>
      )}
    </PoolPortalWrapper>
  );
};

const poolClassName = (isOver: boolean, selectionOn: boolean, inPortal?: boolean): string =>
  classNames('pool', {
    'is-over': isOver || selectionOn,
    'selection-on': selectionOn,
    'in-portal': inPortal
  });

const StaticPool: FC<
  Pick<Props, 'exercise' | 'words'> & {keys: string[]; values: MatchingValues}
> = ({keys, values, words}) => {
  return (
    <div className="pool">
      {keys.map(key => {
        const disabled = values.some(value => value.answer === key);

        return (
          <DragGap
            key={key}
            answer={stripWordCurlyBrackets(words[key].original)}
            flexHandle={true}
            className={classNames('x-dnd-card', {disabled})}
          />
        );
      })}
    </div>
  );
};
