import React, {type FC, useCallback, useContext, useEffect, useRef, useState} from 'react';
import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';
import isHotkey from 'is-hotkey';
import {
  type Word,
  TrainerContext,
  splitWordByBraces,
  stripWordCurlyBrackets
} from '@englex/trainer';

import PronunciationSoundPlayer, {
  type IntlPronunciationSoundPlayer,
  type PronunciationPlayerStatus
} from 'components/XPlayer/widgets/Vocabulary/PronunciationSoundPlayer';
import Icon from 'components/Icon';

import {sliderSpeed, textBreakpointOneThird} from '../../../static';
import './Flashcard.scss';

interface Props {
  index: number;
  inverse?: true;
  word: Word;
}

export const Flashcard: FC<Props> = ({index, inverse, word}) => {
  const {trainerState} = useContext(TrainerContext);
  const pos = trainerState.study?.pos ?? 0;
  if (Math.abs(index - pos) > 2) return null;
  return (
    <FlashcardInner active={index === trainerState.study?.pos} inverse={inverse} word={word} />
  );
};

const FlashcardInner: FC<Omit<Props, 'index'> & {active: boolean}> = React.memo(
  ({active, inverse, word}) => {
    const [flipped, setFlipped] = useState(() => !!inverse);
    const [flipping, setFlipping] = useState(false);
    const [playStatus, setPlayStatus] = useState<PronunciationPlayerStatus>(null);

    const activeRef = useRef(active);
    const inverseRef = useRef(inverse);
    const playerRef = useRef<IntlPronunciationSoundPlayer | null>(null);
    const skipFlip = useRef(true); // skip flipping animation on initial render

    const {textBeforeBraces: original} = splitWordByBraces(stripWordCurlyBrackets(word.original));

    const pronunciationMouseCage = useCallback((e: React.MouseEvent<Button, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
    }, []);

    const playPronunciation = useCallback((e: React.MouseEvent<Button, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      playerRef.current?.playSound();
    }, []);

    const keyboardListener = useCallback((e: KeyboardEvent) => {
      if (['TEXTAREA', 'INPUT'].includes((e.target as HTMLElement)?.tagName)) return;
      if (isHotkey(['arrowup', 'arrowdown'], e)) {
        setFlipped(flipped => !flipped);
      }
    }, []);

    const flipCard = () => {
      setFlipped(flipped => !flipped);
    };

    useEffect(() => {
      if (active) {
        document.addEventListener('keydown', keyboardListener);
        return () => document.removeEventListener('keydown', keyboardListener);
      }
      return;
    }, [active, keyboardListener]);

    useEffect(() => {
      setFlipped(!!inverse);
    }, [inverse]);

    useEffect(() => {
      if (!active) {
        const timeout = setTimeout(() => setFlipped(!!inverseRef.current), sliderSpeed);
        return () => clearTimeout(timeout);
      }
      return;
    }, [active]);

    useEffect(() => {
      if (skipFlip.current) {
        skipFlip.current = false;
        return;
      }
      if (activeRef.current) {
        setFlipping(true);
        const timeout = setTimeout(() => setFlipping(false), sliderSpeed);
        return () => clearTimeout(timeout);
      }
      return;
    }, [flipped]);

    useEffect(() => {
      activeRef.current = active;
      inverseRef.current = inverse;
    });

    return (
      <div className={classNames('flashcard', {active, flipped, flipping})} onClick={flipCard}>
        <div className={classNames('flashcard-inner', {flipped, flipping, inverse})}>
          <div
            className={classNames('original', {
              'with-pronunciation': !!word.pronunciationUrl,
              long: original.length > textBreakpointOneThird,
              longer: original.length > textBreakpointOneThird * 2
            })}
          >
            {!!word.pronunciationUrl && (
              <div className="pronunciation-row">
                <PronunciationSoundPlayer
                  ref={playerRef}
                  onStatusChange={setPlayStatus}
                  soundUrl={word.pronunciationUrl}
                >
                  <Button
                    tabIndex={-1}
                    className={classNames('pronunciation-btn', playStatus)}
                    onClick={playPronunciation}
                    onMouseDown={pronunciationMouseCage}
                    onMouseUp={pronunciationMouseCage}
                  >
                    <Icon name="volume-up" />
                  </Button>
                </PronunciationSoundPlayer>
              </div>
            )}
            <div className="text-wrapper">
              <span className="text">{original}</span>
            </div>
          </div>
          <div
            className={classNames('translation', {
              long: word.translation.length > textBreakpointOneThird,
              longer: word.translation.length > textBreakpointOneThird * 2
            })}
          >
            <span className="text">{word.translation}</span>
          </div>
        </div>
      </div>
    );
  }
);
