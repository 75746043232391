import React, {type FC, useCallback} from 'react';
import {submitAssessmentWrite} from '@englex/trainer';

import {AssessmentExerciseLayout} from '../AssessmentExerciseLayout';
import {Question} from './Question';
import {type ExerciseProps, type WriteExercise} from '../interface';
import './Write.scss';

export const Write: FC<ExerciseProps<WriteExercise>> = React.memo(
  ({closed, dispatch, exercise: write, words}) => {
    const onInput = useCallback(
      (e: React.FormEvent<HTMLTextAreaElement>) => {
        const id = e.target['id'].split('::')[0];
        dispatch(submitAssessmentWrite(id, e.currentTarget.value));
      },
      [dispatch]
    );
    return (
      <AssessmentExerciseLayout className="write" title="Translate the words and phrases.">
        {Object.entries(write).map(([key, value], i) => (
          <Question
            key={key}
            id={key}
            index={i + 1}
            closed={closed}
            value={value}
            words={words}
            onInput={onInput}
          />
        ))}
      </AssessmentExerciseLayout>
    );
  }
);
