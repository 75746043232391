import React, {type FC, useState} from 'react';

import {AssessmentExerciseLayout} from '../AssessmentExerciseLayout';
import {type ExerciseProps, type MatchingExercise} from '../interface';
import {Targets} from './Targets';
import {Pool} from './Pool';
import './Matching.scss';

export const Matching: FC<ExerciseProps<MatchingExercise>> = props => {
  const draggingState = useState<string | undefined>();
  const selectionState = useState<{itemId: string; sourceId?: string} | undefined>();

  return (
    <AssessmentExerciseLayout
      className="matching"
      title="Match the words and phrases to their translations."
    >
      <Pool {...props} draggingState={draggingState} selectionState={selectionState} />
      <Targets {...props} draggingState={draggingState} selectionState={selectionState} />
    </AssessmentExerciseLayout>
  );
};
