import React, {type FC, useMemo} from 'react';
import {type LearnState, isMastered, isRemaining, wordsRecordToArray} from '@englex/trainer';

import Icon from 'components/Icon';

import {InProgressCount, MasteredCount, RemainingCount, StatsRow} from '../../shared';

type Key = 'remaining' | 'inProgress' | 'mastered';

export const Stats: FC<{state: LearnState}> = ({state: {words}}) => {
  const stats = useMemo(
    () =>
      wordsRecordToArray(words).reduce<Record<Key, number>>(
        (acc, w) => {
          isMastered(w) ? acc.mastered++ : isRemaining(w) ? acc.remaining++ : acc.inProgress++;
          return acc;
        },
        {remaining: 0, inProgress: 0, mastered: 0}
      ),
    [words]
  );
  return (
    <StatsRow>
      <RemainingCount count={stats.remaining}>
        <Icon name="pc-thin-right-arrow" />
      </RemainingCount>
      <InProgressCount count={stats.inProgress}>
        <Icon name="pc-thin-right-arrow" />
      </InProgressCount>
      <MasteredCount count={stats.mastered} />
    </StatsRow>
  );
};
