import {useCallback} from 'react';

import {deleteEntriesFromDictionaryRequest} from 'store/dictionary/requests';
import {useApiRequest2} from 'hooks/rest/useApiRequest2';
import {type Role} from 'store/interface';

import {useDictionaryContext} from '../shared/contexts';

export const useDeleteEntriesFromDictionary = (
  successHandler: () => void,
  errorHandler: () => void,
  entryIds?: string[]
): [boolean, () => void] => {
  const {dictionaryOwnerId, dictionaryOwnerRole} = useDictionaryContext();

  const {isLoading, apiRequest} = useApiRequest2<[number, string[], Role]>(
    deleteEntriesFromDictionaryRequest,
    successHandler,
    errorHandler
  );

  const deleteFromList = useCallback(() => {
    if (entryIds) apiRequest(dictionaryOwnerId, entryIds, dictionaryOwnerRole);
  }, [apiRequest, dictionaryOwnerId, dictionaryOwnerRole, entryIds]);

  return [isLoading, deleteFromList];
};
