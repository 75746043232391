import React, {type FC} from 'react';
import classNames from 'classnames';

import {FlashcardLayout} from '../../../../shared';
import './Layout.scss';

interface Props {
  className?: string;
  size: number;
  wordsLeft: number;
}

export const Layout: FC<Props> = ({className, children, size, wordsLeft}) => {
  return (
    <FlashcardLayout className={classNames('round-stage', className)}>
      <RoundProgress size={size} wordsLeft={wordsLeft} />
      {children}
    </FlashcardLayout>
  );
};

const RoundProgress: FC<Props> = ({size, wordsLeft}) => {
  return (
    <div className="round-progress">
      <div className="round-progress-amount" style={{width: `${100 * (1 - wordsLeft / size)}%`}} />
    </div>
  );
};
