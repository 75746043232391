import React, {type FC, useCallback, useContext, memo} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage, useIntl} from 'react-intl';
import classNames from 'classnames';

import Icon from 'components/Icon';
import DropDown from 'components/DropDown';
import * as toastr from 'components/toastr';
import {type Role} from 'store/interface';

import {EditEntryActionsContext, EditEntryStateContext} from '../contexts/entry/EditEntryContext';
import {
  deselectAll,
  exitSelectionMode,
  selectAll,
  setSelectionAction
} from '../contexts/entry/actions';
import {type DictionaryEntryInstance} from '../interface';
import {maxListEntries} from '../static';

interface Props {
  deletionForbidden?: boolean;
  entries: DictionaryEntryInstance[];
  hasLists: boolean;
  listId?: string;
  selectedEntryIds: string[];
  role?: Role;
  isTeacherDictionary: boolean;
  deleteIsDisabled: boolean;
}

export const ActiveSelectionControls: FC<Props> = memo(
  ({
    deletionForbidden,
    entries,
    hasLists,
    listId,
    selectedEntryIds,
    role,
    isTeacherDictionary,
    deleteIsDisabled
  }) => {
    const intl = useIntl();
    const {dispatch} = useContext(EditEntryActionsContext);
    const {selectionMode} = useContext(EditEntryStateContext);

    const allSelected = selectionMode?.entryIds.length === entries.length;

    const showDeleteButton =
      !deletionForbidden && (listId || role === 'student' || isTeacherDictionary);

    const deselectAllCallback = useCallback(() => {
      dispatch(deselectAll());
    }, [dispatch]);

    const selectAllCallback = useCallback(() => {
      if (listId) dispatch(selectAll(entries.map(e => e.id)));
    }, [dispatch, entries, listId]);

    const exitSelectionModeCallback = useCallback(() => {
      dispatch(exitSelectionMode());
    }, [dispatch]);

    const deleteCallback = useCallback(() => {
      dispatch(setSelectionAction('delete'));
    }, [dispatch]);

    const onDropdownChange = useCallback(
      (key: string) => {
        if (key === 'add_to_existing_list') {
          dispatch(setSelectionAction('add', true));
        }
        if (key === 'add_to_new_list') {
          if (selectedEntryIds.length > maxListEntries) {
            return toastr.error(
              '',
              intl.formatMessage({id: 'Dictionary.Toastr.TooManySelectedEntries'})
            );
          } else {
            dispatch(setSelectionAction('add', false));
          }
        }
      },
      [dispatch, intl, selectedEntryIds.length]
    );

    return (
      <>
        <Button
          className="dictionary-selection-controls cancel"
          bsStyle="success"
          onClick={exitSelectionModeCallback}
          title={intl.formatMessage({id: 'Common.Cancel'})}
        >
          <Icon name="arrow-circle-left" />
          <FormattedMessage id="Common.Cancel" />
        </Button>

        {showDeleteButton && (
          <Button
            className="dictionary-selection-controls"
            bsStyle="danger"
            onClick={deleteCallback}
            disabled={!selectedEntryIds.length || deleteIsDisabled}
            title={intl.formatMessage({id: 'Common.Delete'})}
          >
            <Icon name="trash" />
            <FormattedMessage id="Common.Delete" />
          </Button>
        )}

        <DropDown
          toggleClassName="dictionary-selection-controls add btn btn-success"
          items={{
            add_to_new_list: intl.formatMessage({id: 'Dictionary.AddToNewList'}),
            add_to_existing_list: {
              value: intl.formatMessage({id: 'Dictionary.AddToExistingList'}),
              disabled: !hasLists
            }
          }}
          onChange={onDropdownChange}
          value={
            <span title={intl.formatMessage({id: 'Dictionary.AddToList'})}>
              <Icon name="virc-note" />
              <FormattedMessage id="Dictionary.AddToList" />
            </span>
          }
          placement="top"
          trigger={['click']}
          disabled={!selectedEntryIds.length}
          noCaret={true}
        />
        {!listId ? null : (
          <Button
            className={classNames('dictionary-selection-controls')}
            bsStyle="success"
            onClick={selectAllCallback}
            title={intl.formatMessage({id: 'Dictionary.CheckAll'})}
            disabled={allSelected}
          >
            <Icon name="check-square-o" />
            <FormattedMessage id="Dictionary.CheckAll" />
          </Button>
        )}
        <Button
          className={classNames('dictionary-selection-controls')}
          bsStyle="success"
          onClick={deselectAllCallback}
          disabled={!selectedEntryIds.length}
          title={intl.formatMessage({id: 'Dictionary.UncheckAll'})}
        >
          <Icon name="square-o" />
          <FormattedMessage id="Dictionary.UncheckAll" />
        </Button>
      </>
    );
  }
);
