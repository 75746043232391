import React, {type FC, memo} from 'react';
import {Outlet} from 'react-router-dom';

import {useDictionarySubscribe} from 'store/dictionary/useDictionarySubscribe';
import {ContextProviders} from 'components/Dictionary/shared/contexts/list/ContextProviders';
import WampErrorMask from 'components/WampErrorMask';
import Loader from 'components/Loader';
import {useSearchInOD} from 'components/Dictionary/Sidebar/DictionaryArticle/hooks/useSearchInOD';

import {DictionaryBreadcrumbs} from './Breadcrumbs/DictionaryBreadcrumbs';
import {useDictionaryInit} from './useDictionaryInit';

import './DictionaryPage.scss';

export const DictionaryPage: FC = memo(() => {
  const {error, isSubscribing, retry} = useDictionarySubscribe();

  const {isError, isLoading, reload} = useDictionaryInit();
  const searchInODData = useSearchInOD();

  if (isError || error)
    return (
      <WampErrorMask
        reload={() => {
          if (isError) return reload();
          if (error) return retry();
        }}
      />
    );

  return (
    <ContextProviders {...searchInODData}>
      <div className="dictionary-page">
        <DictionaryBreadcrumbs />
        {isLoading || isSubscribing ? (
          <Loader />
        ) : (
          <div className="main-view">
            <Outlet />
          </div>
        )}
      </div>
    </ContextProviders>
  );
});
