import React, {type FC, useCallback, useContext, useEffect, useState} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {FormattedMessage, useIntl} from 'react-intl';
import {
  ASSESSMENT,
  setCurrentTrainerType,
  setMeta,
  TrainerActionsContext,
  TrainerContext,
  type TrainerType
} from '@englex/trainer';

import PageControls from 'components/PageControls';
import Icon from 'components/Icon';

import {RefreshButton, StartScreen, TrainerLayout} from '../shared';
import {AssessmentStage, CardsHint, FirstHint, SecondHint} from './components';
import {ContinueOrExitBtn} from '../WriteTrainer/components';
import './AssessmentTrainer.scss';

export const AssessmentTrainer: FC = () => {
  const intl = useIntl();
  const {listId, studentId, trainerState} = useContext(TrainerContext);
  const {dispatch} = useContext(TrainerActionsContext);

  const {assessment: state, meta, loading} = trainerState;

  const skip = Boolean(meta.skipStartScreen?.assessment);

  const [started, setStarted] = useState(skip);

  const continueCallback = useCallback(() => {
    dispatch(setMeta({...meta, skipStartScreen: {...meta.skipStartScreen, assessment: true}}));
  }, [dispatch, meta]);

  const start = useCallback(() => setStarted(true), []);

  const setSkipStartScreen = useCallback(
    (type: TrainerType) =>
      dispatch(setMeta({...meta, skipStartScreen: {...meta.skipStartScreen, [type]: true}})),
    [dispatch, meta]
  );

  useEffect(() => dispatch(setCurrentTrainerType(ASSESSMENT)), [dispatch]);

  const isLoading = loading || trainerState.currentType !== ASSESSMENT;

  return (
    <TrainerLayout className="assessment">
      <div className="matching-portal-root">
        <Scrollbars autoHeight={true} autoHide={false}>
          <div id="matching-portal" className="matching-portal" />
        </Scrollbars>
      </div>
      {(!started || !state) && state?.score === undefined ? (
        <StartScreen
          CardsHint={CardsHint}
          FirstHint={FirstHint}
          refreshErrorMessage={intl.formatMessage({id: 'Trainer.Assessment.RefreshError'})}
          SecondHint={SecondHint}
          loading={isLoading}
          trainerState={trainerState}
          startOverHint={intl.formatMessage({id: 'Trainer.Assessment.StartOverHint'})}
          stateExists={!!state}
          continueCallback={continueCallback}
          setStarted={setStarted}
          setSkipStartScreen={setSkipStartScreen}
        />
      ) : (
        <>
          <AssessmentStage state={state} />
          <PageControls>
            <RefreshButton onRefreshed={start}>
              <Icon name="undo" />
              <FormattedMessage id="Trainer.StartOver" />
            </RefreshButton>
          </PageControls>
          {state.score !== undefined && (
            <PageControls pullRight={true}>
              <ContinueOrExitBtn
                callback={() => {}}
                listId={listId}
                studentId={studentId}
                // completedCount === total to always show 'Exit'
                completedCount={state.score}
                total={state.score}
              />
            </PageControls>
          )}
        </>
      )}
    </TrainerLayout>
  );
};
