import React, {type FC, useMemo, memo} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {type AppState} from 'store/interface';
import {type DictionaryList} from 'components/Dictionary/shared/interface';
import PageControls from 'components/PageControls';
import {CreateListCard} from 'components/Dictionary/shared/CreateList/CreateListCard';
import {CreateListButton} from 'components/Dictionary/shared/CreateList/CreateListButton';
import {DeleteListModal} from 'components/Dictionary/shared/CreateList/DeleteListModal';
import {useDictionaryContext} from 'components/Dictionary/shared/contexts';

import {DictionaryHeader} from './DictionaryHeader';
import {ListCards} from './ListCards';

export const HomePage: FC = memo(() => {
  const {search} = useLocation();
  const {isTeacherDictionary} = useDictionaryContext();
  const lists = useSelector<AppState, DictionaryList[]>(s => s.dictionary?.lists || []);
  const dictionaryIsReadonly =
    useSelector<AppState, boolean>(s => !!s.studentTeachers?.isInactive) && !isTeacherDictionary;

  const showArchived = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return !!searchParams.get('show_archived');
  }, [search]);

  return (
    <>
      <Scrollbars autoHide={true}>
        <div className="main-view-content">
          <DictionaryHeader />
          <ListCards lists={lists.filter(l => !l.deletedAt)}>
            {dictionaryIsReadonly ? null : <CreateListCard key="create-list-card" />}
          </ListCards>
          {showArchived && <ListCards isArchive={true} lists={lists.filter(l => l.deletedAt)} />}
        </div>
      </Scrollbars>
      {!dictionaryIsReadonly && (
        <PageControls>
          <CreateListButton />
        </PageControls>
      )}
      <DeleteListModal />
    </>
  );
});
