import React, {memo} from 'react';
import {useIntl} from 'react-intl';
import classNames from 'classnames';

import Icon from 'components/Icon';
import {useDictionaryContext} from 'components/Dictionary/shared/contexts';

import styles from './DropdownTitle.module.scss';

export const DropdownTitle: React.FC = memo(() => {
  const {formatMessage} = useIntl();
  const {isTeacherDictionary} = useDictionaryContext();

  return (
    <span className={styles.dropdownTitle}>
      {isTeacherDictionary ? (
        <>
          <span className="breadcrumb-icon always-visible-icon">
            <Icon name="user" />
          </span>
          <span className={classNames('breadcrumb-title', styles.text)}>
            {formatMessage({id: 'Dictionary.MyDictionary'})}
          </span>
        </>
      ) : (
        <>
          <span className="breadcrumb-icon always-visible-icon">
            <Icon name="users" />
          </span>
          <span className={classNames('breadcrumb-title', styles.text)}>
            {formatMessage({id: 'Dictionary.StudentsDictionaries'})}
          </span>
        </>
      )}
      <Icon name="caret-down" />
    </span>
  );
});
