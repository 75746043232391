import React, {type FC, useCallback, useMemo, memo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Button from 'react-bootstrap/lib/Button';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import classNames from 'classnames';
import Tooltip from 'rc-tooltip';
import {minWordsCount} from '@englex/trainer';

import {push} from 'store/router';
import Icon from 'components/Icon';
import DropDown from 'components/DropDown';
import Spinner from 'components/Spinner';
import {type AppState, type Profile, type StudentTeacher} from 'store/interface';
import {type StudentTeachersState} from 'store/studentTeachers/interface';
import {useDictionaryContext} from 'components/Dictionary/shared/contexts';
import {clipboardCopy} from 'helpers/navigator';
import * as toastr from 'components/toastr';
import {useSentChatMessage} from 'hooks/chat/useSentChatMessage';
import {useCopyList} from 'components/Dictionary/shared/contexts/list/CopyListContext/CopyListContext';

import {trainerPathCreator} from '../../ListTrainer/utils';

const DROPDOWN_TRIGGER = ['click'];

const TOOLTIP_TRIGGER = ['hover'];

enum Actions {
  Rename = 'rename',
  Delete = 'delete',
  Archive = 'archive',
  Restore = 'restore',
  Copy = 'copy',
  Send = 'send',
  CopyWordList = 'CopyWordList'
}

interface Props {
  listTitle?: string;
  dictionaryIsReadonly: boolean;
  entryCount?: number;
  isArchived: boolean;
  isLoading: boolean;
  isReadonly: boolean;
  isStudent: boolean;
  listId: string;
  userId: number;
  isTeacherDictionary: boolean;
  toggleArchive(): void;
  renameList(): void;
  deleteList(): void;
}

const messages = defineMessages({
  rename: {
    id: 'Common.Rename'
  },
  delete: {
    id: 'Common.Delete'
  },
  archive: {
    id: 'Dictionary.Archive'
  },
  restore: {
    id: 'Homework.Action.Restore'
  },
  copy: {
    id: 'XPlayer.Grammar.CopyLinkToClipboard'
  },
  send: {
    id: 'XPlayer.Grammar.SendLinkToChat'
  },
  copyWordList: {
    id: 'Dictionary.CopyWordList'
  }
});

export const ListCardFooter: FC<Props> = memo(
  ({
    listTitle,
    dictionaryIsReadonly,
    entryCount = 0,
    isArchived,
    isLoading,
    isReadonly,
    isStudent,
    listId,
    userId,
    isTeacherDictionary,
    toggleArchive,
    renameList,
    deleteList
  }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const {openCopyListModal} = useCopyList();
    const {dictionaryOwnerRole} = useDictionaryContext();
    const {send} = useSentChatMessage();

    const isTeacher = useSelector<AppState, boolean>(s => s.user.role === 'teacher');
    const studentTeachers = useSelector<AppState, StudentTeachersState>(s => s.studentTeachers!);
    const isStudentInactive = !!Object.values(studentTeachers.studentTeachers!).find(
      (item: StudentTeacher) => +item.student_id === userId
    )?.deleted_at;

    const isStudentArchived = Object.values(studentTeachers.studentTeachers!).every(
      studentTeacher => studentTeacher.deleted_at !== null
    );

    const items = useMemo(() => {
      return [
        {
          action: Actions.Rename,
          intl: messages.rename,
          icon: 'pencil',
          show: true,
          disabled: isReadonly || dictionaryIsReadonly
        },
        {
          action: Actions.Delete,
          intl: messages.delete,
          icon: 'trash',
          show: true,
          disabled: isReadonly || dictionaryIsReadonly
        },
        {
          action: Actions.Archive,
          intl: messages.archive,
          icon: 'archive',
          show: (isStudent || isTeacherDictionary) && !isArchived
        },
        {
          action: Actions.Restore,
          intl: messages.restore,
          icon: 'virc-homework-restore',
          show: (isStudent || isTeacherDictionary) && isArchived
        },
        {
          action: Actions.Copy,
          intl: messages.copy,
          icon: 'paste',
          show: !isStudent && !isTeacherDictionary
        },
        {
          action: Actions.Send,
          intl: messages.send,
          icon: 'comment-o',
          show: !isStudent && !isTeacherDictionary,
          disabled: isStudentInactive
        },
        {
          action: Actions.CopyWordList,
          intl: messages.copyWordList,
          icon: 'copy',
          show: !isStudent
        }
      ].reduce((options, option) => {
        if (option.show) {
          return {
            ...options,
            [option.action]: {
              value: intl.formatMessage(option.intl),
              icon: option.icon,
              disabled: option.disabled
            }
          };
        }

        return options;
      }, {});
    }, [
      isReadonly,
      dictionaryIsReadonly,
      isStudent,
      isTeacherDictionary,
      isArchived,
      isStudentInactive,
      intl
    ]);

    const teacherInStudentsDictionary = dictionaryOwnerRole === 'student' && isTeacher;

    const disableTrainButton =
      teacherInStudentsDictionary || isReadonly || entryCount < minWordsCount;

    const onRename = useCallback(() => {
      if (!isReadonly && !dictionaryIsReadonly) {
        renameList();
      }
    }, [dictionaryIsReadonly, isReadonly, renameList]);

    const onDelete = useCallback(() => {
      if (!isReadonly && !dictionaryIsReadonly) {
        deleteList();
      }
    }, [deleteList, dictionaryIsReadonly, isReadonly]);

    const onArchive = useCallback(() => {
      if (!isReadonly && !isLoading && !dictionaryIsReadonly) {
        toggleArchive();
      }
    }, [dictionaryIsReadonly, isLoading, isReadonly, toggleArchive]);

    const onTrain = useCallback(
      (e: React.MouseEvent<Button, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        if (!disableTrainButton) {
          dispatch(push(trainerPathCreator(userId, listId, dictionaryOwnerRole)));
        }
      },
      [disableTrainButton, dispatch, userId, listId, dictionaryOwnerRole]
    );

    const dropdownHandler = useCallback(
      (action: Actions) => {
        if (action === Actions.Rename) {
          onRename();
        }

        if (action === Actions.Archive || action === Actions.Restore) {
          onArchive();
        }

        if (action === Actions.Delete) {
          onDelete();
        }

        if (action === Actions.Copy) {
          clipboardCopy(`${window.location.origin}/dictionary/student/${userId}/list/${listId}`)
            .then(() =>
              toastr.success('', intl.formatMessage({id: 'Dictionary.Toastr.CopyTextSuccess'}))
            )
            .catch(() =>
              toastr.error('', intl.formatMessage({id: 'Dictionary.Toastr.CopyTextError'}))
            );
        }

        if (action === Actions.Send) {
          if (isStudentInactive) return;

          const message = `[${listTitle || 'Glossary'}](${window.location.href}/list/${listId})`;

          send(message)
            .then((profile: Profile) => {
              const {first_name: firstName, last_name: lastName} = profile;

              toastr.success(
                '',
                intl.formatMessage(
                  {id: 'XPlayer.Grammar.SendLinkToChat.SuccessToastr'},
                  {lastName, firstName}
                )
              );
            })
            .catch(() => {
              toastr.error(
                '',
                intl.formatMessage({id: 'XPlayer.Grammar.SendLinkToChat.ErrorToastr'})
              );
            });
        }

        if (action === Actions.CopyWordList) {
          openCopyListModal?.(listId);
        }
      },
      [
        onRename,
        onArchive,
        onDelete,
        userId,
        listId,
        intl,
        isStudentInactive,
        listTitle,
        send,
        openCopyListModal
      ]
    );

    const trainButton = (
      <Button
        className={classNames('train', {disabled: disableTrainButton})}
        bsSize="sm"
        onClick={onTrain}
      >
        <FormattedMessage id="Dictionary.Train" />
      </Button>
    );

    return (
      <div className="footer">
        {isStudent && disableTrainButton ? (
          <Tooltip
            overlayClassName={classNames({
              'dictionary-card-footer-tooltip': isStudentArchived
            })}
            overlay={
              isStudentArchived
                ? intl.formatMessage({id: 'Dictionary.OnlyForActiveStudents'})
                : intl.formatMessage({id: 'Dictionary.LeastWords'})
            }
            placement="top"
            trigger={TOOLTIP_TRIGGER}
          >
            {trainButton}
          </Tooltip>
        ) : (
          trainButton
        )}

        <div className="dropdown-container virc-actions">
          <DropDown
            items={items}
            value={isLoading ? <Spinner size={18} /> : <Icon name="virc-actions" />}
            trigger={DROPDOWN_TRIGGER}
            onChange={dropdownHandler}
            noCaret={true}
          />
        </div>
      </div>
    );
  }
);
