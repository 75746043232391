import React, {type FC, type FormEvent} from 'react';
import type Radio from 'react-bootstrap/lib/Radio';
import {type Immutable} from 'immer';
import {type MultipleChoiceValue, type RawWord} from '@englex/trainer';

import {Choice} from './Choice';

interface Props {
  id: string;
  index: number;
  closed: boolean;
  value: Immutable<MultipleChoiceValue>;
  words: Record<string, RawWord>;
  handleChange(e: FormEvent<Radio>): void;
}

export const Question: FC<Props> = React.memo(({id, index, closed, value, words, handleChange}) => {
  return (
    <div className="word">
      <div className="task">
        <span className="index">{index}. </span>
        {words[id].translation}
      </div>
      <div className="choices">
        {value.choices.map(choice => {
          const checked = choice === value.answer;
          const correct = value.answers.includes(choice);

          return (
            <Choice
              key={choice}
              questionId={id}
              choiceId={choice}
              checked={checked}
              correct={correct}
              closed={closed}
              words={words}
              handleChange={handleChange}
            />
          );
        })}
      </div>
    </div>
  );
});
