import React, {type FC} from 'react';
import {FormattedMessage} from 'react-intl';

interface Props {
  presentation?: 'percent' | 'absolute';
  progress: number;
  total: number;
}

export const ActivityCounter: FC<Props> = ({presentation = 'percent', progress, total}) => {
  return (
    <div className="count">
      {presentation === 'absolute' ? (
        <FormattedMessage id="Common.XOfY" values={{x: progress, y: total}} />
      ) : (
        <span>{Math.round((progress / total) * 100)}%</span>
      )}
    </div>
  );
};
