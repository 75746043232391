import React, {type ComponentType, type FC} from 'react';
import classNames from 'classnames';

import './AssessmentExerciseLayout.scss';

interface Props {
  className?: string;
  title: string | ComponentType;
}

export const AssessmentExerciseLayout: FC<Props> = ({children, className, title: Title}) => {
  return (
    <div className={classNames('assessment-exercise-layout', className)}>
      <h3 className="title">{typeof Title === 'string' ? Title : <Title />}</h3>
      <div className="body">{children}</div>
    </div>
  );
};
