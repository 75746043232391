import React, {type FC, useEffect, useState, memo, useCallback} from 'react';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import {FormattedMessage, useIntl} from 'react-intl';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import {type FormatOptionLabelMeta} from 'react-select';

import {type AppState, type Role, type UserState} from 'store/interface';
import {requestStudentTeachers} from 'store/studentTeachers/requests';
import {type StudentTeacher} from 'components/Homework/interface';
import Icon from 'components/Icon';
import Loader from 'components/Loader';
import ReactSelect from 'components/ReactSelect/ReactSelect';
import {useApiRequest2} from 'hooks/rest/useApiRequest2';
import {useCopyWordList} from 'components/Dictionary/hooks/useCopyWordList';
import {type OptionTypeBase} from 'components/ReactSelect/interface';

import {useDictionaryContext} from '../contexts';
import styles from './CopyListModal.module.scss';

import './ReactSelect.scss';

interface StudentData {
  value?: number;
  firstName?: string;
  lastName?: string;
  image?: string;
  label?: string;
}

export const CopyListModal: FC<{show: boolean; close: () => void; listId?: string}> = memo(
  ({show, close, listId}) => {
    return show && listId ? <InnerModal listId={listId} close={close} /> : null;
  }
);

const InnerModal: FC<{listId: string; close: () => void}> = memo(({listId, close}) => {
  const {formatMessage} = useIntl();
  const {isTeacherDictionary, dictionaryOwnerId} = useDictionaryContext();
  const user = useSelector<AppState, UserState>(state => state.user);

  const [selectedOption, setSelectedOption] = useState<StudentData[]>();
  const [currentValue, setCurrentValue] = useState<number[] | undefined>();

  const successHandler = (data: StudentTeacher[]) => {
    const studentsItems = data
      .filter(
        StudentTeacher =>
          !StudentTeacher.deleted_at && !(StudentTeacher.student?.id === dictionaryOwnerId)
      )
      .map(studentTeacher => ({
        value: studentTeacher.student?.id,
        firstName: studentTeacher.student?.profile?.first_name,
        lastName: studentTeacher.student?.profile?.last_name,
        image: studentTeacher.student?.profile?.avatars.xs['@1x'],
        label: `${studentTeacher.student?.profile?.first_name} ${studentTeacher.student?.profile?.last_name}`
      }));

    const myDictionary = formatMessage({id: 'Dictionary.MyDictionary'});
    if (!isTeacherDictionary) {
      studentsItems.unshift({
        value: user.id,
        firstName: '',
        lastName: myDictionary,
        image: user?.profile?.avatars.xs['@1x'],
        label: myDictionary
      });
    }

    setSelectedOption(studentsItems);
  };

  const {apiRequest, isLoading} = useApiRequest2<[Role]>(requestStudentTeachers, successHandler);

  useEffect(() => {
    apiRequest('student');
  }, [apiRequest]);

  const onChange = useCallback((newValue: StudentData[]) => {
    if (newValue.every(item => !!item?.value)) {
      setCurrentValue(newValue.map(item => item.value!));
    }
  }, []);

  const {copyWordList} = useCopyWordList(close);

  const onClick = () => {
    if (currentValue) {
      copyWordList(listId, currentValue);
    }
  };

  const formatOptionLabel = useCallback(
    (
      {image, firstName, lastName, value}: StudentData,
      {context}: FormatOptionLabelMeta<OptionTypeBase | OptionTypeBase[]>
    ) => {
      if (!(image && lastName)) return null;
      const isMenu = context === 'menu';
      return (
        <div
          className={classNames({
            [styles.userItemInMenu]: isMenu,
            [styles.userItemInValue]: !isMenu
          })}
        >
          {isMenu &&
            (value === user.id ? (
              <div className={styles.dictionaryIcon}>
                <Icon name="virc-vocabulary" />
              </div>
            ) : (
              <img className={styles.userAvatar} src={image} alt="user avatar" />
            ))}

          <span
            className={classNames({
              [styles.userNameInMenu]: isMenu,
              [styles.userNameInValue]: !isMenu
            })}
          >
            {firstName
              ? isMenu
                ? `${firstName} ${lastName}`
                : `${lastName} ${firstName[0]}.`
              : lastName}
          </span>
        </div>
      );
    },
    [user.id]
  );

  return (
    <Modal backdrop="static" show={true} className="copy-list-modal" onHide={close}>
      <Modal.Header className={styles.modalHeader}>
        <Modal.Title>{formatMessage({id: 'Dictionary.CopyWordList'})}</Modal.Title>
        <a onClick={close}>
          <Icon name="pc-close" tag="i" />
        </a>
      </Modal.Header>
      <Modal.Body>
        {isLoading || !selectedOption ? (
          <Loader />
        ) : (
          <>
            <h5>{formatMessage({id: 'Dictionary.CopyToDictionary'})}</h5>
            <ReactSelect
              placeholder={formatMessage({id: 'Dictionary.SelectFromTheList'})}
              onChange={onChange}
              isMulti={true}
              options={selectedOption}
              formatOptionLabel={formatOptionLabel}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="actions">
          <Button bsStyle="default" bsSize="sm" onClick={close}>
            <FormattedMessage id="Common.Cancel" />
          </Button>
          <Button bsStyle="primary" bsSize="sm" type="submit" onClick={onClick}>
            <FormattedMessage id="Common.Save" />
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
});
