import React, {type FC, useCallback, useContext, useEffect, useState, memo} from 'react';
import {FormattedMessage} from 'react-intl';
import {Navigate, useOutletContext} from 'react-router-dom';
import {
  setCurrentTrainerType,
  TrainerActionsContext,
  TrainerContext,
  useDisableTrainer,
  writeNextRound,
  WRITE,
  objectSize,
  type WriteState,
  setMeta,
  type TrainerType
} from '@englex/trainer';

import PageControls from 'components/PageControls';
import Icon from 'components/Icon';
import {useDictionaryContext} from 'components/Dictionary/shared/contexts';

import {trainerPathCreator} from '../../utils';
import {RefreshButton, StartScreen, TrainerLayout} from '../shared';
import {
  CardsHint,
  ContinueOrExitBtn,
  FirstHint,
  SecondHint,
  WriteResults,
  WriteTrainerStage
} from './components';
import {type TrainerOutletContext} from '../interface';

export const WriteTrainer: FC = memo(() => {
  const listEntriesCount = useOutletContext<TrainerOutletContext>()?.list?.entryCount;
  const {listId, studentId, trainerState} = useContext(TrainerContext);
  const {dispatch} = useContext(TrainerActionsContext);
  const {write: state, meta, loading} = trainerState;

  const skip = Boolean(meta.skipStartScreen?.write);

  const [started, setStarted] = useState(skip);
  const shouldRedirect = useDisableTrainer(!!state, listEntriesCount || 0);
  const {dictionaryOwnerRole} = useDictionaryContext();

  const page = getPageFromState(started, state);

  const start = useCallback(() => setStarted(true), []);
  const continueCallback = useCallback(() => {
    dispatch(writeNextRound());
    setStarted(true);
  }, [dispatch]);

  const setSkipStartScreen = useCallback(
    (type: TrainerType) =>
      dispatch(setMeta({...meta, skipStartScreen: {...meta.skipStartScreen, [type]: true}})),
    [dispatch, meta]
  );

  useEffect(() => {
    dispatch(setCurrentTrainerType(WRITE));
  }, [dispatch]);

  if (shouldRedirect)
    return (
      <Navigate replace={true} to={trainerPathCreator(studentId, listId, dictionaryOwnerRole)} />
    );

  const isLoading = loading || trainerState.currentType !== WRITE;

  return (
    <TrainerLayout className="write-trainer">
      {page === 'start-page' ? (
        <StartScreen
          CardsHint={CardsHint}
          FirstHint={FirstHint}
          SecondHint={SecondHint}
          loading={isLoading}
          trainerState={trainerState}
          stateExists={!!state}
          continueCallback={continueCallback}
          setStarted={setStarted}
          setSkipStartScreen={setSkipStartScreen}
        />
      ) : (
        <>
          {page === 'final-page' && <WriteResults state={state!} />}
          {page === 'stage-page' && <WriteTrainerStage state={state!} />}
          <PageControls>
            <RefreshButton onRefreshed={start}>
              <Icon name="undo" />
              <FormattedMessage id="Trainer.StartOver" />
            </RefreshButton>
          </PageControls>
          {page === 'final-page' && (
            <PageControls pullRight={true}>
              <ContinueOrExitBtn
                callback={continueCallback}
                completedCount={state!.correct.length}
                listId={listId}
                studentId={studentId}
                total={objectSize(state!.words)}
              />
            </PageControls>
          )}
        </>
      )}
    </TrainerLayout>
  );
});

const getPageFromState = (
  started: boolean,
  state?: WriteState
): 'start-page' | 'final-page' | 'stage-page' => {
  if (!state) return 'start-page';
  if (state.correct.length === objectSize(state.words) || (started && !state.remaining.length))
    return 'final-page';
  return started ? 'stage-page' : 'start-page';
};
