import React, {type FC, useCallback, useState} from 'react';
import Tooltip from 'rc-tooltip';
import {type TooltipProps} from 'rc-tooltip/lib/Tooltip';
import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';

import {requestDictionaryList} from 'store/dictionary/requests';
import {useApiRequest2} from 'hooks/rest/useApiRequest2';
import {type DictionaryList, type ExerciseInstance} from 'components/Dictionary/shared/interface';
import Spinner from 'components/Spinner';
import Icon from 'components/Icon';

import './ExcerciseInfoTooltip.scss';

interface Props {
  listId: string;
  list?: DictionaryList;
  tooltipContainerSelector: string;
  placement?: TooltipProps['placement'];
}

export const ExerciseInfoTooltip: FC<Props> = ({
  listId,
  list,
  tooltipContainerSelector,
  placement
}) => {
  const [exerciseInfo, setExerciseInfo] = useState<ExerciseInstance | undefined>(
    () => list?.exerciseInstance
  );
  const [visible, setVisible] = useState(false);

  const getTooltipContainer = useCallback<() => HTMLElement>(
    () => document.querySelector(tooltipContainerSelector) || document.body,
    [tooltipContainerSelector]
  );

  const {apiRequest, isLoading: loading} = useApiRequest2<[string, [string]], DictionaryList>(
    requestDictionaryList,
    data => {
      setExerciseInfo(data.exerciseInstance);
      setVisible(true);
    },
    () => {}
  );

  const requestList = useCallback(() => {
    if (!exerciseInfo && !loading) {
      apiRequest(listId, ['exerciseInstance.unitInstance.unit.coursebook']);
    }
  }, [apiRequest, exerciseInfo, listId, loading]);

  const onClick = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      visible ? setVisible(false) : exerciseInfo ? setVisible(true) : requestList();
    },
    [exerciseInfo, requestList, visible]
  );

  return (
    <Tooltip
      overlay={loading || !exerciseInfo ? <div /> : <Overlay data={exerciseInfo} />}
      overlayClassName="exercise-info-overlay"
      trigger={['click']}
      destroyTooltipOnHide={true}
      placement={placement || 'bottomRight'}
      getTooltipContainer={getTooltipContainer}
      onVisibleChange={visible => !visible && setVisible(false)}
      visible={visible}
    >
      <Button className={classNames('btn-ico overview-trigger', {visible})} onClick={onClick}>
        {loading ? <Spinner size={14} /> : <Icon name="pc-book" />}
      </Button>
    </Tooltip>
  );
};

const Overlay: FC<{data: ExerciseInstance}> = ({data}) => (
  <div onClick={e => e.stopPropagation()}>
    <div className="overlay-row">
      <Icon name="pc-book" />
      <span>{data.unitInstance.unit.coursebook.title}</span>
    </div>
    <div className="overlay-row">
      <Icon name={data.unitInstance.unit.isRevision ? 'virc-test' : 'virc-unit'} />
      <span>
        {data.unitInstance.ordinal ? `${data.unitInstance.ordinal}. ` : ''}
        {data.unitInstance.unit.title}
      </span>
    </div>
  </div>
);
