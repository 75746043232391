import React, {type FC, useCallback, useContext} from 'react';
import {
  last,
  TrainerActionsContext,
  type WriteState,
  writeSubmit,
  useRelatedWords
} from '@englex/trainer';

import {Stage} from '../../shared';
import {Flashcard} from './Flashcard';
import {Stats} from './Stats';

interface Props {
  state: WriteState;
}

export const WriteTrainerStage: FC<Props> = ({state}) => {
  const {dispatch} = useContext(TrainerActionsContext);
  const word = state.words[last(state.remaining)];

  const relatedWords = useRelatedWords(word, state.words);

  const submitAnswer = useCallback(isCorrect => dispatch(writeSubmit(isCorrect)), [dispatch]);

  return (
    <Stage className="write">
      <Flashcard
        key={word.id}
        word={word}
        relatedWords={relatedWords}
        submitAnswer={submitAnswer}
      />
      <Stats state={state} />
    </Stage>
  );
};
