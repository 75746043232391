import React, {type FC} from 'react';

import Icon from 'components/Icon';

export const Hint: FC<{content: string}> = ({content}) => (
  <div className="hint">
    <Icon name="check-circle" />
    {content}
  </div>
);
