import React, {type FC} from 'react';
import {type Word} from '@englex/trainer';

import {Original} from './Original';
import './WordComponent.scss';

export const WordComponent: FC<{word: Word}> = ({word}) => {
  return (
    <div className="word">
      <Original word={word} />
      <div className="translation">{word.translation}</div>
    </div>
  );
};
