import React from 'react';
import {FormattedMessage} from 'react-intl';

import {type StartScreenChild} from '../../interface';
import './startScreenChildren.scss';

const FirstHint: StartScreenChild = () => <FormattedMessage id="Trainer.Learn.Hint1" />;

const CardsHint: StartScreenChild = () => (
  <>
    <div className="card original">
      <span className="message">an apple</span>
      <div className="choices">
        <div className="row-first">
          <div className="choice">груша</div>
          <div className="choice active">яблоко</div>
        </div>
        <div className="row-second">
          <div className="choice">слива</div>
          <div className="choice">персик</div>
        </div>
      </div>
    </div>
    <div className="action-hint">/</div>
    <div className="card translation">
      <span className="message">яблоко</span>
      <div className="pseudo-textarea">
        <span className="prefix">an</span> apple
      </div>
      <div className="pseudo-keyboard">
        <div className="key">a</div>
        <div className="key">e</div>
        <div className="key">l</div>
        <div className="key twice">p</div>
      </div>
    </div>
  </>
);

const SecondHint: StartScreenChild = () => <FormattedMessage id="Trainer.Learn.Hint2" />;

export {CardsHint, FirstHint, SecondHint};
