import React, {type FC} from 'react';
import classNames from 'classnames';

import './TrainerLayout.scss';

interface Props {
  className?: string;
}

export const TrainerLayout: FC<Props> = ({children, className}) => {
  return <div className={classNames('trainer', className)}>{children}</div>;
};
