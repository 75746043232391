import React, {type FC, useContext, useMemo} from 'react';
import classNames from 'classnames';
import {type IntlShape, useIntl} from 'react-intl';

import {EditEntryStateContext} from 'components/Dictionary/shared/contexts/entry/EditEntryContext';
import {useSearchingInODContext} from 'components/Dictionary/Sidebar/DictionaryArticle/contexts/searchingInODContext';
import {useDictionaryContext} from 'components/Dictionary/shared/contexts';

import {DirectionSwitcher} from './DirectionSwitcher';
import {FromCoursebookFilter} from './FromCoursebookFilter';
import {ListDirectionSwitcher} from './ListDirectionSwitcher';

import './Filter.scss';

interface Category {
  value: string;
  icon: string;
  parameter?: {
    key: string;
    paramValue: string;
  };
}
export interface Categories {
  [key: string]: Category;
}

const getCategories = (intl: IntlShape, isList: boolean, isTeacherDictionary: boolean) => {
  const items: Categories = {};

  if (!(isTeacherDictionary && isList)) {
    items.allWords = {
      value: intl.formatMessage({id: 'Dictionary.AllWords'}),
      icon: 'virc-vocabulary'
    };
  }

  if (!isTeacherDictionary) {
    items.itemsfromCoursebook = {
      value: intl.formatMessage({id: 'Dictionary.WordsFromCoursebook'}),
      icon: 'pc-book',
      parameter: {
        key: 'fromCoursebook',
        paramValue: 'true'
      }
    };

    items.extraWords = {
      value: intl.formatMessage({id: 'Dictionary.ExtraWords'}),
      icon: 'user',
      parameter: {
        key: 'fromCoursebook',
        paramValue: 'false'
      }
    };
  }

  if (!isList) {
    items.inLists = {
      value: intl.formatMessage({id: 'Dictionary.WordsNotInLists'}),
      icon: 'virc-no-list',
      parameter: {
        key: 'inLists',
        paramValue: 'false'
      }
    };
  }

  return items;
};

interface Props {
  disabled?: boolean;
  matchesAllEntries: boolean;
  matchesList: boolean;
}

export const Filter: FC<Props> = ({disabled, matchesAllEntries, matchesList}) => {
  const intl = useIntl();
  const {selectionMode} = useContext(EditEntryStateContext);
  const {isSearchingInOD} = useSearchingInODContext();
  const {isTeacherDictionary} = useDictionaryContext();

  const categories = useMemo(
    () => getCategories(intl, matchesList, isTeacherDictionary),
    [intl, matchesList, isTeacherDictionary]
  );
  const isCategoriesNotEmpty = !!Object.keys(categories).length;

  return (
    <ul
      className={classNames('dictionary-page-filter', {
        'selection-mode': !!selectionMode
      })}
    >
      {isCategoriesNotEmpty && (
        <FromCoursebookFilter
          categories={categories}
          isTeacherDictionary={isTeacherDictionary}
          disabled={disabled || isSearchingInOD}
        />
      )}
      {matchesAllEntries && <DirectionSwitcher disabled={disabled || isSearchingInOD} />}
      {matchesList && <ListDirectionSwitcher disabled={disabled || isSearchingInOD} />}
    </ul>
  );
};
