import React, {type FC, useCallback, memo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import {useDispatch} from 'react-redux';
import Tooltip from 'rc-tooltip';
import {minWordsCount} from '@englex/trainer';

import {push} from 'store/router';
import Icon from 'components/Icon';
import {useDictionaryContext} from 'components/Dictionary/shared/contexts';

import {trainerPathCreator} from '../../ListTrainer/utils';
import './TrainListButton.scss';

const TOOLTIP_TRIGGER = ['hover'];

interface Props {
  listEntryCount: number;
  listId: string;
  studentId: number;
}

export const TrainListButton: FC<Props> = memo(({listEntryCount, listId, studentId}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {dictionaryOwnerRole} = useDictionaryContext();

  const disabled = listEntryCount < minWordsCount;

  const onClick = useCallback(() => {
    dispatch(push(trainerPathCreator(studentId, listId, dictionaryOwnerRole)));
  }, [dictionaryOwnerRole, dispatch, listId, studentId]);

  const button = (
    <Button
      className="train-list-floating-button"
      bsStyle="success"
      disabled={disabled}
      title={intl.formatMessage({id: 'Dictionary.Train'})}
      onClick={onClick}
    >
      <Icon name="play" />
      <FormattedMessage id="Dictionary.Train" />
    </Button>
  );

  return disabled ? (
    <Tooltip
      overlay={intl.formatMessage({id: 'Dictionary.LeastWords'})}
      placement="top"
      trigger={TOOLTIP_TRIGGER}
    >
      <div>{button}</div>
    </Tooltip>
  ) : (
    button
  );
});
