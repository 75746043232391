import React from 'react';

import {DragLayer} from 'components/dnd/DragLayer';
import DragGap from 'components/XPlayer/components/DragGap';
import {DndTypes, type DragObject} from 'components/dnd/interface';

interface Item extends DragObject {
  answer: string;
}

const renderItem = (itemType: DndTypes, item: Item, style: React.CSSProperties) => {
  if (itemType !== DndTypes.ASSESSMENT_ITEM) return null;
  return <DragGap answer={item.answer} style={style} className="x-gap-drag-preview black" />;
};

export const AssessmentDragLayer = () => <DragLayer renderItem={renderItem} />;
