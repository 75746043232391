import React, {type FC, type FormEvent} from 'react';
import Radio from 'react-bootstrap/lib/Radio';
import classNames from 'classnames';
import {type RawWord, type TrueFalseValue, stripWordCurlyBrackets} from '@englex/trainer';

import {Hint} from '../Hint';

interface Props {
  id: string;
  index: number;
  closed: boolean;
  value: TrueFalseValue;
  words: Record<string, RawWord>;
  handleChange(e: FormEvent<Radio>): void;
}

export const Question: FC<Props> = React.memo(({id, index, closed, value, words, handleChange}) => {
  return (
    <div className={classNames('question', {closed})}>
      <div className="task">
        <div className="translation">
          <span className="index">{index}. </span>
          {words[id].translation}
        </div>
        <div className="original">
          {stripWordCurlyBrackets((value.pair ? words[value.pair] : words[id]).original)}
        </div>
      </div>
      <div className="choices">
        <Radio
          className={radioClassName(closed, !value.pair)}
          checked={value.answer === true}
          name={`${id}::true`}
          onChange={handleChange}
          disabled={closed}
        >
          <span>True</span>
        </Radio>
        <Radio
          className={radioClassName(closed, !!value.pair)}
          checked={value.answer === false}
          name={`${id}::false`}
          onChange={handleChange}
          disabled={closed}
        >
          <span>False</span>
        </Radio>
      </div>
      {closed && value.pair && value.answer !== false && (
        <Hint content={stripWordCurlyBrackets(words[id].original)} />
      )}
    </div>
  );
});

const radioClassName = (closed: boolean, correct: boolean) =>
  !closed ? 'choice' : classNames('choice closed', {correct});
