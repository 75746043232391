import React, {type FC} from 'react';
import {type WriteState} from '@englex/trainer';

import {ResultsPage, CorrectCount, IncorrectCount} from '../../shared';

export const WriteResults: FC<{state: WriteState}> = ({state: {correct, incorrect, words}}) => (
  <ResultsPage<'incorrect' | 'correct'>
    defaultKey={incorrect.length ? 'incorrect' : 'correct'}
    map={{
      incorrect: {ids: incorrect, LabelComponent: IncorrectCount},
      correct: {ids: correct, LabelComponent: CorrectCount}
    }}
    targetKey="correct"
    words={words}
  />
);
