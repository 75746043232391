import React, {type FC, useCallback, useRef, useState} from 'react';
import ReactResizeDetector from 'react-resize-detector';
import Scrollbars from 'react-custom-scrollbars';
import classNames from 'classnames';

import './Stage.scss';

interface Props {
  className?: string;
  withScrollListener?: boolean;
}

export const Stage: FC<Props> = React.memo(({children, withScrollListener, ...props}) => {
  return withScrollListener ? (
    <ScrollListenerStage {...props}>{children}</ScrollListenerStage>
  ) : (
    <SimpleStage {...props}>{children}</SimpleStage>
  );
});

const SimpleStage: FC<Props> = ({children, className}) => (
  <Scrollbars className={classNames('stage-scrollbars', className)}>
    <div className={classNames('stage', className)}>{children}</div>
  </Scrollbars>
);

export const ScrollContext = React.createContext<{
  clientTop?: number;
  scrollTop?: number;
}>({});

const ScrollListenerStage: FC<Props> = ({children, className}) => {
  const [clientTop, setClientTop] = useState<number | undefined>();
  const [scrollTop, setScrollTop] = useState<number | undefined>();

  const scrollbarRef = useRef<Scrollbars | null>(null);
  const stageBoxRef = useRef<HTMLDivElement | null>(null);

  const onResize = useCallback(
    () => setClientTop(stageBoxRef.current?.getBoundingClientRect().top),
    []
  );

  const onScrollStop = useCallback(
    () => setScrollTop(scrollbarRef.current?.getValues().scrollTop),
    []
  );

  return (
    <ScrollContext.Provider
      value={{
        clientTop,
        scrollTop
      }}
    >
      <ReactResizeDetector onResize={onResize} refreshRate={16} refreshMode="throttle">
        <div className="stage-box" ref={stageBoxRef}>
          <Scrollbars
            ref={scrollbarRef}
            className={classNames('stage-scrollbars', className)}
            onScrollStop={onScrollStop}
          >
            <div className={classNames('stage', className)}>{children}</div>
          </Scrollbars>
        </div>
      </ReactResizeDetector>
    </ScrollContext.Provider>
  );
};
