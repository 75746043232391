import {useDispatch, useSelector} from 'react-redux';

import {replace} from 'store/router';
import {type AppState, type Recipient, type StudentTeachers} from 'store/interface';
import {setSelectedStudentTeacher, setStudentTeachers} from 'store/studentTeachers/actions';
import {useStudentTeachers} from 'components/Dictionary/hooks/useStudentTeachers';
import {studentDictionaryPath} from 'common/paths';

export const useStudentTeachersInDictionary = (studentId: number) => {
  const dispatch = useDispatch();
  const selectedStudentTeacher = useSelector<AppState, number | undefined>(
    s => s.studentTeachers?.selectedStudentTeacher
  );
  const studentTeachers = useSelector<AppState, StudentTeachers | undefined>(
    s => s.studentTeachers?.studentTeachers
  );
  const isStudent = useSelector<AppState, boolean>(s => s.user.role === 'student');

  const {isError, isLoading, reload} = useStudentTeachers(
    studentTeachers => {
      const studentTeachersObject: StudentTeachers = {};
      let studentIdIsValid = false;
      for (const st of studentTeachers) {
        studentTeachersObject[st.id] = {
          ...st,
          recipient: (isStudent ? st.teacher : st.student) as Recipient
        };
        if (Number(st.student_id) === studentId) studentIdIsValid = true;
      }

      dispatch(setStudentTeachers(studentTeachersObject));
      if (!studentIdIsValid && isStudent) {
        if (selectedStudentTeacher && studentTeachersObject[selectedStudentTeacher]) {
          dispatch(
            replace(studentDictionaryPath(studentTeachersObject[selectedStudentTeacher].student_id))
          );
        } else {
          const firstActiveStudentTeacher = studentTeachers.find(st => !st.deleted_at);
          const studentTeacherToSelect = firstActiveStudentTeacher || studentTeachers[0];
          dispatch(setSelectedStudentTeacher(studentTeacherToSelect.id, isStudent));
          dispatch(replace(studentDictionaryPath(studentTeacherToSelect.student!.id!)));
        }
      } else {
        const studentTeachersValues = Object.values(studentTeachersObject);
        const studentTeacherId = studentTeachersValues.find(
          studentTeacher => !studentTeacher.deleted_at
        )?.id;
        if (!selectedStudentTeacher && studentTeacherId)
          dispatch(setSelectedStudentTeacher(studentTeacherId, isStudent));
      }
    },
    true,
    isStudent
  );

  return {isError, isLoading, reload, studentTeachers};
};
