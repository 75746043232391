import React, {type FC, type FormEvent} from 'react';
import Radio from 'react-bootstrap/lib/Radio';
import classNames from 'classnames';
import {type RawWord, stripWordCurlyBrackets} from '@englex/trainer';

interface Props {
  questionId: string;
  choiceId: string;
  checked: boolean;
  closed: boolean;
  correct: boolean;
  words: Record<string, RawWord>;
  handleChange(e: FormEvent<Radio>): void;
}

export const Choice: FC<Props> = React.memo(
  ({questionId, choiceId, checked, closed, correct, words, handleChange}) => {
    const original = stripWordCurlyBrackets(words[choiceId].original);

    return (
      <Radio
        className={radioClassName(closed, correct)}
        checked={checked}
        onChange={handleChange}
        name={`${questionId}::${choiceId}`}
        disabled={closed}
      >
        <span>{original}</span>
      </Radio>
    );
  }
);

const radioClassName = (closed: boolean, correct: boolean) =>
  !closed ? 'choice' : classNames('choice closed', {correct});
