import React, {type FC, memo, useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {Link, useParams} from 'react-router-dom';
import {TrainerType} from '@englex/trainer';

import {push} from 'store/router';
import DropDown, {type DropDownItem, type DropDownItems} from 'components/DropDown';
import Icon from 'components/Icon';
import {Breadcrumb} from 'components/Breadcrumbs/Breadcrumb';
import {useDictionaryContext} from 'components/Dictionary/shared/contexts';
import {enumValues} from 'helpers/enum';

import {trainerPathCreator, trainerTypePathCreator} from '../../../ListTrainer/utils';
import {type Activity, type TrainerUrlParams} from '../../../ListTrainer/pages/interface';
import {useActivities} from '../../../ListTrainer/hooks/useActivities';
import './TrainerBreadcrumbs.scss';

export const TrainerBreadcrumbs: FC = memo(() => {
  const dispatch = useDispatch();
  const activities = useActivities();
  const {dictionaryOwnerRole, dictionaryOwnerId} = useDictionaryContext();

  const {'*': trainerType, listId} = useParams<TrainerUrlParams>();

  const isTrainerType = enumValues(TrainerType).includes(trainerType);

  const items = useMemo<{[key: string]: DropDownItem}>(
    () => dropdownItems(activities) as {[key: string]: DropDownItem},
    [activities]
  );

  const onDropdownChange = useCallback(
    (key: TrainerType) => {
      if (isTrainerType && Object.values(TrainerType).includes(key) && trainerType !== key) {
        dispatch(
          push(trainerTypePathCreator(dictionaryOwnerId, listId!, dictionaryOwnerRole, key))
        );
      }
    },
    [dictionaryOwnerId, dictionaryOwnerRole, dispatch, isTrainerType, listId, trainerType]
  );

  return (
    <>
      <Breadcrumb className="trainer-breadcrumb">
        {isTrainerType ? (
          <Link to={trainerPathCreator(dictionaryOwnerId, listId!, dictionaryOwnerRole)}>
            <TrainerBreadcrumbTitle />
          </Link>
        ) : (
          <TrainerBreadcrumbTitle />
        )}
      </Breadcrumb>
      {isTrainerType && (
        <Breadcrumb className="activity-type-breadcrumb">
          <DropDown
            activeKey={trainerType}
            items={items}
            placement="bottomLeft"
            onChange={onDropdownChange}
            value={
              <>
                <span className="breadcrumb-icon always-visible-icon">
                  <Icon name={items[trainerType!]?.icon!} />
                </span>
                <span className="breadcrumb-title">{items[trainerType!]?.value}</span>
                <Icon name="caret-down" />
              </>
            }
            trigger={['click']}
            noCaret={true}
          />
        </Breadcrumb>
      )}
    </>
  );
});

const TrainerBreadcrumbTitle = () => (
  <>
    <span className="breadcrumb-icon">
      <Icon name="play" />
    </span>
    <span className="breadcrumb-title">
      <FormattedMessage id="Trainer.Trainer" />
    </span>
  </>
);

const dropdownItems = (activities: Activity[]): DropDownItems => {
  return activities.reduce<DropDownItems>((r, {type, title, icon}) => {
    r[type] = {icon, value: title};
    return r;
  }, {});
};
