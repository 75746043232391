import React, {type FC} from 'react';

import {type SelectionAndDragging} from './interface';
import {Target} from './Target';
import {type ExerciseProps, type MatchingExercise} from '../interface';

export const Targets: FC<ExerciseProps<MatchingExercise> & SelectionAndDragging> = ({
  closed,
  dispatch,
  draggingState,
  selectionState,
  exercise: matching,
  words
}) => {
  return (
    <div className="targets">
      {Object.entries(matching).map(([key, value], i) => (
        <Target
          key={key}
          id={key}
          index={i + 1}
          closed={closed}
          words={words}
          value={value}
          draggingState={draggingState}
          selectionState={selectionState}
          dispatch={dispatch}
        />
      ))}
    </div>
  );
};
