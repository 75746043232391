import React, {type FC, useCallback, useEffect, useMemo} from 'react';
import classNames from 'classnames';
import {useKeyboard} from '@englex/trainer';

import Icon from 'components/Icon';

import {longTextBoundary} from '../../../../../static';
import './Keyboard.scss';

interface Props {
  original: string;
  setValue(value: string): void;
  value: string;
}

const extractLetter = (char: string): string | undefined =>
  char.length > 1 ? char[char.length - 1] : undefined;

const getCount = (element: Element): number =>
  +element.attributes['data-count']?.value ||
  +element.parentElement?.attributes['data-count']?.value;

const getChar = (element: Element): [string, string] => {
  const char = element['id'].split('--')[1] || element.parentElement?.['id'].split('--')[1] || '';
  if (char === 'backspace') return ['backspace', 'backspace'];
  if (char) {
    const letter = extractLetter(char);
    return letter ? [char, letter] : [char, char];
  }
  return ['', ''];
};

export const Keyboard: FC<Props> = ({original, setValue, value}) => {
  const {
    keyboardState: {available: chars},
    inputChar,
    prefix
  } = useKeyboard(original, value, setValue);

  const keyboardEntries = useMemo(() => Object.entries(chars), [chars]);

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!getCount(e.target as Element)) return;
      const [char, letter] = getChar(e.target as Element);
      if (chars[char]) inputChar(char, letter);
    },
    [chars, inputChar]
  );

  const keyboardListener = useCallback(
    (e: KeyboardEvent) => {
      if (['TEXTAREA', 'INPUT'].includes((e.target as HTMLElement)?.tagName)) return;
      const eKey = e.key;

      if (eKey === 'Backspace') {
        e.preventDefault();
        inputChar('backspace', 'backspace');
        return;
      }

      const entry = keyboardEntries.find(
        ([char, count]) => char.includes(eKey) && count && char !== 'backspace'
      );
      if (entry) {
        const letter = extractLetter(entry[0]);
        if (letter) {
          e.preventDefault();
          inputChar(entry[0], letter);
        }
      }
    },
    [inputChar, keyboardEntries]
  );

  useEffect(() => {
    document.addEventListener('keydown', keyboardListener);
    return () => document.removeEventListener('keydown', keyboardListener);
  }, [keyboardListener]);

  return (
    <>
      <div className={classNames('input', {long: value.length >= longTextBoundary})}>
        {prefix ? <span className="prefix">{prefix}</span> : null}
        {value}
      </div>
      <div className={classNames('keyboard', {'small-buttons': keyboardEntries.length > 12})}>
        {keyboardEntries.map(([char, count]) => {
          const backspace = char === 'backspace';
          const letter = extractLetter(char);
          const space = letter === ' ';
          const slash = letter === '/';

          return (
            <div
              key={char}
              className={classNames('key', {backspace, space, slash, disabled: !count})}
              id={`keyboard-char--${char}`}
              onClick={onClick}
              data-count={count}
            >
              {backspace || count < 2 ? null : <span className="count">{count}</span>}
              <CharIcon letter={letter} isBackspace={backspace} isSpace={space} />
            </div>
          );
        })}
      </div>
    </>
  );
};

const CharIcon: FC<{letter?: string; isBackspace: boolean; isSpace: boolean}> = ({
  letter,
  isBackspace,
  isSpace
}) => {
  if (isBackspace) return <Icon name="virc-backspace" />;
  return <>{isSpace ? '⎵' : letter}</>;
};
