import {useEffect, useMemo, useState} from 'react';

import {stringBooleanToBoolean} from 'store/dictionary/stringBooleanToBoolean';

export const useFilter = (
  search: string
): [{search?: string; fromCoursebook?: boolean}, boolean] => {
  const [searchState, setSearchState] = useState<string | null>();
  const [fromCoursebook, setFromCoursebook] = useState<string | null>();
  const [inLists, setInLists] = useState<string | null>();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    setInitialized(true);
    const searchParams = new URLSearchParams(search);

    const fromCoursebookParam = searchParams.get('fromCoursebook');
    const searchParam = searchParams.get('search');
    const inListsParam = searchParams.get('inLists');

    if (
      searchState === searchParam &&
      fromCoursebook === fromCoursebookParam &&
      inLists === inListsParam
    )
      return;

    setFromCoursebook(fromCoursebookParam);
    setSearchState(searchParam);
    setInLists(inListsParam);
  }, [fromCoursebook, searchState, search, inLists]);

  const params = useMemo(
    () => ({
      search: searchState?.trim() || undefined,
      fromCoursebook: stringBooleanToBoolean(fromCoursebook),
      inLists: stringBooleanToBoolean(inLists)
    }),
    [fromCoursebook, inLists, searchState]
  );

  return [params, initialized];
};
