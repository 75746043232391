import React, {type FC, type FormEvent, useCallback} from 'react';
import {type Immutable} from 'immer';
import type Radio from 'react-bootstrap/lib/Radio';
import {type MultipleChoiceValue, submitChoice} from '@englex/trainer';

import {type ExerciseProps} from '../interface';
import {AssessmentExerciseLayout} from '../AssessmentExerciseLayout';
import {Question} from './Question';
import './MultipleChoice.scss';

export const MultipleChoice: FC<ExerciseProps<Immutable<Record<string, MultipleChoiceValue>>>> =
  React.memo(({closed, dispatch, exercise: multipleChoice, words}) => {
    const handleChange = useCallback(
      (e: FormEvent<Radio>) => {
        const [id, answerId] = e.target['name'].split('::');
        dispatch(submitChoice(id, answerId));
      },
      [dispatch]
    );
    return (
      <AssessmentExerciseLayout
        className="multiple-choice"
        title="Select correct translations of the words and phrases."
      >
        {Object.entries(multipleChoice).map(([key, value], i) => (
          <Question
            key={key}
            id={key}
            index={i + 1}
            closed={closed}
            handleChange={handleChange}
            value={value}
            words={words}
          />
        ))}
      </AssessmentExerciseLayout>
    );
  });
