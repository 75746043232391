import React, {type FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {type IntlShape, useIntl} from 'react-intl';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import {useLocation} from 'react-router-dom';

import {push, replace} from 'store/router';
import DropDown from 'components/DropDown';
import Icon from 'components/Icon';
import {useDictionaryContext} from 'components/Dictionary/shared/contexts';

type Key = 'all_lists' | 'true' | 'false';

const defaultKey = 'all_lists';

export const DictionaryFilter: FC = () => {
  const intl = useIntl();
  const {search} = useLocation();
  const dispatch = useDispatch();

  const {isTeacherDictionary} = useDictionaryContext();

  const [selectedKey, setSelectedKey] = useState<Key>(defaultKey);
  const [showArchivedLists, setShowArchivedLists] = useState(false);

  const selectedKeyRef = useRef<string>();
  const showArchivedListsRef = useRef<boolean>();
  selectedKeyRef.current = selectedKey;
  showArchivedListsRef.current = showArchivedLists;

  const items = useMemo(() => getItems(intl), [intl]);

  const dropdownHandler = useCallback(
    (key: Key) => {
      if (key === selectedKey) return;
      const searchParams = new URLSearchParams(search);
      key !== defaultKey
        ? searchParams.set('fromCoursebook', key)
        : searchParams.delete('fromCoursebook');
      setSelectedKey(key);
      dispatch(push({search: searchParams.toString()}));
    },
    [dispatch, search, selectedKey]
  );

  const checkboxHandler = useCallback(() => {
    const checked = !showArchivedLists;
    const searchParams = new URLSearchParams(search);
    checked ? searchParams.set('show_archived', 'true') : searchParams.delete('show_archived');
    setShowArchivedLists(checked);
    dispatch(push({search: searchParams.toString()}));
  }, [dispatch, search, showArchivedLists]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const fromCoursebookParam = searchParams.get('fromCoursebook');
    if (
      fromCoursebookParam &&
      fromCoursebookParam !== selectedKeyRef.current &&
      items[fromCoursebookParam]
    )
      return setSelectedKey(fromCoursebookParam as Key);
    if (!fromCoursebookParam && selectedKeyRef.current !== defaultKey)
      return setSelectedKey(defaultKey);
    if (fromCoursebookParam && !items[fromCoursebookParam]) {
      searchParams.delete('fromCoursebook');
      dispatch(replace({search: searchParams.toString()}));
    }
  }, [dispatch, items, search]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const showArchivedParam = searchParams.get('show_archived');
    if (!showArchivedListsRef.current && showArchivedParam === 'true') {
      return setShowArchivedLists(true);
    }
    if (showArchivedListsRef.current && !showArchivedParam) {
      return setShowArchivedLists(false);
    }
    if (showArchivedParam && showArchivedParam !== 'true') {
      if (showArchivedListsRef.current) setShowArchivedLists(false);
      searchParams.delete('show_archived');
      dispatch(replace({search: searchParams.toString()}));
    }
  }, [dispatch, search]);

  return (
    <div className="dictionary-filter">
      {!isTeacherDictionary && (
        <div className="dropdown-container">
          <DropDown
            activeKey={selectedKey}
            items={items}
            value={
              <span>
                <Icon name={items[selectedKey].icon} />
                <span className="text">{items[selectedKey].value}</span>
                <Icon name="angle-down" />
              </span>
            }
            trigger={['click']}
            onChange={dropdownHandler}
            noCaret={true}
          />
        </div>
      )}
      <div className="archive-filter">
        <Checkbox onChange={checkboxHandler} checked={showArchivedLists}>
          <span>{intl.formatMessage({id: 'Dictionary.ShowArchivedLists'})}</span>
        </Checkbox>
      </div>
    </div>
  );
};

const getItems = (intl: IntlShape) => ({
  [defaultKey]: {value: intl.formatMessage({id: 'Dictionary.AllLists'}), icon: 'virc-vocabulary'},
  true: {
    value: intl.formatMessage({id: 'Dictionary.ListsFromCoursebook'}),
    icon: 'pc-book'
  },
  false: {
    value: intl.formatMessage({id: 'Dictionary.ExtraLists'}),
    icon: 'user'
  }
});
