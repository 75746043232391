import {useEffect, useState} from 'react';

import {type DictionaryList} from 'components/Dictionary/shared/interface';

export const useListsFilter = (lists: DictionaryList[], search: string): DictionaryList[] => {
  const [filteredLists, setFilteredLists] = useState<DictionaryList[]>(lists);
  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const fromCoursebookParam = urlParams.get('fromCoursebook') || '';
    switch (fromCoursebookParam) {
      case 'true':
        setFilteredLists(lists.filter(l => l.exerciseInstanceId));
        break;
      case 'false':
        setFilteredLists(lists.filter(l => !l.exerciseInstanceId));
        break;
      default:
        setFilteredLists(lists);
    }
  }, [lists, search]);

  return filteredLists;
};
