import React, {type FC, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {type IntlShape, useIntl} from 'react-intl';

import {setIsReverse} from 'store/dictionary/actions';
import {type AppState} from 'store/interface';
import DropDown from 'components/DropDown';
import Icon from 'components/Icon';

interface Props {
  disabled: boolean;
}

export const DirectionSwitcher: FC<Props> = ({disabled}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isReverse = useSelector<AppState, boolean>(s => !!s.dictionary?.isReverse);

  const items = useMemo(() => getItems(intl), [intl]);

  const onChange = (key: string) => {
    if (key === String(isReverse) || disabled) return;
    dispatch(setIsReverse(key === 'true'));
  };

  return (
    <DropDown
      activeKey={String(isReverse)}
      className="rc-dropdown direction-switcher"
      items={items}
      value={<Icon name={items[String(isReverse)].icon} />}
      noCaret={true}
      onChange={onChange}
      placement="bottomRight"
      trigger={['click']}
      disabled={disabled}
    />
  );
};

const getItems = (intl: IntlShape) => ({
  true: {
    value: intl.formatMessage({id: 'Dictionary.NewestAtTheBottom'}),
    icon: 'sort-1-31'
  },
  false: {
    value: intl.formatMessage({id: 'Dictionary.NewestAtTheTop'}),
    icon: 'sort-31-1'
  }
});
