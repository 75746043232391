import React, {type FC} from 'react';
import classNames from 'classnames';

import './ActivityProgress.scss';

interface Props {
  className?: string;
  progress: number;
  total: number;
}

export const ActivityProgress: FC<Props> = ({className, progress, total}) => {
  return (
    <div className={classNames('activity-progress', className)}>
      <div className="progress-amount" style={{width: (progress / total) * 100 + '%'}} />
    </div>
  );
};
