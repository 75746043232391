import React, {type FC} from 'react';
import {type Immutable} from 'immer';
import {TextareaAutosize} from 'react-autosize-textarea/lib/TextareaAutosize';
import classNames from 'classnames';
import {
  type WriteValue,
  type RawWord,
  compareOriginalToInput,
  stripWordCurlyBrackets
} from '@englex/trainer';

import {Hint} from '../Hint';

interface Props {
  id: string;
  index: number;
  value: Immutable<WriteValue>;
  closed: boolean;
  words: Record<string, RawWord>;
  onInput(e: React.FormEvent<HTMLTextAreaElement>): void;
}

export const Question: FC<Props> = React.memo(
  ({id, index, closed, value: {answers, answer}, words, onInput}) => {
    const correct = closed
      ? answers.some(wordId => compareOriginalToInput(words[wordId].original, answer, true))
      : true;

    return (
      <div className="question">
        <div className="task">
          <span className="index">{index}. </span>
          {words[id].translation}
        </div>
        <TextareaAutosize
          autoCapitalize="off"
          async={true}
          className={textareaClassName(closed, correct)}
          id={`${id}::writing`}
          maxLength={255}
          value={answer}
          onChange={onInput}
          disabled={closed}
        />
        {!correct && <Hint content={stripWordCurlyBrackets(words[id].original)} />}
      </div>
    );
  }
);

const textareaClassName = (closed: boolean, correct: boolean): string =>
  !closed ? 'textarea' : classNames('textarea closed', {correct});
