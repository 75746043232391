import React, {type Dispatch, type FC, useCallback} from 'react';
import {useDrop} from 'react-dnd';
import classNames from 'classnames';
import {type Immutable} from 'immer';
import {
  type MatchingValue,
  type RawWord,
  submitMatch,
  stripWordCurlyBrackets
} from '@englex/trainer';

import DragGap from 'components/XPlayer/components/DragGap';
import {DndTypes} from 'components/dnd/interface';

import {DragItem} from './DragItem';
import {type MatchingDragObject, type SelectionAndDragging} from './interface';
import {Hint} from '../Hint';

interface StaticProps {
  id: string;
  words: Record<string, RawWord>;
  value: Immutable<MatchingValue>;
  correct: boolean;
}

interface Props extends Omit<StaticProps, 'correct'>, SelectionAndDragging {
  index: number;
  closed: boolean;
  dispatch: Dispatch<unknown>;
}

export const Target: FC<Props> = ({id, closed, index, value, ...props}) => {
  const children = (
    <div className="translation">
      <span className="index">
        {index}
        {'. '}
      </span>
      {props.words[id].translation}
    </div>
  );

  if (closed) {
    const correct = !!value.answer && value.answers.includes(value.answer);

    return (
      <StaticTarget id={id} value={value} correct={correct} {...props}>
        {children}
      </StaticTarget>
    );
  }

  return (
    <DynamicTarget id={id} value={value} {...props}>
      {children}
    </DynamicTarget>
  );
};

const DynamicTarget: FC<Omit<Props, 'closed' | 'index'>> = React.memo(
  ({children, draggingState, selectionState, id, words, dispatch, value: {answer}}) => {
    const [selection, select] = selectionState;
    const [{isOver}, drop] = useDrop<MatchingDragObject, unknown, {isOver: boolean}>({
      accept: DndTypes.ASSESSMENT_ITEM,
      canDrop: item => id !== item.sourceId,
      drop: item => dispatch(submitMatch(id, item.id, item.sourceId)),
      collect: monitor => ({isOver: monitor.isOver({shallow: true})})
    });

    const onClick = useCallback(() => {
      if (selection) {
        if (id !== selection?.sourceId)
          dispatch(submitMatch(id, selection?.itemId, selection?.sourceId));
        select(undefined);
      }
    }, [dispatch, id, select, selection]);

    return (
      <div
        ref={drop}
        className={classNames('target', {'selection-on': !!selection})}
        onClick={onClick}
      >
        {children}
        <div
          className={classNames('drop-target', {
            'is-over': isOver || (!answer && !!selection),
            'value-is-dragging': draggingState[0] === answer,
            'value-is-selected': selection?.itemId === answer,
            filled: !!answer
          })}
        >
          {answer && (
            <DragItem
              id={answer}
              sourceId={id}
              words={words}
              setDragged={draggingState[1]}
              selectionState={selectionState}
            />
          )}
        </div>
      </div>
    );
  }
);

const StaticTarget: FC<StaticProps> = ({children, id, words, value: {answer}, correct}) => {
  return (
    <div className={classNames('target closed', {correct})}>
      {children}
      <div className="drop-target-container">
        <div className={classNames('drop-target closed', {filled: !!answer})}>
          {answer && (
            <DragGap
              flexHandle={true}
              className={classNames('x-dnd-card closed', {correct})}
              answer={stripWordCurlyBrackets(words[answer].original)}
            />
          )}
        </div>
        {!correct && <Hint content={stripWordCurlyBrackets(words[id].original)} />}
      </div>
    </div>
  );
};
