import React, {type FC, type FormEvent, useCallback} from 'react';
import type Radio from 'react-bootstrap/lib/Radio';
import {submitTrueFalse, type TrueFalseValue} from '@englex/trainer';

import {AssessmentExerciseLayout} from '../AssessmentExerciseLayout';
import {type ExerciseProps} from '../interface';
import {Question} from './Question';
import './TrueFalse.scss';

export const TrueFalse: FC<ExerciseProps<Record<string, TrueFalseValue>>> = React.memo(
  ({closed, dispatch, exercise: trueFalse, words}) => {
    const handleChange = useCallback(
      (e: FormEvent<Radio>) => {
        const [id, answerId] = e.target['name'].split('::');
        dispatch(submitTrueFalse(id, answerId === 'true'));
      },
      [dispatch]
    );
    return (
      <AssessmentExerciseLayout
        className="true-false"
        title={() => (
          <span>
            Choose <em>True</em> or <em>False</em>
          </span>
        )}
      >
        {Object.entries(trueFalse).map(([key, value], i) => (
          <Question
            key={key}
            id={key}
            index={i + 1}
            closed={closed}
            value={value}
            words={words}
            handleChange={handleChange}
          />
        ))}
      </AssessmentExerciseLayout>
    );
  }
);
