import React, {type ComponentType, useState} from 'react';
import Tab from 'react-bootstrap/lib/Tab';
import Nav from 'react-bootstrap/lib/Nav';
import NavItem from 'react-bootstrap/lib/NavItem';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import {type Immutable} from 'immer';
import {objectSize, type Word} from '@englex/trainer';

import {List} from './components/List';
import './styles.scss';

type V = {
  LabelComponent: ComponentType<{count: number}>;
  ids: Immutable<string[]>;
};

interface Props<Key extends string = string> {
  congratulations?: string;
  defaultKey: Key;
  map: Record<Key, V>;
  targetKey: Key;
  words: Record<string, Word>;
}

export const ResultsPage = <K extends string>({
  congratulations,
  defaultKey,
  map,
  targetKey,
  words
}: Props<K>) => {
  const [activeKey, setActiveKey] = useState<K>(defaultKey);
  const isCompleted = map[targetKey].ids.length === objectSize(words);
  return (
    <div className="results">
      <Tab.Container
        activeKey={activeKey}
        id="trainer-results-page"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSelect={setActiveKey as any}
      >
        <div className="tabs-container">
          <div className="header">
            <h3 className="message">
              {isCompleted ? (
                congratulations || <FormattedMessage id="Trainer.Congratulations" />
              ) : (
                <FormattedMessage id="Trainer.KeepItUp" />
              )}
            </h3>
            <Nav bsStyle="tabs" className={classNames({hide: isCompleted})}>
              {Object.entries(map).map(([key, obj]: [string, V]) => (
                <NavItem key={key} eventKey={key} className={`nav-item nav-item-${key}`}>
                  <obj.LabelComponent count={obj.ids.length} />
                </NavItem>
              ))}
            </Nav>
          </div>
          <Tab.Content>
            {Object.entries(map).map(([key, obj]: [string, V]) => (
              <Tab.Pane key={key} eventKey={key}>
                <List ids={obj.ids} words={words} />
              </Tab.Pane>
            ))}
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
  );
};
