import React, {type FC, useCallback, useContext, useState} from 'react';
import Button, {type ButtonProps} from 'react-bootstrap/lib/Button';
import {useIntl} from 'react-intl';
import classNames from 'classnames';
import {useIsMounted} from '@englex/react-hooks/lib/useIsMounted';
import {TrainerActionsContext, TrainerContext} from '@englex/trainer';

import * as toastr from 'components/toastr';
import Confirm from 'components/modals/Confirm';

interface Props extends ButtonProps {
  onRefreshed?(): void;
  refreshHandler?(): void;
}

export const RefreshButton: FC<Props> = ({
  children,
  className,
  onRefreshed,
  refreshHandler,
  ...rest
}) => {
  const intl = useIntl();
  const {loadTrainer} = useContext(TrainerActionsContext);
  const {trainerState} = useContext(TrainerContext);
  const [showConfirm, setShowConfirm] = useState(false);
  const {loading, currentType} = trainerState;

  const isMounted = useIsMounted();

  return (
    <>
      <Button
        className={classNames('refresh-btn', className)}
        bsStyle="danger"
        onClick={useCallback(() => setShowConfirm(true), [])}
        {...rest}
      >
        {children}
      </Button>
      <Confirm
        headerText={intl.formatMessage({id: 'Trainer.Flashcards.RefreshConfirm'})}
        disableButtons={!!loading}
        onAccept={useCallback(() => {
          if (!currentType) return;
          if (refreshHandler) refreshHandler();
          else
            loadTrainer(currentType)
              .then(() => {
                onRefreshed?.();
                isMounted.current && setShowConfirm(false);
              })
              .catch(() => {
                toastr.error('', intl.formatMessage({id: 'Trainer.Flashcards.RefreshError'}));
              });
        }, [currentType, refreshHandler, loadTrainer, onRefreshed, isMounted, intl])}
        onDecline={useCallback(() => setShowConfirm(false), [])}
        show={showConfirm}
      />
    </>
  );
};
