import React, {type FC} from 'react';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';

import Icon from 'components/Icon';

import {type StartScreenChild} from '../../interface';
import './startScreenChildren.scss';

const FirstHint: StartScreenChild = () => <FormattedMessage id="Trainer.Assessment.Hint1" />;

const ExampleCards: FC<{filled: boolean}> = ({filled}) => (
  <div className="example-cards">
    <div className="example-card example-card-top">
      <span className="translation">яблоко</span>
      <div className="choices">
        {['an apple', 'a pear'].map((w, i) => (
          <div className="original-row" key={i}>
            <div className={classNames('outer-circle', {active: !i && filled})}>
              {!i && filled && <div className="inner-circle" />}
            </div>
            <div className="original">{w}</div>
          </div>
        ))}
      </div>
    </div>
    <div className="example-card example-card-bottom">
      <span className="translation">яблоко</span>
      <div className="pseudo-textarea">{filled && 'an apple'}</div>
    </div>
  </div>
);

const CardsHint: StartScreenChild = () => (
  <>
    <ExampleCards filled={false} />
    <div className="action-hint">
      <Icon name="hand-pointer-o" /> / <Icon name="keyboard-o" />
    </div>
    <ExampleCards filled={true} />
  </>
);

const SecondHint: StartScreenChild = () => <FormattedMessage id="Trainer.Assessment.Hint2" />;

export {CardsHint, FirstHint, SecondHint};
