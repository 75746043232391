import {type Action} from 'redux';

import {type DictionaryList} from '../../interface';
import {SET_LIST_TO_DELETE, SET_LIST_TO_EDIT} from '../actionTypes';

export interface EditListAction extends Action {
  list?: DictionaryList;
}
export const setListToDelete = (list?: DictionaryList): EditListAction => ({
  type: SET_LIST_TO_DELETE,
  list
});
export const editList = (list?: DictionaryList): EditListAction => ({type: SET_LIST_TO_EDIT, list});
