import React, {type FC, useContext, memo} from 'react';
import {useSelector} from 'react-redux';

import {type AppState, type Role} from 'store/interface';

import {EditEntryStateContext} from '../contexts/entry/EditEntryContext';
import {ActiveSelectionControls} from './ActiveSelectionControls';
import {EnterSelectionModeButton} from './EnterSelectionModeButton';
import {type DictionaryEntryInstance} from '../interface';
import {DeleteEntriesFromListModal} from './DeleteEntriesFromListModal';
import {DeleteEntriesFromDictionaryModal} from './DeleteEntriesFromDictionaryModal';

import './SelectionControls.scss';

interface Props {
  deletionForbidden?: boolean;
  entries: DictionaryEntryInstance[];
  hasLists: boolean;
  listId?: string;
  role?: Role;
  isTeacherDictionary: boolean;
}

export const SelectionControls: FC<Props> = memo(
  ({deletionForbidden, entries, hasLists, listId, role, isTeacherDictionary}) => {
    const userId = useSelector((state: AppState) => state.user.id!);
    const userRole = useSelector((state: AppState) => state.user.role!);
    const {selectionMode} = useContext(EditEntryStateContext);

    const deleteIsDisabled = entries.some(
      entry =>
        selectionMode?.entryIds.includes(entry.id) &&
        entry.createdById !== userId &&
        userRole === 'teacher'
    );

    return (
      <>
        {!selectionMode ? (
          <EnterSelectionModeButton listId={listId} />
        ) : (
          <ActiveSelectionControls
            deletionForbidden={deletionForbidden}
            hasLists={hasLists}
            entries={entries}
            listId={listId}
            selectedEntryIds={selectionMode.entryIds}
            role={role}
            isTeacherDictionary={isTeacherDictionary}
            deleteIsDisabled={deleteIsDisabled}
          />
        )}
        {listId ? (
          <DeleteEntriesFromListModal selectionMode={selectionMode} />
        ) : (
          <DeleteEntriesFromDictionaryModal selectionMode={selectionMode} />
        )}
      </>
    );
  }
);
