import React, {
  type FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  memo
} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {splitSearchByFirstBraces} from '@englex/trainer';
import {useLocation} from 'react-router-dom';

import {type AppState} from 'store/interface';
import {requestDictionaryInstanceChunk} from 'store/dictionary/requests';
import {clearAllEntries, updateAllEntries} from 'store/dictionary/actions';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import {type AxiosResponseAction} from 'services/axios/interface';
import {dictionaryPageSize} from 'config/static';
import InfiniteScroll from 'components/InfiniteScroll';
import Loader from 'components/Loader';
import DateSeparator from 'components/DateSeparator/DateSeparator';
import WampErrorMask from 'components/WampErrorMask';
import {type DictionaryEntryInstance} from 'components/Dictionary/shared/interface';
import {WordsList} from 'components/Dictionary/shared/WordsList/WordsList';
import {WordEntry} from 'components/Dictionary/shared/WordsList/WordEntry';
import {useScrollSaver} from 'components/Dictionary/shared/WordsList/useScrollSaver';
import {useLocaleDate} from 'components/Dictionary/hooks/useLocaleDate';
import {NoEntries} from 'components/Dictionary/shared/NoEntries';
import {DictionaryArticle} from 'components/Dictionary/Sidebar/DictionaryArticle/DictionaryArticle';
import {useSearchingInODContext} from 'components/Dictionary/Sidebar/DictionaryArticle/contexts/searchingInODContext';
import {useDictionaryContext} from 'components/Dictionary/shared/contexts';

import {DateChunkController} from './DateChunkController';
import {EntryActions} from '../EntryActions';
import {useFilter} from './useFilter';
import {useHasLists} from '../../useHasLists';
import './AllEntriesPage.scss';

interface Props {}

export const AllEntriesPage: FC<Props> = memo(() => {
  const dispatch = useDispatch();

  const hasLists = useHasLists();
  const {search} = useLocation();
  const axiosDispatch = useAxiosDispatch();

  const {dictionaryOwnerId, dictionaryOwnerRole, isTeacherDictionary, hasError, setError} =
    useDictionaryContext();

  const entries = useSelector<AppState, DictionaryEntryInstance[]>(
    s => s.dictionary?.entries || []
  );
  const timezone = useSelector<AppState, string | undefined>(s => s.dictionary?.overview?.tz);
  const isReverse = useSelector<AppState, boolean>(s => !!s.dictionary?.isReverse);
  const dictionaryIsReadonly =
    useSelector<AppState, boolean>(s => !!s.studentTeachers?.isInactive) && !isTeacherDictionary;

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [shouldHide, setShouldHide] = useState(false);

  const {isSearchingInOD, isSearching} = useSearchingInODContext();
  const [scrolled, setScrolled] = useState(false);

  const pageNumber = useRef(0);
  const lastEntry = useRef<DictionaryEntryInstance>();
  const scrollbarRef = useRef<Scrollbars>(null);
  const infiniteScrollRef = useRef<InfiniteScroll>(null);
  const cancel = useRef(false);

  const [params, initialized] = useFilter(search);
  const datesRecord = useLocaleDate(entries, timezone);
  const {saveScrollPosition, scrollToSavedPosition} = useScrollSaver(scrollbarRef.current);

  const isReverseRef = useRef(isReverse);
  if (isReverseRef.current !== isReverse) {
    if (isReverse) {
      !isSearchingInOD && scrollbarRef.current?.scrollToBottom();
    } else {
      !isSearchingInOD && scrollbarRef.current?.scrollToTop();
    }
  }
  isReverseRef.current = isReverse;

  const wordSearch = useMemo(
    () => (params.search ? splitSearchByFirstBraces(params.search) : undefined),
    [params.search]
  );

  const loadEntries = useCallback(() => {
    setLoading(true);
    cancel.current = false;
    if (!pageNumber.current) setShouldHide(true);
    return axiosDispatch(
      requestDictionaryInstanceChunk(dictionaryOwnerId, dictionaryOwnerRole, {
        before: lastEntry.current?.updatedAt,
        beforeId: lastEntry.current?.id,
        ...params
      })
    )
      .then(({payload: {data}}: AxiosResponseAction<DictionaryEntryInstance[]>) => {
        if (cancel.current) return;
        saveScrollPosition(isReverse);
        pageNumber.current++;
        lastEntry.current = data.length ? data[data.length - 1] : undefined;
        if (data.length < dictionaryPageSize) setHasMore(false);
        dispatch(updateAllEntries(data, pageNumber.current));
        if (pageNumber.current === 1 && isReverse && !isSearchingInOD)
          scrollbarRef.current?.scrollToBottom();
        if (hasError) setError(false);
        setLoading(false);
      })
      .catch(() => {
        if (cancel.current) return;
        setLoading(false);
        setError(true);
      });
  }, [
    dispatch,
    axiosDispatch,
    saveScrollPosition,
    setError,
    hasError,
    dictionaryOwnerId,
    dictionaryOwnerRole,
    isReverse,
    isSearchingInOD,
    params
  ]);

  useEffect(() => {
    if (!initialized) return;
    if (infiniteScrollRef.current) infiniteScrollRef.current.pageLoaded = 0;
    cancel.current = true;
    pageNumber.current = 0;
    lastEntry.current = undefined;
    dispatch(clearAllEntries());
    setHasMore(true);
  }, [dispatch, initialized, params]);

  if (pageNumber.current === 1 && shouldHide) setShouldHide(false);

  const entriesLengthRef = useRef(entries.length);

  useEffect(() => {
    if (pageNumber.current && scrollbarRef.current && entries.length > entriesLengthRef.current) {
      const scrollHeight = scrollbarRef.current.getScrollHeight();
      const scrollTop = scrollbarRef.current.getScrollTop();
      const clientHeight = scrollbarRef.current.getClientHeight();
      if (isReverse) {
        if (scrollHeight - scrollTop <= clientHeight * 2) {
          !isSearchingInOD && scrollbarRef.current.scrollToBottom();
          setScrolled(true);
        }
      } else {
        if (scrollTop <= clientHeight) {
          !isSearchingInOD && scrollbarRef.current.scrollToTop();
          setScrolled(true);
        }
      }
    }
    if (pageNumber.current !== 1 && !scrolled && !isSearchingInOD)
      scrollToSavedPosition(isReverseRef.current);
    entriesLengthRef.current = entries.length;
  }, [entries.length, isReverse, isSearchingInOD, scrollToSavedPosition, scrolled]);

  useEffect(
    () => () => {
      setError(false);
    },
    [setError]
  );

  if (hasError) return <WampErrorMask reload={loadEntries} />;

  const isLoadingFirstPage = loading && !pageNumber.current;
  const allEntriesIsEmpty = !isLoadingFirstPage && !entries.length;

  const onScroll = () => {
    if (!scrolled) setScrolled(true);
  };

  const isReverseScrolling = isSearchingInOD ? false : isReverse;

  return (
    <div
      className={classNames('all-entries-page', {
        'initial-load': isLoadingFirstPage && !isSearchingInOD,
        'is-reverse': isReverse,
        'has-more': hasMore && !isSearchingInOD
      })}
    >
      <Scrollbars ref={scrollbarRef} autoHide={true} onScroll={onScroll}>
        {initialized && (
          <InfiniteScroll
            ref={infiniteScrollRef}
            className={allEntriesIsEmpty || isSearchingInOD ? 'empty' : undefined}
            hasMore={hasMore}
            initialLoad={!isSearchingInOD}
            isReverse={isReverseScrolling}
            loader={!isSearchingInOD ? <Loader key="loader-key" /> : undefined}
            loadMore={!isSearchingInOD ? loadEntries : undefined}
            threshold={300}
            useWindow={false}
          >
            <>
              {isSearchingInOD ? (
                <DictionaryArticle dictionaryIsReadonly={dictionaryIsReadonly} />
              ) : allEntriesIsEmpty && pageNumber.current && !isSearching ? (
                <NoEntries
                  dictionaryIsReadonly={dictionaryIsReadonly}
                  hasButton={true}
                  userId={dictionaryOwnerId}
                />
              ) : (
                <WordsList
                  shouldHide={shouldHide}
                  numberOfWords={entries.length}
                  isLoading={loading}
                >
                  {entries.map(we => (
                    <Fragment key={we.id}>
                      {datesRecord[we.id] && (
                        <DateSeparator date={datesRecord[we.id]}>
                          {!dictionaryIsReadonly && (
                            <DateChunkController
                              hasLists={hasLists}
                              updatedAt={we.updatedAt}
                              timezone={timezone!}
                            />
                          )}
                        </DateSeparator>
                      )}
                      <WordEntry
                        dictionaryIsReadonly={dictionaryIsReadonly}
                        search={wordSearch}
                        wordEntry={we}
                        hasLists={hasLists}
                      />
                    </Fragment>
                  ))}
                </WordsList>
              )}
            </>
          </InfiniteScroll>
        )}
      </Scrollbars>
      {!isLoadingFirstPage && !dictionaryIsReadonly && (
        <EntryActions
          entries={entries}
          hasLists={hasLists}
          studentId={dictionaryOwnerId}
          isTeacherDictionary={isTeacherDictionary}
        />
      )}
    </div>
  );
});
