import React, {memo, useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import classNames from 'classnames';
import {useIntl} from 'react-intl';

import {push} from 'store/router';
import {teacherDictionaryPath} from 'common/paths';
import {type AppState, type StudentTeachers} from 'store/interface';

import {DropdownTitle} from './components/DropdownTitle/DropdownTitle';
import {DropdownItem} from './components/DropdownItem/DropdownItem';

import './TeacherDictionariesBreadcrumb.scss';

interface Props {
  studentTeachers: StudentTeachers | undefined;
  changeActiveStudentTeacher: (id: number) => void;
}

export const TeacherDictionariesBreadcrumb: React.FC<Props> = memo(
  ({studentTeachers, changeActiveStudentTeacher}) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const userId = useSelector<AppState, number>(state => state.user.id!);
    const selectedStudentTeacher = useSelector<AppState, number | undefined>(
      s => s.studentTeachers?.selectedStudentTeacher
    );

    const [isOpen, setIsOpen] = useState(false);

    const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

    const redirectToMyDictionary = useCallback(() => {
      dispatch(push(teacherDictionaryPath(userId)));
      toggle();
    }, [dispatch, toggle, userId]);

    if (!studentTeachers) return null;

    const activeKeys = studentTeachers
      ? Object.keys(studentTeachers).filter(key => !studentTeachers[key].deleted_at)
      : [];

    const selectedStudent =
      selectedStudentTeacher && activeKeys.includes(selectedStudentTeacher.toString())
        ? selectedStudentTeacher
        : activeKeys
          ? Number(activeKeys[0])
          : studentTeachers[Object.keys(studentTeachers)[0]].id;

    const redirectToStudentsDictionaries = () => {
      changeActiveStudentTeacher(selectedStudent);
      toggle();
    };

    return (
      <DropdownButton
        id="teachers-dictionaries-breadcrumb-dropdown"
        title={<DropdownTitle />}
        className={classNames('btn-ico', 'teacher-dictionaries-breadcrumb')}
        noCaret={true}
        open={isOpen}
        onToggle={toggle}
      >
        <DropdownItem
          key="my dictionary"
          iconName="user"
          text={intl.formatMessage({id: 'Dictionary.MyDictionary'})}
          onClick={redirectToMyDictionary}
        />
        <DropdownItem
          key="students dictionaries"
          iconName="users"
          text={intl.formatMessage({id: 'Dictionary.StudentsDictionaries'})}
          onClick={redirectToStudentsDictionaries}
        />
      </DropdownButton>
    );
  }
);
