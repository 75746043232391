import React, {type FC, useCallback, useContext, useState} from 'react';
import Button, {type ButtonProps} from 'react-bootstrap/lib/Button';
import {FormattedMessage, useIntl} from 'react-intl';
import {TrainerActionsContext} from '@englex/trainer';

import Confirm from 'components/modals/Confirm';
import Icon from 'components/Icon';

interface Props extends ButtonProps {
  loading?: boolean;
  disabled?: boolean;
}

export const ResetProgress: FC<Props> = ({loading, disabled, ...rest}) => {
  const intl = useIntl();
  const {reset} = useContext(TrainerActionsContext);

  const [showConfirm, setShowConfirm] = useState(false);

  const show = useCallback(() => setShowConfirm(true), []);

  const hide = useCallback(() => setShowConfirm(false), []);

  const handleAccept = useCallback(() => {
    reset(() => {
      requestAnimationFrame(() => hide());
    });
  }, [reset, hide]);

  return (
    <>
      <Button
        className="reset-progress"
        bsStyle="danger"
        disabled={disabled}
        onClick={show}
        {...rest}
      >
        <Icon name="undo" />
        <FormattedMessage id="Trainer.ResetProgress" />
      </Button>
      <Confirm
        show={showConfirm}
        headerText={intl.formatMessage({id: 'Trainer.ShuffleWordsAndResetProgress'})}
        disableButtons={!!loading}
        onAccept={handleAccept}
        onDecline={hide}
      />
    </>
  );
};
