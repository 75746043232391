import React, {memo} from 'react';
import {Route, Routes} from 'react-router-dom';
import {ASSESSMENT, FLASHCARDS, LEARN, WRITE} from '@englex/trainer';

import {useDictionaryStore} from 'store/dictionary/useDictionaryStore';
import {PageNotFound} from 'routes/PageNotFound/components/PageNotFound';
import {useRole} from 'hooks/user/useRole';

import {DictionaryLayout} from './DictionaryLayout';
import {DictionaryHome} from './components/DictionaryHome';
import {HomePage} from './components/HomePage/HomePage';
import {AllEntriesPage} from './components/AllEntriesPage/AllEntriesPage';
import {ListPage} from './components/ListPage/ListPage';
import {ListTrainerLayout} from './ListTrainer/ListTrainerLayout';
import {TrainerHome} from './ListTrainer/pages/TrainerHome/TrainerHome';
import {FlashcardsTrainer} from './ListTrainer/pages/FlashCardsTrainer/FlashcardsTrainer';
import {LearnTrainer} from './ListTrainer/pages/LearnTrainer/LearnTrainer';
import {WriteTrainer} from './ListTrainer/pages/WriteTrainer/WriteTrainer';
import {AssessmentTrainer} from './ListTrainer/pages/AssessmentTrainer/AssessmentTrainer';

const DictionaryRoute = memo(() => {
  useDictionaryStore();
  const isTeacher = useRole() === 'teacher';

  const trainerRoute = (
    <Route path="train" element={<ListTrainerLayout />}>
      <Route index={true} element={<TrainerHome />} />
      <Route path="*">
        <Route path={FLASHCARDS} element={<FlashcardsTrainer />} />
        <Route path={LEARN} element={<LearnTrainer />} />
        <Route path={WRITE} element={<WriteTrainer />} />
        <Route path={ASSESSMENT} element={<AssessmentTrainer />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Route>
  );
  return (
    <Routes>
      <Route element={<DictionaryLayout />}>
        <Route index={true} element={<DictionaryHome />} />

        <Route path="student/:studentId/*">
          <Route index={true} element={<HomePage />} />

          <Route path="all" element={<AllEntriesPage />} />

          <Route path="list/:listId/*">
            <Route index={true} element={<ListPage />} />

            {!isTeacher ? trainerRoute : null}

            <Route path="*" element={<PageNotFound />} />
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Route>

        {isTeacher ? (
          <Route path="teacher/:teacherId/*">
            <Route index={true} element={<HomePage />} />

            <Route path="all" element={<AllEntriesPage />} />

            <Route path="list/:listId/*">
              <Route index={true} element={<ListPage />} />

              {isTeacher ? trainerRoute : null}

              <Route path="*" element={<PageNotFound />} />
            </Route>

            <Route path="*" element={<PageNotFound />} />
          </Route>
        ) : null}

        <Route path="*" element={<PageNotFound />} />
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
});

export default DictionaryRoute;
