import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {type AppState} from '../../store/interface';
import {type DictionaryRoute} from '../../components/Dictionary/shared/interface';

export const useHasLists = () => {
  const {studentId: studentIdParam} = useParams<Required<DictionaryRoute>>();
  const studentId = Number(studentIdParam);
  return useSelector<AppState, boolean>(s => {
    if (s.user.id === studentId) return !!s.dictionary?.lists?.length;
    return !!s.dictionary?.lists?.filter(l => l.createdById === s.user.id || l.exerciseInstanceId)
      .length;
  });
};
