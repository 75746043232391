import {useCallback} from 'react';

import {deleteEntriesFromListRequest} from 'store/dictionary/requests';
import {useApiRequest2} from 'hooks/rest/useApiRequest2';

import {type DictionaryOwnerRole, useDictionaryContext} from '../shared/contexts';

export const useDeleteEntryFromList = (
  successHandler: () => void,
  errorHandler: () => void,
  listId?: string,
  entryIds?: string[]
): [boolean, () => void] => {
  const {dictionaryOwnerId, dictionaryOwnerRole} = useDictionaryContext();

  const {isLoading, apiRequest} = useApiRequest2<[number, string[], string, DictionaryOwnerRole]>(
    deleteEntriesFromListRequest,
    successHandler,
    errorHandler
  );

  const deleteFromList = useCallback(() => {
    if (entryIds && listId) apiRequest(dictionaryOwnerId, entryIds, listId, dictionaryOwnerRole);
  }, [apiRequest, entryIds, listId, dictionaryOwnerRole, dictionaryOwnerId]);

  return [isLoading, deleteFromList];
};
