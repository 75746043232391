import React, {createContext, useState, memo, useContext} from 'react';

import {CopyListModal} from 'components/Dictionary/shared/CreateList/CopyListModal';
import {useRole} from 'hooks/user/useRole';

interface CopyListCtx {
  openCopyListModal?: (listId: string) => void;
}

const CopyListContext = createContext<CopyListCtx>({});

export const CopyListContextProvider: React.FC = memo(({children}) => {
  const isTeacher = useRole() === 'teacher';
  const [show, setShow] = useState(false);
  const [currentWordList, setCurrentWordList] = useState<string | undefined>(undefined);

  const close = () => setShow(false);
  const openCopyListModal = (listId: string) => {
    setCurrentWordList(listId);
    setShow(true);
  };

  return isTeacher ? (
    <CopyListContext.Provider value={{openCopyListModal}}>
      {children}
      <CopyListModal show={show} close={close} listId={currentWordList} />
    </CopyListContext.Provider>
  ) : (
    <>{children}</>
  );
});

export const useCopyList = (): CopyListCtx => useContext(CopyListContext);
