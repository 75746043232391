import React, {type FC, useContext, useMemo} from 'react';
import {type IntlShape, useIntl} from 'react-intl';

import {EditListActionsContext} from 'components/Dictionary/shared/contexts/list/EditListContext';
import {addEntriesToList} from 'components/Dictionary/shared/contexts/entry/actions';
import {showModal} from 'components/Dictionary/shared/contexts/actions';
import {EditEntryActionsContext} from 'components/Dictionary/shared/contexts/entry/EditEntryContext';
import {maxListEntries} from 'components/Dictionary/shared/static';
import * as toastr from 'components/toastr';
import DropDown from 'components/DropDown';
import Icon from 'components/Icon';

import './DateChunkControls.scss';

interface Props {
  chunkIds: string[];
  hasLists?: boolean;
}

export const DateChunkControls: FC<Props> = ({chunkIds, hasLists}) => {
  const intl = useIntl();

  const {dispatch} = useContext(EditEntryActionsContext);
  const editListDispatch = useContext(EditListActionsContext);
  const items = useMemo(() => getItems(intl, hasLists), [intl, hasLists]);

  const onChange = (key: string) => {
    if (key === 'add_to_new_list') {
      if (chunkIds.length > maxListEntries) {
        return toastr.error(
          '',
          intl.formatMessage({id: 'Dictionary.Toastr.TooManySelectedEntries'})
        );
      }
      dispatch(addEntriesToList(chunkIds));
      editListDispatch(showModal());
      return;
    }
    if (key === 'add_to_existing_list') {
      return dispatch(addEntriesToList(chunkIds, true));
    }
  };

  return (
    <DropDown
      className="rc-dropdown date-chunk-controls"
      items={items}
      onChange={onChange}
      value={<Icon name="plus-circle" />}
      noCaret={true}
      trigger={['click']}
    />
  );
};

const getItems = (intl: IntlShape, hasLists?: boolean) => ({
  add_to_new_list: intl.formatMessage({id: 'Dictionary.AddToNewList'}),
  add_to_existing_list: {
    value: intl.formatMessage({id: 'Dictionary.AddToExistingList'}),
    disabled: !hasLists
  }
});
