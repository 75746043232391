import React, {type FC, memo} from 'react';
import Label from 'react-bootstrap/lib/Label';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import {useDisableTrainer} from '@englex/trainer';

import Card from 'components/Cards/Card';
import Icon from 'components/Icon';
import {useDictionaryContext} from 'components/Dictionary/shared/contexts';

import {trainerTypePathCreator} from '../../../../utils';
import {type Activity} from '../../../interface';

interface Props {
  activity: Activity;
  cached: boolean;
  listId: string;
  notImplemented: boolean;
  wordsCount: number;
}

export const ActivityCard: FC<Props> = memo(
  ({activity, cached, children, listId, notImplemented, wordsCount}) => {
    const disabled = useDisableTrainer(cached, wordsCount, notImplemented);
    const {dictionaryOwnerRole, dictionaryOwnerId} = useDictionaryContext();
    return (
      <Card
        className={classNames('activity-card', activity.type, {disabled})}
        redirectsTo={trainerTypePathCreator(
          dictionaryOwnerId,
          listId,
          dictionaryOwnerRole,
          activity.type
        )}
        disabled={disabled}
      >
        {notImplemented && (
          <Label bsStyle="warning">
            <FormattedMessage id="Trainer.Soon" />
          </Label>
        )}
        <div className="content">
          <div className="icon-row">
            <Icon name={activity.icon} />
          </div>
          <div className="title-row">
            <div className="title">{activity.title}</div>
          </div>
        </div>
        <div className="activity-progress-wrapper">{children}</div>
      </Card>
    );
  }
);
