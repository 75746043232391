import React, {memo} from 'react';
import {useSelector} from 'react-redux';

import {type AppState} from 'store/interface';

import {TeachersDictionaries} from '../TeachersDictionaries/TeachersDictionaries';
import styles from './TeachersDictionaryPage.module.scss';

export const TeachersDictionaryPage: React.FC = memo(() => {
  const userId = useSelector<AppState, number>(state => state.user.id!);

  return (
    <div className={styles.teacherDictionaryMain}>
      <TeachersDictionaries userId={userId} />
    </div>
  );
});
