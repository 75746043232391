import {useCallback} from 'react';

import {requestListDelete} from 'store/dictionary/requests';
import {useApiRequest2} from 'hooks/rest/useApiRequest2';

export const useDeleteWordsList = (
  successHandler: () => void,
  errorHandler: () => void,
  listId?: string
): [boolean, () => void] => {
  const {isLoading, apiRequest} = useApiRequest2<[string]>(
    requestListDelete,
    successHandler,
    errorHandler
  );

  const deleteWordsList = useCallback(() => {
    if (listId) apiRequest(listId);
  }, [apiRequest, listId]);

  return [isLoading, deleteWordsList];
};
