import {useCallback, useEffect, useMemo, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {getDictionaryOverviewRequest, requestStudentLists} from 'store/dictionary/requests';
import {setDictionaryOverview, setStudentLists} from 'store/dictionary/actions';
import {useApiRequest} from 'hooks/rest/useApiRequest';
import {studentDictionaryPath} from 'common/paths';
import {type DictionaryList, type DictionaryOverview} from 'components/Dictionary/shared/interface';
import {
  type DictionaryOwnerRole,
  useDictionaryContext
} from 'components/Dictionary/shared/contexts';

export const useDictionaryInit = () => {
  const {pathname} = useLocation();
  const {dictionaryOwnerId, dictionaryOwnerRole} = useDictionaryContext();

  const {
    isLoading: loadingOverview,
    reload: reloadOverview,
    isError: isErrorOverview
  } = useOverviewLoader(dictionaryOwnerId, dictionaryOwnerRole, pathname);
  const {
    isLoading: loadingLists,
    reload: reloadLists,
    isError: isErrorLists
  } = useListsLoader(dictionaryOwnerId, dictionaryOwnerRole, pathname);
  return {
    isError: isErrorOverview || isErrorLists,
    isLoading: loadingOverview || loadingLists,
    reload: () => {
      reloadOverview();
      reloadLists();
    }
  };
};

const useListsLoader = (
  dictionaryOwnerId: number,
  dictionaryOwnerRole: DictionaryOwnerRole,
  pathname: string
) => {
  const dispatch = useDispatch();

  const requestAction = useMemo(
    () => requestStudentLists(dictionaryOwnerId, dictionaryOwnerRole),
    [dictionaryOwnerId, dictionaryOwnerRole]
  );
  const successHandler = useCallback(
    (dictionaryLists: DictionaryList[]) => dispatch(setStudentLists(dictionaryLists)),
    [dispatch]
  );
  const result = useApiRequest(requestAction, successHandler);

  useShouldReload(result.reload, result.isLoading, pathname, dictionaryOwnerId);
  return result;
};

const useOverviewLoader = (
  dictionaryOwnerId: number,
  dictionaryOwnerRole: DictionaryOwnerRole,
  pathname: string
) => {
  const dispatch = useDispatch();
  const requestAction = useMemo(() => {
    return getDictionaryOverviewRequest(dictionaryOwnerId, dictionaryOwnerRole);
  }, [dictionaryOwnerRole, dictionaryOwnerId]);
  const successHandler = useCallback(
    (overview: DictionaryOverview) => dispatch(setDictionaryOverview(overview)),
    [dispatch]
  );
  const result = useApiRequest(requestAction, successHandler);

  useShouldReload(result.reload, result.isLoading, pathname, dictionaryOwnerId);
  return result;
};

const useShouldReload = (
  reload: () => void,
  isLoading: boolean,
  pathname: string,
  dictionaryOwnerId: number
) => {
  const userIdRef = useRef<number>(dictionaryOwnerId);
  const initiallyLoaded = useRef<boolean>(false);

  useEffect(() => {
    if (initiallyLoaded.current && pathname === studentDictionaryPath(dictionaryOwnerId)) {
      if (userIdRef.current !== dictionaryOwnerId) {
        userIdRef.current = dictionaryOwnerId;
        return;
      }
      reload();
    }
  }, [reload, dictionaryOwnerId, pathname]);

  useEffect(() => {
    if (!isLoading) initiallyLoaded.current = true;
  }, [isLoading]);
};
