import React, {memo} from 'react';

import Card from 'components/Cards/Card';
import Icon from 'components/Icon';

import styles from './DictionaryCard.module.scss';

interface Props {
  iconName: string;
  title: string;
  text: string;
  redirectTo?: string;
  onClick?: () => void;
}

export const DictionaryCard: React.FC<Props> = memo(
  ({iconName, title, text, redirectTo, onClick}) => {
    return (
      <Card className={styles.dictionaryCard} redirectsTo={redirectTo || ''} onClick={onClick}>
        <Icon name={iconName} className={styles.icon} />
        <span className={styles.title}>{title}</span>
        <span>{text}</span>
      </Card>
    );
  }
);
