import React, {type FC, useCallback, useContext, useMemo, useState, memo} from 'react';
import RetinaImage from '@englex/react-retina-image';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import {useIsMounted} from '@englex/react-hooks/lib/useIsMounted';

import {type AppState} from 'store/interface';
import {archiveListToggle} from 'store/dictionary/requests';
import {type AxiosResponseAction} from 'services/axios/interface';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import {type DictionaryList} from 'components/Dictionary/shared/interface';
import {dictionaryListPath, teacherDictionaryListPath} from 'common/paths';
import Card from 'components/Cards/Card';
import Icon from 'components/Icon';
import * as toastr from 'components/toastr';
import {EditListActionsContext} from 'components/Dictionary/shared/contexts/list/EditListContext';
import {editList, setListToDelete} from 'components/Dictionary/shared/contexts/list/actions';
import {useDictionaryContext} from 'components/Dictionary/shared/contexts';

import {ExerciseInfoTooltip} from './ExerciseInfoTooltip';
import {ListCardFooter} from './ListCardFooter';

interface Props {
  list: DictionaryList;
}

export const ListCard: FC<Props> = memo(({list}) => {
  const isStudent = useSelector<AppState, boolean>(state => state.user.role === 'student');
  const intl = useIntl();
  const axiosDispatch = useAxiosDispatch();
  const {id, entryCount, deletedAt, image, title, exerciseInstanceId, createdById} = list;

  const editListDispatch = useContext(EditListActionsContext);
  const {dictionaryOwnerId, isTeacherDictionary} = useDictionaryContext();

  const dictionaryIsReadonly =
    useSelector<AppState, boolean>(s => !!s.studentTeachers?.isInactive) && !isTeacherDictionary;

  const userId = useSelector<AppState, number>(s => s.user.id!);

  const [isLoading, setIsLoading] = useState(false);

  const isMounted = useIsMounted();

  const isReadonly = useMemo<boolean>(() => {
    if (isStudent || exerciseInstanceId) return false;
    if (isStudent || isTeacherDictionary) return dictionaryOwnerId !== createdById;
    return userId !== createdById;
  }, [isStudent, exerciseInstanceId, isTeacherDictionary, dictionaryOwnerId, createdById, userId]);

  const renameList = useCallback(() => editListDispatch(editList(list)), [editListDispatch, list]);
  const toggleArchive = useCallback(() => {
    if (isLoading) return;
    setIsLoading(true);
    axiosDispatch<AxiosResponseAction<DictionaryList>>(
      archiveListToggle(id, deletedAt ? 'restore' : 'archive')
    )
      .then(() => {
        if (isMounted.current) setIsLoading(false);
        toastr.success(
          '',
          deletedAt
            ? intl.formatMessage({id: 'Dictionary.Toastr.RestoreSuccess'})
            : intl.formatMessage({id: 'Dictionary.Toastr.ArchiveSuccess'})
        );
      })
      .catch(() => {
        if (isMounted.current) setIsLoading(false);
        toastr.error(
          '',
          deletedAt
            ? intl.formatMessage({id: 'Dictionary.Toastr.RestoreError'})
            : intl.formatMessage({id: 'Dictionary.Toastr.ArchiveError'})
        );
      });
  }, [axiosDispatch, deletedAt, id, intl, isLoading, isMounted]);

  const deleteList = useCallback(() => {
    editListDispatch(setListToDelete(list));
  }, [list, editListDispatch]);

  const printTitle = title.replace(/\s/g, ' ');

  return (
    <Card
      className={classNames('dictionary-list-card', {'is-archived': deletedAt})}
      redirectsTo={
        isTeacherDictionary
          ? teacherDictionaryListPath(dictionaryOwnerId, id)
          : dictionaryListPath(dictionaryOwnerId, id)
      }
    >
      <div className="body">
        <figure className="cover">
          {!image ? <Icon name="virc-note" /> : <RetinaImage src={image.urls} />}
        </figure>
        <div className="content">
          <div className="title" title={printTitle}>
            {printTitle}
          </div>
          <span className="count">
            {intl.formatMessage({id: 'Dictionary.WordCount'}, {count: entryCount || 0})}
            {exerciseInstanceId && (
              <ExerciseInfoTooltip
                listId={id}
                tooltipContainerSelector=".cards.dictionary-list-cards"
              />
            )}
          </span>
        </div>
      </div>
      <ListCardFooter
        listTitle={list.title}
        dictionaryIsReadonly={dictionaryIsReadonly}
        isArchived={!!deletedAt}
        isLoading={isLoading}
        isReadonly={isReadonly}
        isStudent={isStudent}
        listId={list.id}
        userId={dictionaryOwnerId}
        toggleArchive={toggleArchive}
        renameList={renameList}
        deleteList={deleteList}
        entryCount={list.entryCount}
        isTeacherDictionary={isTeacherDictionary}
      />
    </Card>
  );
});
