import React, {type FC, useCallback, useContext} from 'react';
import {FormattedMessage} from 'react-intl';

import Icon from 'components/Icon';

import {EditListActionsContext} from '../contexts/list/EditListContext';
import {showModal} from '../contexts/actions';
import './CreateListCard.scss';

export const CreateListCard: FC = () => {
  const dispatch = useContext(EditListActionsContext);
  return (
    <div
      className="card card-large dictionary-list-card create-list-card"
      onClick={useCallback(() => dispatch(showModal()), [dispatch])}
    >
      <Icon name="plus-circle" />
      <FormattedMessage id="Dictionary.CreateList" />
    </div>
  );
};
