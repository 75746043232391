import React, {type FC, useMemo} from 'react';
import {
  isInProgress,
  type LearnState,
  LearnStatus,
  type LearnWord,
  wordsRecordToArray
} from '@englex/trainer';

import {ActivityCounter} from '../ActivityCounter';
import {ActivityProgress} from '../ActivityProgress';

export const LearnCard: FC<{state?: LearnState}> = ({state}) =>
  !state ? null : <LearnCardInner state={state} />;

const LearnCardInner: FC<{state: LearnState}> = ({state}) => {
  const {words} = state;

  const {progress, total} = useMemo(() => {
    const wordsArray = wordsRecordToArray<LearnWord>(words);
    return {
      progress:
        wordsArray.filter(lw => lw.status === LearnStatus.mastered).length +
        wordsArray.filter(isInProgress).length / 2,
      total: wordsArray.length
    };
  }, [words]);

  return (
    <>
      <ActivityCounter progress={progress} total={total} presentation="percent" />
      <ActivityProgress progress={progress} total={total} className="learn" />
    </>
  );
};
