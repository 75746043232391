import React from 'react';
import {FormattedMessage} from 'react-intl';

import Icon from 'components/Icon';

import {type StartScreenChild} from '../../interface';
import './startScreenChildren.scss';

const FirstHint: StartScreenChild = () => <FormattedMessage id="Trainer.Write.Hint1" />;

const CardsHint: StartScreenChild = () => (
  <>
    <div className="card original">
      <span className="message">яблоко</span>
      <div className="pseudo-textarea" />
    </div>
    <div className="action-hint">
      <Icon name="keyboard-o" />
    </div>
    <div className="card translation">
      <span className="message">яблоко</span>
      <div className="pseudo-textarea">an apple</div>
    </div>
  </>
);

const SecondHint: StartScreenChild = () => <FormattedMessage id="Trainer.Write.Hint2" />;

export {CardsHint, FirstHint, SecondHint};
