import React, {type FC, useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';

interface Props {
  submit(forceCorrect?: true): void;
}

export const Footer: FC<Props> = ({submit}) => (
  <div className="footer">
    <div className="left" />
    <Button
      className="submit"
      bsStyle="primary"
      bsSize="lg"
      onClick={useCallback(() => submit(), [submit])}
    >
      <FormattedMessage id="Trainer.Continue" />
    </Button>
  </div>
);
