import React, {type FC} from 'react';
import {FormattedMessage} from 'react-intl';
import {type AssessmentState} from '@englex/trainer';

import './AssessmentCard.scss';

export const AssessmentCard: FC<{state?: AssessmentState}> = ({state}) => {
  return state ? (
    <div className="assessment-progress">
      <div className="message">
        <FormattedMessage id="Trainer.Assessment.BestScore" />:
      </div>
      <div className="best-score">
        <span>{state.bestScore === undefined ? '—' : `${state.bestScore}%`}</span>
      </div>
    </div>
  ) : null;
};
