import React, {type FC, useCallback, useContext, useEffect, useRef} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage} from 'react-intl';
import {
  answersDataToScore,
  type AssessmentState,
  getAnswersData,
  objectSize,
  submitAssessment,
  TrainerActionsContext
} from '@englex/trainer';

import {Stage} from '../../shared';
import {MultipleChoice} from './MultipleChoice/MultipleChoice';
import {Matching} from './Matching/Matching';
import {TrueFalse} from './TrueFalse/TrueFalse';
import {Write} from './Write/Write';
import {AssessmentDragLayer} from './Matching/AssessmentDragLayer';

import './AssessmentStage.scss';

export const AssessmentStage: FC<{state: AssessmentState}> = ({state}) => {
  const {dispatch, sendAnswersData} = useContext(TrainerActionsContext);

  const timeout = useRef<NodeJS.Timeout | number>();

  const submit = useCallback(() => {
    const answers = getAnswersData(state);
    sendAnswersData(answers);

    dispatch(submitAssessment(answersDataToScore(answers, objectSize(state.words))));

    timeout.current = setTimeout(() => {
      document
        .getElementById('assessment-results-header')
        ?.scrollIntoView({block: 'start', behavior: 'smooth'});
    }, 500);
  }, [dispatch, sendAnswersData, state]);

  const closed = state.score !== undefined;

  useEffect(() => () => clearTimeout(timeout.current as number), []);

  return (
    <Stage className="assessment" withScrollListener={true}>
      {closed ? (
        <div className="results-header" id="assessment-results-header">
          <div className="appeal">
            <FormattedMessage id="Trainer.Assessment.ResultHeader" />:
          </div>
          <div className="percentage">{state.score}%</div>
        </div>
      ) : null}
      <div className="assessment-canvas">
        {!!state.multipleChoice && (
          <MultipleChoice
            dispatch={dispatch}
            exercise={state.multipleChoice}
            words={state.words}
            closed={closed}
          />
        )}
        {!!state.matching && (
          <Matching
            dispatch={dispatch}
            exercise={state.matching}
            words={state.words}
            closed={closed}
          />
        )}
        {!!state.trueFalse && (
          <TrueFalse
            dispatch={dispatch}
            exercise={state.trueFalse}
            words={state.words}
            closed={closed}
          />
        )}
        {!!state.write && (
          <Write dispatch={dispatch} exercise={state.write} words={state.words} closed={closed} />
        )}
        {closed ? null : (
          <div className="footer">
            <Button bsStyle="primary" bsSize="lg" onClick={submit}>
              <FormattedMessage id="Trainer.Assessment.ShowResults" />
            </Button>
          </div>
        )}
      </div>
      <AssessmentDragLayer />
    </Stage>
  );
};
