import React, {type FC, useContext, useEffect} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {
  checkIfTrainStarted,
  setCurrentTrainerType,
  TrainerActionsContext,
  TrainerContext
} from '@englex/trainer';
import {useOutletContext} from 'react-router-dom';

import Cards from 'components/Cards/Cards';
import Loader from 'components/Loader';

import ListHeader from '../../../components/ListPage/ListHeader';
import {useActivities} from '../../hooks/useActivities';
import {ActivityCard} from './components/cards';
import {ResetProgress} from './components/ResetProgress';
import {cardFactory} from './utils';
import {type TrainerOutletContext} from '../interface';

import './TrainerHome.scss';

export const TrainerHome: FC = () => {
  const {list} = useOutletContext<TrainerOutletContext>();
  const {listId, trainerState} = useContext(TrainerContext);
  const {dispatch} = useContext(TrainerActionsContext);
  const activities = useActivities();

  useEffect(() => dispatch(setCurrentTrainerType()), [dispatch]);

  const started = checkIfTrainStarted(trainerState);

  const {loading} = trainerState;

  return (
    <div className="trainer-home">
      <Scrollbars>
        <ListHeader list={list} />
        {trainerState.loading ? (
          <Loader />
        ) : (
          <Cards className="trainer-activity-cards">
            {activities.map((activity, i) => {
              const Component = cardFactory(activity.type);
              const cachedState = trainerState[activity.type];
              return (
                <ActivityCard
                  key={i}
                  activity={activity}
                  listId={listId}
                  notImplemented={!Component}
                  wordsCount={list.entryCount!}
                  cached={!!cachedState}
                >
                  {Component ? <Component state={cachedState} /> : null}
                </ActivityCard>
              );
            })}
          </Cards>
        )}
        <ResetProgress loading={loading} disabled={!started} />
      </Scrollbars>
    </div>
  );
};
