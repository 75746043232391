import React, {type FC, memo, useCallback, useEffect, useState} from 'react';
import {Outlet, useParams} from 'react-router-dom';

import {requestDictionaryList} from 'store/dictionary/requests';
import {type AxiosRequestError} from 'services/axios/interface';
import {useApiRequest2} from 'hooks/rest/useApiRequest2';
import {PageNotFound} from 'routes/PageNotFound/components/PageNotFound';
import {type DictionaryListWithEntries} from 'components/Dictionary/shared/interface';
import Loader from 'components/Loader';
import WampErrorMask from 'components/WampErrorMask';

import {type DictionaryListUrlParams} from '../utils';

export const TrainerRoute: FC = memo(() => {
  const {listId} = useParams<DictionaryListUrlParams>();
  const [list, setList] = useState<DictionaryListWithEntries | undefined>();
  const [show404, setShow404] = useState(false);

  const {apiRequest, isLoading, isError} = useApiRequest2<
    [string, string[]],
    DictionaryListWithEntries
  >(requestDictionaryList, setList, (e: AxiosRequestError) => {
    if (e.error.response?.status === 403) {
      setShow404(true);
    }
  });

  const load = useCallback(
    () =>
      apiRequest(listId!, ['overview', 'image', 'exerciseInstance.unitInstance.unit.coursebook']),
    [apiRequest, listId]
  );

  useEffect(() => load(), [load]);

  if (show404) return <PageNotFound />;
  if (isError) return <WampErrorMask reload={load} />;

  return isLoading || !list ? <Loader /> : <Outlet context={{list}} />;
});
