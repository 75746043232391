import React, {type FC, useCallback, useContext, useEffect, useMemo, useRef} from 'react';
import {type Immutable} from 'immer';
import {Swiper, type SwiperRef, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';
import isHotkey from 'is-hotkey';
import {setPos, TrainerActionsContext, type Word, wordsRecordToArray} from '@englex/trainer';

import {generateKey} from '../../../utils';
import {sliderSpeed} from '../../../static';
import {Flashcard} from './Flashcard';
import {Arrow} from './Arrow';

import './Flashcards.scss';

interface Props {
  inverse?: true;
  pos: number;
  words: Immutable<Record<string, Word>>;
}

export const Flashcards: FC<Props> = React.memo(
  ({inverse, pos, words}) => {
    const {dispatch} = useContext(TrainerActionsContext);

    const sliderRef = useRef<SwiperRef | null>(null);

    const {key, wordsArray} = useMemo(() => {
      const wordsArray = wordsRecordToArray(words);
      const key = generateKey();
      return {key, wordsArray};
    }, [words]);
    const index = sliderRef.current?.swiper?.activeIndex;
    const arrowIsFirst = index === 0;
    const arrowIsLast = index === wordsArray.length - 1;

    const keyboardListener = useCallback((e: KeyboardEvent) => {
      window.requestAnimationFrame(() => {
        if (['TEXTAREA', 'INPUT'].includes((e.target as HTMLElement)?.tagName)) return;
        if (isHotkey('arrowleft', e)) {
          sliderRef.current?.swiper?.slidePrev();
        }
        if (isHotkey('arrowright', e)) {
          sliderRef.current?.swiper?.slideNext();
        }
      });
    }, []);

    const onSlideChange = useCallback(() => {
      dispatch(setPos(sliderRef.current?.swiper?.activeIndex!));
    }, [dispatch]);

    useEffect(() => {
      document.addEventListener('keydown', keyboardListener);
      return () => document.removeEventListener('keydown', keyboardListener);
    }, [keyboardListener]);

    return (
      <Swiper
        key={key}
        ref={sliderRef}
        className="flashcards"
        initialSlide={pos}
        speed={sliderSpeed}
        onSlideChange={onSlideChange}
        modules={[Navigation]}
        navigation={{
          prevEl: '.left',
          nextEl: '.right'
        }}
      >
        {wordsArray.map((word, i) => (
          <SwiperSlide key={i}>
            <Flashcard index={i} inverse={inverse} word={word} />
          </SwiperSlide>
        ))}
        <Arrow direction="left" disabled={arrowIsFirst} />
        <Arrow direction="right" disabled={arrowIsLast} />
      </Swiper>
    );
  },
  (p, n) => p.inverse === n.inverse && p.words === n.words
);
