import React, {type FC, useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';

import {push} from 'store/router';
import {dictionaryPath, studentDictionaryPath} from 'common/paths';
import {Breadcrumb} from 'components/Breadcrumbs/Breadcrumb';
import {useDictionaryContext} from 'components/Dictionary/shared/contexts';
import {type AppState} from 'store/interface';

interface Props {
  clickable: boolean;
}

export const DictionaryBreadcrumb: FC<Props> = ({clickable}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {dictionaryOwnerId} = useDictionaryContext();
  const isStudent = useSelector<AppState, boolean>(s => s.user.role === 'student');

  const onClick = useCallback(() => {
    if (!clickable) return;
    dispatch(push(isStudent ? studentDictionaryPath(dictionaryOwnerId) : dictionaryPath));
  }, [clickable, dictionaryOwnerId, dispatch, isStudent]);

  const title = intl.formatMessage({id: 'Vocabulary'});

  return (
    <Breadcrumb
      title={title}
      icon="virc-vocabulary"
      className={classNames('dictionary-breadcrumb', {clickable})}
      withDivider={false}
      onClick={onClick}
    />
  );
};
