import React, {type FC, memo} from 'react';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router-dom';

import Cards from 'components/Cards/Cards';
import {CardsTitle} from 'components/Cards/CardsTitle';
import {type DictionaryList} from 'components/Dictionary/shared/interface';

import {useListsFilter} from './useListsFilter';
import {ListCard} from './ListCard';
import {DictionaryFilter} from './DictionaryFilter';
import './ListCards.scss';
import './ListCard.scss';

interface Props {
  isArchive?: true;
  lists: DictionaryList[];
}

export const ListCards: FC<Props> = memo(({children, isArchive, lists}) => {
  const {search} = useLocation();
  const intl = useIntl();
  const filteredLists = useListsFilter(lists, search);

  if (!filteredLists.length && isArchive) return null;
  return (
    <>
      <CardsTitle>
        <span>
          {isArchive
            ? intl.formatMessage({id: 'Dictionary.ArchivedLists'})
            : intl.formatMessage({id: 'Dictionary.WordLists'})}
        </span>
      </CardsTitle>
      {!isArchive && <DictionaryFilter />}
      <Cards className="dictionary-list-cards">
        {filteredLists.map(l => (
          <ListCard key={l.id} list={l} />
        ))}
        {children}
      </Cards>
    </>
  );
});
