import React, {type FC, useCallback, useMemo} from 'react';
import classNames from 'classnames';
import {
  type Word,
  CompareType,
  useWordState,
  splitWordByBraces,
  stripWordCurlyBrackets
} from '@englex/trainer';

import {
  FlashcardLayout,
  Footer,
  IncorrectHeader,
  IncorrectInfo,
  PronunciationBtn
} from '../../../shared';
import {longTextBoundary} from '../../../../static';
import {Input} from './Input';

interface Props {
  word: Word;
  relatedWords: Word[];
  submitAnswer(isCorrect: boolean): void;
}

export const Flashcard: FC<Props> = React.memo(({submitAnswer, word, relatedWords}) => {
  const {correct, ff, incorrect, submitted, setValue, submit, value, checkValue} = useWordState(
    word.id,
    submitAnswer
  );
  const {original, processedOriginal} = useMemo(() => {
    const {textBeforeBraces} = splitWordByBraces(word.original);

    return {
      original: textBeforeBraces,
      processedOriginal: stripWordCurlyBrackets(textBeforeBraces)
    };
  }, [word.original]);

  const check = useCallback(
    () =>
      checkValue(
        {...word, original},
        {compare: CompareType.Original, caseInsensitive: true, relatedWords}
      ),
    [checkValue, word, original, relatedWords]
  );

  const changeValue = useCallback(
    (value: string) => setValue({...word, original: value}),
    [word, setValue]
  );

  const {original: userAnswer = ''} = value || {};

  return (
    <FlashcardLayout>
      {incorrect && (
        <>
          <IncorrectHeader />
          {word.pronunciationUrl && <PronunciationBtn pronunciationUrl={word.pronunciationUrl} />}
        </>
      )}
      <div className={classNames('body', {incorrect})}>
        <div className={classNames('task', {long: word.translation.length > longTextBoundary})}>
          <span>{word.translation}</span>
        </div>
        {incorrect ? (
          <IncorrectInfo original={processedOriginal} value={userAnswer} />
        ) : (
          <Input disabled={submitted} setValue={changeValue} value={userAnswer} />
        )}
      </div>
      <Footer
        check={check}
        correct={correct}
        ff={ff}
        submit={submit}
        valueIsEmpty={!userAnswer.length}
      />
    </FlashcardLayout>
  );
});
