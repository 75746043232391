import React, {type FC, useCallback, useContext, memo} from 'react';
import {useIntl} from 'react-intl';

import Confirm from 'components/modals/Confirm';
import * as toastr from 'components/toastr';

import {useDeleteEntriesFromDictionary} from '../../hooks/useDeleteEntriesFromDictionary';
import {type EntrySelectionMode} from '../contexts/entry/interface';
import {EditEntryActionsContext} from '../contexts/entry/EditEntryContext';
import {exitSelectionMode, setSelectionAction} from '../contexts/entry/actions';

interface Props {
  selectionMode?: EntrySelectionMode;
}

export const DeleteEntriesFromDictionaryModal: FC<Props> = memo(({selectionMode}) => {
  const intl = useIntl();

  const {dispatch} = useContext(EditEntryActionsContext);

  const onDecline = useCallback(() => {
    dispatch(setSelectionAction());
  }, [dispatch]);

  const [submitting, deleteEntriesFromModal] = useDeleteEntriesFromDictionary(
    () => {
      toastr.success(
        '',
        intl.formatMessage({id: 'Dictionary.Toastr.EntriesDeletedFromDictionarySuccess'})
      );
      dispatch(exitSelectionMode());
    },
    () => {
      toastr.error(
        '',
        intl.formatMessage({id: 'Dictionary.Toastr.EntriesDeletedFromDictionaryError'})
      );
    },
    selectionMode?.entryIds
  );

  return (
    <Confirm
      headerText={intl.formatMessage({id: 'Dictionary.DeleteEntriesFromDictionaryModalHeader'})}
      disableButtons={submitting}
      onAccept={deleteEntriesFromModal}
      onDecline={onDecline}
      show={selectionMode?.action === 'delete'}
    />
  );
});
