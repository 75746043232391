import React, {type FC, useContext} from 'react';

import {EditEntryStateContext} from 'components/Dictionary/shared/contexts/entry/EditEntryContext';
import {DateChunkCheckbox} from 'components/Dictionary/shared/DateChunkCheckbox';
import {useChunkIds} from 'components/Dictionary/hooks/useChunkIds';

import {DateChunkControls} from './DateChunkControls';

interface Props {
  hasLists?: boolean;
  updatedAt: string;
  timezone: string;
}

export const DateChunkController: FC<Props> = ({hasLists, timezone, updatedAt}) => {
  const chunkIds = useChunkIds(timezone, updatedAt);
  const {selectionMode} = useContext(EditEntryStateContext);

  if (selectionMode) {
    return <DateChunkCheckbox chunkIds={chunkIds} selectionMode={selectionMode} />;
  }

  return <DateChunkControls hasLists={hasLists} chunkIds={chunkIds} />;
};
