import {type FC} from 'react';
import {Navigate} from 'react-router-dom';

import {useUserId} from 'hooks/user/useUserId';
import {useRole} from 'hooks/user/useRole';

import {TeachersDictionaryPage} from './TeachersDictionaryPage/TeachersDictionaryPage';

export const DictionaryHome: FC = () => {
  const isStudent = useRole() === 'student';
  const userId = useUserId();

  return isStudent ? (
    <Navigate replace={true} to={`student/${userId}`} />
  ) : (
    <TeachersDictionaryPage />
  );
};
