import React, {type FC} from 'react';
import {type LearnState, useRoundState} from '@englex/trainer';

import {Layout, MultipleChoiceStage, ScrambledStage} from './components';

interface Props extends LearnState {
  finishRound(): void;
}

export const Round: FC<Props> = ({finishRound, ...learnState}) => {
  const {currentWord, isMultipleChoice, size, submitAnswer, wordIds} = useRoundState(
    learnState,
    finishRound
  );

  return !currentWord ? null : (
    <Layout
      size={size}
      wordsLeft={wordIds.length}
      className={isMultipleChoice ? 'multiple-choice-stage' : 'scrambled-stage'}
    >
      {isMultipleChoice ? (
        <MultipleChoiceStage
          key={currentWord.id}
          submitAnswer={submitAnswer}
          words={learnState.words}
          word={currentWord}
        />
      ) : (
        <ScrambledStage key={currentWord.id} submitAnswer={submitAnswer} word={currentWord} />
      )}
    </Layout>
  );
};
