import React, {type FC, useCallback, useContext} from 'react';
import {useIntl} from 'react-intl';

import Confirm from 'components/modals/Confirm';
import * as toastr from 'components/toastr';

import {EditListActionsContext, EditListStateContext} from '../contexts/list/EditListContext';
import {setListToDelete} from '../contexts/list/actions';
import {useDeleteWordsList} from '../../hooks/useDeleteList';

export const DeleteListModal: FC = () => {
  const intl = useIntl();

  const {listToDelete} = useContext(EditListStateContext);
  const editListDispatch = useContext(EditListActionsContext);

  const [isLoading, onAccept] = useDeleteWordsList(
    () => {
      editListDispatch(setListToDelete());
      toastr.success('', intl.formatMessage({id: 'Dictionary.Toastr.DeleteListSuccess'}));
    },
    () => {
      toastr.error('', intl.formatMessage({id: 'Dictionary.Toastr.DeleteListError'}));
      editListDispatch(setListToDelete());
    },
    listToDelete?.id
  );

  const onDecline = useCallback(() => {
    editListDispatch(setListToDelete());
  }, [editListDispatch]);

  return (
    <Confirm
      disableButtons={isLoading}
      headerText={intl.formatMessage({id: 'Dictionary.List.ConfirmDelete'})}
      onAccept={onAccept}
      onDecline={onDecline}
      show={!!listToDelete}
    />
  );
};
