import React, {type FC} from 'react';
import {useParams} from 'react-router-dom';
import {TrainerContextProvider, useStorage} from '@englex/trainer';

import {useWebBackend} from 'routes/Dictionary/ListTrainer/hooks/useWebBackend';

import {type DictionaryListUrlParams} from './utils';
import {storageProxy} from './static';
import {TrainerRoute} from './pages/TrainerRoute';

export const ListTrainerLayout: FC = () => {
  const {studentId, listId} = useParams<DictionaryListUrlParams>();
  const backend = useWebBackend();
  const storage = useStorage(storageProxy, listId!);
  return (
    <TrainerContextProvider
      backend={backend}
      storage={storage}
      studentId={Number(studentId)}
      listId={listId!}
    >
      <TrainerRoute />
    </TrainerContextProvider>
  );
};
