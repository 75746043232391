import localForage from 'localforage';
import {type StorageProxy} from '@englex/trainer';

export const textBreakpointOneThird = 85;
export const longTextBoundary = 128;
export const sliderSpeed = 400;

export const storageProxy: StorageProxy = {
  getItem: localForage.getItem.bind(localForage),
  setItem: localForage.setItem.bind(localForage),
  removeItem: localForage.removeItem.bind(localForage),
  key: localForage.key.bind(localForage),
  keys: localForage.keys.bind(localForage)
};
