import {type ComponentType} from 'react';
import {type Trainers, TrainerType, type ValueOf} from '@englex/trainer';

import {StudyCard, WriteCard, LearnCard, AssessmentCard} from './components/cards';

const activityToComponentMap: Record<string, ComponentType<{state?: ValueOf<Trainers>}>> = {
  [TrainerType.FLASHCARDS]: StudyCard,
  [TrainerType.WRITE]: WriteCard,
  [TrainerType.LEARN]: LearnCard,
  [TrainerType.ASSESSMENT]: AssessmentCard
};

export const cardFactory = (type: TrainerType): null | ValueOf<typeof activityToComponentMap> =>
  activityToComponentMap[type] || null;
