import React, {type FC, useCallback, useContext, useEffect, useState, memo} from 'react';
import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import {Navigate, useOutletContext} from 'react-router-dom';
import {
  flipAllCards,
  setCurrentTrainerType,
  TrainerActionsContext,
  TrainerContext,
  useDisableTrainer,
  FLASHCARDS,
  setMeta,
  type TrainerType
} from '@englex/trainer';

import PageControls from 'components/PageControls';
import Icon from 'components/Icon';
import {useDictionaryContext} from 'components/Dictionary/shared/contexts';

import {trainerPathCreator} from '../../utils';
import {PositionDisplay} from './components/PositionDisplay';
import {RefreshButton, Stage, StartScreen, StatsRow, TrainerLayout} from '../shared';
import {Flashcards} from './components/Flashcards';
import {CardsHint, FirstHint, SecondHint} from './components/startScreenChildren';
import {type TrainerOutletContext} from '../interface';
import './FlashcardsTrainer.scss';

export const FlashcardsTrainer: FC = memo(() => {
  const listEntriesCount = useOutletContext<TrainerOutletContext>()?.list?.entryCount;
  const {listId, studentId, trainerState} = useContext(TrainerContext);
  const {dispatch} = useContext(TrainerActionsContext);
  const {dictionaryOwnerRole} = useDictionaryContext();
  const {study, meta, loading} = trainerState;

  const skip = Boolean(meta.skipStartScreen?.study);

  const [started, setStarted] = useState(skip);
  const shouldRedirect = useDisableTrainer(!!study, listEntriesCount || 0);

  const continueCallback = useCallback(() => {
    dispatch(setMeta({...meta, skipStartScreen: {...meta.skipStartScreen, study: true}}));
  }, [dispatch, meta]);

  const flipAll = useCallback(() => {
    dispatch(flipAllCards());
  }, [dispatch]);

  const setSkipStartScreen = useCallback(
    (type: TrainerType) =>
      dispatch(setMeta({...meta, skipStartScreen: {...meta.skipStartScreen, [type]: true}})),
    [dispatch, meta]
  );

  useEffect(() => {
    dispatch(setCurrentTrainerType(FLASHCARDS));
  }, [dispatch]);

  if (shouldRedirect)
    return (
      <Navigate replace={true} to={trainerPathCreator(studentId, listId, dictionaryOwnerRole)} />
    );

  const isLoading = loading || trainerState.currentType !== FLASHCARDS;

  return (
    <TrainerLayout className="flashcards">
      {!started || !study ? (
        <StartScreen
          CardsHint={CardsHint}
          FirstHint={FirstHint}
          SecondHint={SecondHint}
          loading={isLoading}
          trainerState={trainerState}
          stateExists={!!study}
          continueCallback={continueCallback}
          setStarted={setStarted}
          setSkipStartScreen={setSkipStartScreen}
        />
      ) : (
        <>
          <Stage className="flashcards">
            <div className="flashcards-container">
              <Flashcards inverse={study.inverse} pos={study.pos} words={study.words} />
              <StatsRow>
                <PositionDisplay pos={study.pos} total={Object.keys(study.words).length} />
              </StatsRow>
            </div>
          </Stage>
          <PageControls>
            <RefreshButton>
              <Icon name="undo" />
              <FormattedMessage id="Trainer.StartOver" />
            </RefreshButton>
            <Button bsStyle="success" onClick={flipAll}>
              <Icon name="refresh" />
              <FormattedMessage id="Trainer.Flashcards.FlipAll" />
            </Button>
          </PageControls>
        </>
      )}
    </TrainerLayout>
  );
});
