import React, {type FC, useCallback, useContext, memo} from 'react';
import {useIntl} from 'react-intl';

import Confirm from 'components/modals/Confirm';
import * as toastr from 'components/toastr';

import {useDeleteEntryFromList} from '../../hooks/useDeleteEntryFromList';
import {type EntrySelectionMode} from '../contexts/entry/interface';
import {EditEntryActionsContext} from '../contexts/entry/EditEntryContext';
import {exitSelectionMode, setSelectionAction} from '../contexts/entry/actions';

interface Props {
  selectionMode?: EntrySelectionMode;
}

export const DeleteEntriesFromListModal: FC<Props> = memo(({selectionMode}) => {
  const intl = useIntl();

  const {dispatch} = useContext(EditEntryActionsContext);

  const onDecline = useCallback(() => {
    dispatch(setSelectionAction());
  }, [dispatch]);

  const [submitting, deleteEntriesFromList] = useDeleteEntryFromList(
    () => {
      toastr.success('', intl.formatMessage({id: 'Dictionary.Selection.DeletionSuccess'}));
      dispatch(exitSelectionMode());
    },
    () => {
      toastr.error('', intl.formatMessage({id: 'Dictionary.Selection.DeletionError'}));
    },
    selectionMode?.listId,
    selectionMode?.entryIds
  );

  return (
    <Confirm
      headerText={intl.formatMessage({id: 'Dictionary.Selection.DeleteModalHeader'})}
      disableButtons={submitting}
      onAccept={deleteEntriesFromList}
      onDecline={onDecline}
      show={selectionMode?.action === 'delete'}
    />
  );
});
