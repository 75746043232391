import React, {type FC, useCallback, useEffect, memo} from 'react';
import {useDispatch} from 'react-redux';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage} from 'react-intl';
import isHotkey from 'is-hotkey';

import {push} from 'store/router';
import Icon from 'components/Icon';
import {useDictionaryContext} from 'components/Dictionary/shared/contexts';

import {trainerPathCreator} from '../../../utils';

interface Props {
  callback(): void;
  completedCount: number;
  listId: string;
  studentId: number;
  total: number;
}

export const ContinueOrExitBtn: FC<Props> = memo(
  ({callback, completedCount, listId, studentId, total}) => {
    const dispatch = useDispatch();
    const isExit = completedCount === total;
    const {dictionaryOwnerRole} = useDictionaryContext();

    const exit = useCallback(() => {
      dispatch(push(trainerPathCreator(studentId, listId, dictionaryOwnerRole)));
    }, [dictionaryOwnerRole, dispatch, listId, studentId]);

    const keyboardListener = useCallback(
      (e: KeyboardEvent) => {
        if (e.repeat) return;

        if (isHotkey('enter', e)) {
          if (isExit) return exit();

          callback();
        }
      },
      [callback, isExit, exit]
    );

    const onClick = useCallback(() => {
      if (isExit) {
        return dispatch(push(trainerPathCreator(studentId, listId, dictionaryOwnerRole)));
      }

      return callback();
    }, [isExit, callback, dispatch, studentId, listId, dictionaryOwnerRole]);

    useEffect(() => {
      document.addEventListener('keydown', keyboardListener);
      return () => document.removeEventListener('keydown', keyboardListener);
    }, [keyboardListener]);

    return (
      <Button bsStyle="success" onClick={onClick}>
        <Icon name={`arrow-circle-${isExit ? 'left' : 'right'}`} />
        {isExit ? (
          <FormattedMessage id="Common.Exit" />
        ) : (
          <FormattedMessage id="Trainer.Continue" />
        )}
      </Button>
    );
  }
);
