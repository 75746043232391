import React, {type FC, memo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';

import {type AppState, type StudentTeachers} from 'store/interface';
import {studentDictionaryPath, teacherDictionaryPath} from 'common/paths';
import {setSelectedStudentTeacher} from 'store/studentTeachers/actions';

import {DictionaryCard} from '../DictionaryCard/DictionaryCard';
import styles from './TeachersDictionaries.module.scss';

interface Props {
  userId: number;
}

export const TeachersDictionaries: FC<Props> = memo(({userId}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const entryCount = useSelector<AppState, number>(s => s.dictionary?.overview?.entryCount || 0);
  const selectedStudentTeacher = useSelector<AppState, number | undefined>(
    s => s.studentTeachers?.selectedStudentTeacher
  );
  const studentTeachers = useSelector<AppState, StudentTeachers | undefined>(
    s => s.studentTeachers?.studentTeachers
  );

  if (!studentTeachers) return null;

  const activeKeys = studentTeachers
    ? Object.keys(studentTeachers).filter(key => !studentTeachers[key].deleted_at)
    : [];

  const studentCount = studentTeachers ? Object.keys(studentTeachers).length : 0;

  const selectedStudent =
    selectedStudentTeacher &&
    Object.keys(studentTeachers).includes(selectedStudentTeacher.toString())
      ? selectedStudentTeacher
      : activeKeys?.length
        ? Number(activeKeys[0])
        : studentTeachers[Object.keys(studentTeachers)[0]].id;

  const onClick = () => {
    dispatch(setSelectedStudentTeacher(selectedStudent, false));
  };

  const myDictionaryTitle = intl.formatMessage({id: 'Dictionary.MyDictionary'});
  const myDictionaryText = intl.formatMessage({id: 'Dictionary.WordCount'}, {count: entryCount});

  const studentsDictionaryTitle = intl.formatMessage({id: 'Dictionary.StudentsDictionaries'});
  const studentsDictionaryText = intl.formatMessage(
    {id: 'Dictionary.Students'},
    {count: studentCount}
  );

  return (
    <div className={styles.teachersDictionaries}>
      <DictionaryCard
        iconName="user"
        title={myDictionaryTitle}
        text={myDictionaryText}
        redirectTo={teacherDictionaryPath(userId)}
      />

      <DictionaryCard
        iconName="users"
        title={studentsDictionaryTitle}
        text={studentsDictionaryText}
        onClick={onClick}
        redirectTo={
          selectedStudent && studentTeachers && studentTeachers[selectedStudent]
            ? studentDictionaryPath(studentTeachers[selectedStudent].student_id)
            : ''
        }
      />
    </div>
  );
});
