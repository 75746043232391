import React, {type FC} from 'react';
import {type WriteState} from '@englex/trainer';

import {ActivityCounter} from '../ActivityCounter';
import {ActivityProgress} from '../ActivityProgress';

export const WriteCard: FC<{state?: WriteState}> = ({state}) => {
  if (!state) return null;
  const {correct, words} = state;
  const total = Object.keys(words).length;
  return (
    <>
      <ActivityCounter progress={correct.length} total={total} presentation="percent" />
      <ActivityProgress progress={correct.length} total={total} className="write" />
    </>
  );
};
