import React, {type FC, useCallback, useEffect, useMemo} from 'react';
import {type Immutable} from 'immer';
import classNames from 'classnames';
import isHotkey from 'is-hotkey';
import {
  type LearnWord,
  CompareType,
  useChoicesGenerator,
  useWordState,
  splitWordByBraces,
  stripWordCurlyBrackets
} from '@englex/trainer';

import {IncorrectHeader, IncorrectInfo, PronunciationBtn} from '../../../../shared';
import {longTextBoundary} from '../../../../../static';
import {Choice} from './Choice';
import {Footer} from './Footer';
import './MultipleChoiceStage.scss';

interface Props {
  submitAnswer(correct: boolean): void;
  word: LearnWord;
  words: Immutable<Record<string, LearnWord>>;
}

export const MultipleChoiceStage: FC<Props> = ({submitAnswer, word, words}) => {
  const {
    value: {id, translation = ''} = {},
    correct,
    incorrect,
    submitted,
    checkValue,
    setValue,
    submit
  } = useWordState(word.id, submitAnswer);
  const choices = useChoicesGenerator(words, word);

  const {textBeforeBraces: original} = useMemo(
    () => splitWordByBraces(stripWordCurlyBrackets(word.original)),
    [word.original]
  );

  const commitChoice = useCallback(
    (index: number): void => {
      setValue(choices[index]);
      checkValue(word, {
        compare: CompareType.OriginalAndTranslation,
        ignoreHomogeneousWords: true
      });
    },
    [checkValue, choices, setValue, word]
  );

  const makeChoice = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      if (submitted) return;
      const id: string | undefined = e.target['id'];
      if (id) commitChoice(Number(id.charAt(id.length - 1)));
    },
    [commitChoice, submitted]
  );

  const keyboardListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.repeat) return;

      if (['TEXTAREA', 'INPUT'].includes((e.target as HTMLElement)?.tagName)) return;
      if (!submitted) {
        for (const hotkey of ['1', '2', '3', '4']) {
          if (isHotkey(hotkey, e)) {
            e.preventDefault();
            return commitChoice(+hotkey - 1);
          }
        }
      } else {
        if (isHotkey('enter', e)) {
          e.preventDefault();
          return submit();
        }
      }
    },
    [commitChoice, submit, submitted]
  );

  useEffect(() => {
    document.addEventListener('keydown', keyboardListener);
    return () => document.removeEventListener('keydown', keyboardListener);
  }, [keyboardListener]);

  return (
    <>
      {incorrect && <IncorrectHeader />}
      {word.pronunciationUrl && <PronunciationBtn pronunciationUrl={word.pronunciationUrl} />}
      <div className="body">
        <div className={classNames('task', {long: original.length > longTextBoundary})}>
          <span>{original}</span>
        </div>
        {!incorrect ? (
          <div className={classNames('choices', {submitted})}>
            {choices.map((choice, i) => (
              <Choice
                key={i}
                correct={correct && id === choice.id}
                i={i}
                value={choice.translation}
                makeChoice={makeChoice}
              />
            ))}
          </div>
        ) : (
          <IncorrectInfo original={word.translation} value={translation} />
        )}
      </div>
      {incorrect && <Footer submit={submit} />}
    </>
  );
};
