import React, {type FC} from 'react';
import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';

import Icon from 'components/Icon';

interface Props {
  direction: 'left' | 'right';
  disabled?: boolean;
}

export const Arrow: FC<Props> = ({direction, disabled}) => (
  <Button className={classNames(`custom-arrow`, direction, {disabled})}>
    <Icon name={`chevron-${direction}`} />
  </Button>
);
