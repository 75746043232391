import React, {type FC, memo} from 'react';

import {AllEntriesCard} from './AllEntriesCard';
import './DictionaryHeader.scss';

export const DictionaryHeader: FC = memo(() => {
  return (
    <div className="dictionary-header">
      <AllEntriesCard />
    </div>
  );
});
