import React, {type FC, useCallback, useRef} from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import classNames from 'classnames';
import isHotkey from 'is-hotkey';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';

import {type AppState} from 'store/interface';

import {longTextBoundary} from '../../../../static';
import './Input.scss';

interface Props {
  disabled: boolean;
  setValue(value: string): void;
  value: string;
}

export const Input: FC<Props> = ({disabled, setValue, value}) => {
  const intl = useIntl();
  const isMobile = useSelector<AppState, boolean>(s => !!s.layout.isMobile);

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const onTextareaInput = useCallback(
    (e: React.FormEvent<HTMLTextAreaElement>) => setValue(e.currentTarget.value),
    [setValue]
  );

  const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLTextAreaElement> | KeyboardEvent) => {
    if (isHotkey(['shift+enter', 'enter'], e as KeyboardEvent)) e.preventDefault();
  }, []);

  return (
    <div className="input">
      <TextareaAutosize
        autoFocus={!isMobile}
        autoCapitalize="off"
        async={true}
        ref={textareaRef}
        placeholder={intl.formatMessage({id: 'Trainer.Write.Textarea.Placeholder'})}
        className={classNames('textarea flashcard-textarea', {
          long: value.length > longTextBoundary
        })}
        onFocus={onTextareaInput}
        onChange={onTextareaInput}
        maxLength={255}
        value={value}
        onKeyDown={onKeyDown}
        disabled={disabled}
      />
    </div>
  );
};
