import React, {type FC, memo} from 'react';
import {useSelector} from 'react-redux';
import {useIntl} from 'react-intl';

import {type AppState} from 'store/interface';
import {dictionaryAllEntriesPath, teacherDictionaryAllEntriesPath} from 'common/paths';
import {useDictionaryContext} from 'components/Dictionary/shared/contexts';

import {DictionaryCard} from '../DictionaryCard/DictionaryCard';

export const AllEntriesCard: FC = memo(() => {
  const {dictionaryOwnerId, isTeacherDictionary} = useDictionaryContext();
  const entryCount = useSelector<AppState, number>(s => s.dictionary?.overview?.entryCount || 0);

  const intl = useIntl();

  const title = intl.formatMessage({id: 'Dictionary.WholeDictionary'});
  const text = intl.formatMessage({id: 'Dictionary.WordCount'}, {count: entryCount});

  return (
    <DictionaryCard
      iconName="virc-vocabulary"
      title={title}
      text={text}
      redirectTo={
        isTeacherDictionary
          ? teacherDictionaryAllEntriesPath(dictionaryOwnerId)
          : dictionaryAllEntriesPath(dictionaryOwnerId)
      }
    />
  );
});
