import React, {type FC, useCallback, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import {type Word, splitWordByBraces, stripWordCurlyBrackets} from '@englex/trainer';

import PronunciationSoundPlayer, {
  type IntlPronunciationSoundPlayer,
  type PronunciationPlayerStatus
} from 'components/XPlayer/widgets/Vocabulary/PronunciationSoundPlayer';
import Spinner from 'components/Spinner';
import Icon from 'components/Icon';

interface Props {
  word: Word;
}

interface SpellingIconProps {
  playStatus: PronunciationPlayerStatus;
  pronunciationUrl?: string;
}

interface OriginalViewProps extends SpellingIconProps {
  original: string;
}

export const Original: FC<Props> = ({word}) => {
  const {original, pronunciationUrl} = word;
  const [playStatus, setPlayStatus] = useState<PronunciationPlayerStatus>(null);

  const playerRef = useRef<IntlPronunciationSoundPlayer | null>(null);
  const soundWithPronunciationRef = useRef<HTMLSpanElement | null>(null);

  const playPronunciation = useCallback((e: React.MouseEvent<HTMLSpanElement>) => {
    const userSelection = window.getSelection();
    if (userSelection?.toString().length) {
      const selectionRange = userSelection.getRangeAt(0);
      const parentNode = selectionRange.startContainer.parentNode;
      if (
        parentNode &&
        soundWithPronunciationRef.current &&
        parentNode === soundWithPronunciationRef.current
      ) {
        return e.stopPropagation();
      }
    }
    playerRef.current?.playSound();
  }, []);

  const viewProps = useMemo(
    () => ({
      original,
      playStatus,
      pronunciationUrl
    }),
    [original, playStatus, pronunciationUrl]
  );

  return (
    <div className="original">
      {pronunciationUrl ? (
        <PronunciationSoundPlayer
          ref={playerRef}
          onStatusChange={setPlayStatus}
          soundUrl={pronunciationUrl}
        >
          <div
            className={classNames('pronunciation-sound-player', [playStatus])}
            onClick={playPronunciation}
          >
            <OriginalView ref={soundWithPronunciationRef} {...viewProps} />
          </div>
        </PronunciationSoundPlayer>
      ) : (
        <OriginalView {...viewProps} />
      )}
    </div>
  );
};

const OriginalView = React.forwardRef<HTMLSpanElement, OriginalViewProps>(
  ({original, ...rest}, ref) => {
    const {textBeforeBraces, textInBraces} = splitWordByBraces(stripWordCurlyBrackets(original));
    return (
      <div>
        <span className="value" ref={ref}>
          {textBeforeBraces}
        </span>
        <SpellingIcon {...rest} />
        {textInBraces && <span className="grammar-commentary">{textInBraces}</span>}
      </div>
    );
  }
);

const SpellingIcon: FC<SpellingIconProps> = ({playStatus, pronunciationUrl}) => {
  if (playStatus === 'loading') return <Spinner size={14} />;
  if (pronunciationUrl) return <Icon name="volume-up" />;
  return null;
};
