import React, {type FC} from 'react';
import {type StudyState} from '@englex/trainer';

import {ActivityCounter} from '../ActivityCounter';
import {ActivityProgress} from '../ActivityProgress';

export const StudyCard: FC<{state?: StudyState}> = ({state}) => {
  if (!state) return null;
  const total = Object.keys(state.words).length;
  return (
    <>
      <ActivityCounter progress={state.pos + 1} total={total} presentation="absolute" />
      <ActivityProgress progress={state.pos + 1} total={total} className="study" />
    </>
  );
};
