import React, {type FC} from 'react';
import {FormattedMessage} from 'react-intl';

import './IncorrectHeader.scss';

export const IncorrectHeader: FC = () => (
  <h2 className="incorrect-title">
    <FormattedMessage id="Trainer.Incorrect" />!
  </h2>
);
