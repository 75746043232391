import React, {useCallback, useMemo, useState} from 'react';
import ReactResizeDetector from 'react-resize-detector';
import {useDispatch, useSelector} from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import classNames from 'classnames';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';

import {push} from 'store/router';
import {type AppState} from 'store/interface';
import {
  dictionaryAllEntriesPath,
  dictionaryListPath,
  teacherDictionaryAllEntriesPath,
  teacherDictionaryListPath
} from 'common/paths';
import {type DictionaryList} from 'components/Dictionary/shared/interface';
import Icon from 'components/Icon';
import {Breadcrumb} from 'components/Breadcrumbs/Breadcrumb';
import {useDictionaryContext} from 'components/Dictionary/shared/contexts';

import {type DictionaryListUrlParams} from '../../ListTrainer/utils';

import './ListsBreadcrumb.scss';

const elementHeight = 44;

const getMenuHeight = (lists: DictionaryList[]) => {
  const elementsCount = lists.length + 1;
  return elementHeight * (elementsCount > 4.5 ? 4.5 : elementsCount);
};

interface Props {
  breadcrumbRef: React.Ref<HTMLDivElement> | null;
  onResizeTitle: (width: number) => void;
}

export const ListsBreadcrumb: React.FC<Props> = ({breadcrumbRef, onResizeTitle}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {listId} = useParams<DictionaryListUrlParams>();
  const lists = useSelector<AppState, DictionaryList[]>(s => s.dictionary?.lists || []);
  const {dictionaryOwnerId, isTeacherDictionary} = useDictionaryContext();
  const [open, setOpen] = useState(false);
  const {openedLists, closedLists, selectedList, menuHeight} = useMemo(() => {
    const opened = lists.filter(l => !l.deletedAt);
    const closed = lists.filter(l => l.deletedAt);
    const selected = listId ? lists.find(l => l.id === listId) : undefined;
    const menuHeight = getMenuHeight(lists);
    return {openedLists: opened, closedLists: closed, selectedList: selected, menuHeight};
  }, [listId, lists]);

  const toggle = useCallback(() => setOpen(!open), [open]);

  const redirect = useCallback(
    (id?: string) => {
      if (id) {
        const path = isTeacherDictionary
          ? teacherDictionaryListPath(dictionaryOwnerId, id)
          : dictionaryListPath(dictionaryOwnerId, id);
        dispatch(push(path));
      } else {
        const path = isTeacherDictionary
          ? teacherDictionaryAllEntriesPath(dictionaryOwnerId)
          : dictionaryAllEntriesPath(dictionaryOwnerId);
        dispatch(push(path));
      }
      toggle();
    },
    [dispatch, isTeacherDictionary, dictionaryOwnerId, toggle]
  );

  const icon = selectedList ? 'virc-note' : 'virc-vocabulary';
  const titleElement = (
    <ReactResizeDetector onResize={onResizeTitle} refreshRate={16} refreshMode="throttle">
      <span>
        {selectedList ? selectedList.title : intl.formatMessage({id: 'Dictionary.WholeDictionary'})}
      </span>
    </ReactResizeDetector>
  );

  return (
    <Breadcrumb
      ref={breadcrumbRef}
      icon={icon}
      titleElement={titleElement}
      className={classNames('dictionary-lists-breadcrumb', {open})}
      withDivider={false}
      onClick={toggle}
    >
      <div className="breadcrumbs-dropdown dictionary-lists-breadcrumb-dropdown">
        <DropdownButton
          id="dictionary-lists-breadcrumb-dropdown"
          title={<Icon name="caret-down" />}
          className="btn-ico"
          noCaret={true}
          open={open}
          onToggle={toggle}
        >
          <Scrollbars autoHide={true} style={{height: menuHeight}}>
            <MenuItem
              style={{height: elementHeight}}
              onClick={() => redirect()}
              active={!selectedList?.id}
            >
              <Icon name="virc-vocabulary" />
              <span>{intl.formatMessage({id: 'Dictionary.WholeDictionary'})}</span>
            </MenuItem>
            {openedLists.map(l => (
              <DropdownItem
                key={l.id}
                elementHeight={elementHeight}
                list={l}
                redirect={redirect}
                selectedList={selectedList}
              />
            ))}
            {closedLists.map(l => (
              <DropdownItem
                key={l.id}
                elementHeight={elementHeight}
                list={l}
                redirect={redirect}
                isClosed
                selectedList={selectedList}
              />
            ))}
          </Scrollbars>
        </DropdownButton>
      </div>
    </Breadcrumb>
  );
};

interface DropdownProps {
  list: DictionaryList;
  isClosed?: boolean;
  elementHeight: number;
  selectedList?: DictionaryList;
  redirect: (id?: string) => void;
}

const DropdownItem: React.FC<DropdownProps> = ({
  list,
  isClosed,
  elementHeight,
  selectedList,
  redirect
}) => (
  <MenuItem
    key={list.id}
    style={{height: elementHeight}}
    className={classNames({closed: isClosed})}
    onClick={() => redirect(list.id)}
    active={list.id === selectedList?.id}
  >
    <Icon name="virc-note" />
    <span title={list.title} className="title">
      {list.title}
    </span>
  </MenuItem>
);
