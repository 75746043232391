import {useCallback, useMemo} from 'react';
import {type Dispatch} from 'redux-axios-middleware';
import {type Action} from 'redux';
import {type AnswerData, type Backend, type Word} from '@englex/trainer';

import {type AppState} from 'store/interface';
import {requestDictionaryList, publishEntryAnswer} from 'store/dictionary/requests';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import {type AxiosResponseAction} from 'services/axios/interface';
import {type DictionaryListWithEntries} from 'components/Dictionary/shared/interface';

const requestListAndMapToWords =
  (dispatch: Dispatch<Action, AppState>) =>
  (listId: string, expand?: string[]): Promise<Word[]> => {
    return dispatch<AxiosResponseAction<DictionaryListWithEntries>>(
      requestDictionaryList(listId, expand)
    ).then(response => {
      const entryInstances = response.payload.data.entryInstances;
      return entryInstances.map(ei => ({
        ...ei.dictionaryEntry,
        pronunciationUrl: ei.pronunciation?.url
      }));
    });
  };

export const useWebBackend = (): Backend => {
  const dispatch = useAxiosDispatch();

  const requestRemoteList = useCallback<(listId: string) => Promise<Word[]>>(
    listId => {
      return requestListAndMapToWords(dispatch)(listId);
    },
    [dispatch]
  );

  const sendAnswerData = useCallback(
    async (data: AnswerData[]) => {
      await dispatch(publishEntryAnswer(data));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      requestRemoteList,
      sendAnswerData
    }),
    [requestRemoteList, sendAnswerData]
  );
};
