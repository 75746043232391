import {useCallback} from 'react';
import {useIntl} from 'react-intl';

import {copyWordListRequest} from 'store/dictionary/requests';
import {useApiRequest2} from 'hooks/rest/useApiRequest2';
import {error, success} from 'components/toastr';

export const useCopyWordList = (close: () => void) => {
  const {formatMessage} = useIntl();

  const {isLoading, apiRequest} = useApiRequest2<[string, number[]]>(
    copyWordListRequest,
    () => {
      success('', formatMessage({id: 'Dictionary.Toastr.CopyListSuccess'}));
      close();
    },
    () => {
      error('', formatMessage({id: 'Dictionary.Toastr.CopyListError'}));
    }
  );

  const copyWordList = useCallback(
    (dictionaryListId: string, dictionaryInstanceOwnerIds: number[]) => {
      apiRequest(dictionaryListId, dictionaryInstanceOwnerIds);
    },
    [apiRequest]
  );

  return {isLoading, copyWordList};
};
