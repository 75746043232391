import {useIntl} from 'react-intl';
import {ASSESSMENT, FLASHCARDS, LEARN, WRITE} from '@englex/trainer';

import {type Activity} from '../pages/interface';

export const useActivities = (): Activity[] => {
  const intl = useIntl();
  return [
    {
      title: intl.formatMessage({id: 'Trainer.Flashcards.Title'}),
      icon: 'language',
      type: FLASHCARDS
    },
    {
      title: intl.formatMessage({id: 'Trainer.Learn.Title'}),
      icon: 'check-square-o',
      type: LEARN
    },
    {
      title: intl.formatMessage({id: 'Trainer.Write.Title'}),
      icon: 'pencil',
      type: WRITE
    },
    {
      title: intl.formatMessage({id: 'Trainer.Assessment.Title'}),
      icon: 'graduation-cap',
      type: ASSESSMENT
    }
  ];
};
