import React, {type FC, memo} from 'react';
import {useParams} from 'react-router-dom';

import {DictionaryContextProvider} from 'components/Dictionary/shared/contexts';
import {CopyListContextProvider} from 'components/Dictionary/shared/contexts/list/CopyListContext/CopyListContext';
import {useUserId} from 'hooks/user/useUserId';
import {useRole} from 'hooks/user/useRole';
import WampErrorMask from 'components/WampErrorMask';
import Loader from 'components/Loader';
import {useIsTeacherDictionary} from 'components/Dictionary/hooks/useIsTeacherDictionary';
import {type DictionaryRoute} from 'components/Dictionary/shared/interface';

import {useStudentTeachersInDictionary} from './useStudentTeachersInDictionary';
import {DictionaryPage} from './components/DictionaryPage';

export const DictionaryLayout: FC = memo(() => {
  const userId = useUserId()!;
  const role = useRole();
  const isStudent = role === 'student';
  const {isError, isLoading, reload, studentTeachers} = useStudentTeachersInDictionary(userId);

  const {studentId: studentIdParam} = useParams<DictionaryRoute>();
  const studentId = Number(studentIdParam);

  const isTeacherDictionary = useIsTeacherDictionary();

  const dictionaryOwnerId = isStudent || isTeacherDictionary ? userId : studentId;

  if (isError) return <WampErrorMask reload={reload} />;
  if (isLoading || !studentTeachers) return <Loader />;

  return (
    <DictionaryContextProvider
      isTeacherDictionary={isTeacherDictionary}
      dictionaryOwnerId={dictionaryOwnerId}
    >
      <CopyListContextProvider>
        <DictionaryPage />
      </CopyListContextProvider>
    </DictionaryContextProvider>
  );
});
