import React from 'react';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';

import Icon from 'components/Icon';

import {type StartScreenChild} from '../../interface';
import './startScreenChilren.scss';

const FirstHint: StartScreenChild = ({isMobile}) =>
  isMobile ? (
    <FormattedMessage id="Trainer.Flashcards.Hint1.Mobile" />
  ) : (
    <FormattedMessage
      id="Trainer.Flashcards.Hint1"
      values={{arrowup: <Icon name="arrow-up" />, arrowdown: <Icon name="arrow-down" />}}
    />
  );

const CardsHint: StartScreenChild = ({isMobile}) => (
  <>
    <div className="card original">
      <Icon name="volume-up" />
      <span className="message">an apple</span>
    </div>
    <div className={classNames('action-hint', {mobile: isMobile})}>
      <Icon name="hand-pointer-o" />
      {!isMobile && (
        <>
          /
          <Icon name="arrow-up" />
          /
          <Icon name="arrow-down" />
        </>
      )}
    </div>
    <div className="card translation">
      <span className="message">яблоко</span>
    </div>
  </>
);

const SecondHint: StartScreenChild = ({isMobile}) =>
  isMobile ? (
    <FormattedMessage
      id="Trainer.Flashcards.Hint2.Mobile"
      values={{chevron: <Icon name="chevron-right" />}}
    />
  ) : (
    <FormattedMessage
      id="Trainer.Flashcards.Hint2"
      values={{
        chevron: <Icon name="chevron-right" />,
        arrow: <Icon name="arrow-right" />
      }}
    />
  );

export {CardsHint, FirstHint, SecondHint};
