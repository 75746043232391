import React, {type FC} from 'react';
import {type WriteState} from '@englex/trainer';

import Icon from 'components/Icon';

import {CorrectCount, IncorrectCount, RemainingCount, StatsRow} from '../../shared';

interface Props {
  state: WriteState;
}

export const Stats: FC<Props> = ({state: {correct, incorrect, remaining}}) => (
  <StatsRow>
    <RemainingCount count={remaining.length}>
      <Icon name="pc-thin-right-arrow" />
    </RemainingCount>
    <IncorrectCount count={incorrect.length}>
      <Icon name="pc-thin-right-arrow" />
    </IncorrectCount>
    <CorrectCount count={correct.length} />
  </StatsRow>
);
