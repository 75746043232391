import React, {type FC, useCallback, useMemo} from 'react';
import {type IntlShape, useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';

import {type AppState} from 'store/interface';
import {setListSort} from 'store/dictionary/actions';
import DropDown from 'components/DropDown';
import Icon from 'components/Icon';
import {type ListSort} from 'components/Dictionary/shared/interface';

export const ListDirectionSwitcher: FC<{disabled: boolean}> = ({disabled}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const listSort = useSelector<AppState, ListSort>(s => s.dictionary?.listSort || 'alphabetic');

  const items = useMemo(() => getItems(intl), [intl]);

  const onChange = useCallback(
    (key: ListSort) => {
      if (key === listSort || disabled) return;
      dispatch(setListSort(key));
    },
    [disabled, dispatch, listSort]
  );

  return (
    <DropDown
      activeKey={listSort}
      className="rc-dropdown direction-switcher"
      items={items}
      value={<Icon name={items[listSort].icon} />}
      noCaret={true}
      onChange={onChange}
      placement="bottomRight"
      trigger={['click']}
      disabled={disabled}
    />
  );
};

const getItems = (intl: IntlShape) => ({
  alphabetic: {
    value: intl.formatMessage({id: 'Dictionary.Alphabetic'}),
    icon: 'sort-z-a'
  },
  newest_at_the_bottom: {
    value: intl.formatMessage({id: 'Dictionary.NewestAtTheBottom'}),
    icon: 'sort-1-31'
  },
  newest_at_the_top: {
    value: intl.formatMessage({id: 'Dictionary.NewestAtTheTop'}),
    icon: 'sort-31-1'
  }
});
