import React, {type FC, useCallback, useContext} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage} from 'react-intl';

import Icon from 'components/Icon';

import {EditListActionsContext} from '../contexts/list/EditListContext';
import {showModal} from '../contexts/actions';
import './CreateListButton.scss';

export const CreateListButton: FC = () => {
  const dispatch = useContext(EditListActionsContext);
  return (
    <Button
      className="create-list-button"
      bsStyle="success"
      onClick={useCallback(() => dispatch(showModal()), [dispatch])}
    >
      <Icon name="plus-circle" />
      <FormattedMessage id="Dictionary.CreateList" />
    </Button>
  );
};
