import React, {type FC, useCallback, useContext, useEffect, useState} from 'react';
import {useIsMounted} from '@englex/react-hooks/lib/useIsMounted';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import Button from 'react-bootstrap/lib/Button';
import Scrollbars from 'react-custom-scrollbars';
import classNames from 'classnames';
import {TrainerActionsContext, type TrainerState, type TrainerType} from '@englex/trainer';

import {type AppState} from 'store/interface';
import * as toastr from 'components/toastr';
import PageControls from 'components/PageControls';
import WampErrorMask from 'components/WampErrorMask';
import Loader from 'components/Loader';
import Icon from 'components/Icon';

import {RefreshButton} from './RefreshButton';
import {type StartScreenChild} from '../interface';
import './StartScreen.scss';

interface Props {
  CardsHint: StartScreenChild;
  FirstHint: StartScreenChild;
  refreshErrorMessage?: string;
  SecondHint: StartScreenChild;
  trainerState: TrainerState;
  loading?: boolean;
  startOverHint?: string;
  stateExists: boolean;
  continueCallback?(): void;
  setStarted(started: boolean): void;
  setSkipStartScreen(type: TrainerType): void;
}

export const StartScreen: FC<Props> = ({
  CardsHint,
  FirstHint,
  refreshErrorMessage,
  SecondHint,
  trainerState,
  loading,
  startOverHint,
  stateExists,
  continueCallback,
  setStarted,
  setSkipStartScreen
}) => {
  const intl = useIntl();
  const isMounted = useIsMounted();
  const [error, setError] = useState(false);
  const {loadTrainer} = useContext(TrainerActionsContext);
  const isMobile = useSelector<AppState, boolean>(s => !!s.layout.isMobile);
  const doContinue = useCallback(() => {
    continueCallback?.();
    setStarted(true);
  }, [continueCallback, setStarted]);

  const skip = Boolean(trainerState.meta.skipStartScreen?.[trainerState.currentType!]);

  const refresh = useCallback(() => {
    if (!trainerState.currentType) return;
    setError(false);
    loadTrainer(trainerState.currentType)
      .then(() => {
        if (isMounted.current) {
          setStarted(true);
          setSkipStartScreen(trainerState.currentType!);
        }
      })
      .catch(e => {
        toastr.error(
          '',
          refreshErrorMessage ?? intl.formatMessage({id: 'Trainer.Flashcards.RefreshError'})
        );
        if (!isMounted.current) return;
        const {currentType} = trainerState;
        if (currentType && !trainerState[currentType]) {
          return setError(true);
        }
        setStarted(true);
      });
  }, [
    intl,
    isMounted,
    loadTrainer,
    setSkipStartScreen,
    refreshErrorMessage,
    setStarted,
    trainerState
  ]);

  useEffect(() => {
    if (skip && !loading && !stateExists) {
      refresh();
    }
  }, [refresh, skip, loading, stateExists]);

  if (skip && !stateExists) {
    return <Loader />;
  }

  return error ? (
    <WampErrorMask reload={refresh} />
  ) : loading ? (
    <Loader />
  ) : (
    <div className="start-screen">
      <Scrollbars autoHide={true} className="start-screen-scrollbars">
        <div className="body">
          <div className="hint">
            <FirstHint isMobile={isMobile} />
          </div>
          <div className="cards">
            <CardsHint isMobile={isMobile} />
          </div>
          <div className="hint">
            <SecondHint isMobile={isMobile} />
          </div>
          {stateExists && (
            <div className="start-over-hint">
              <Icon name="info-circle" />
              {startOverHint ?? <FormattedMessage id="Trainer.StartOverHint" />}
            </div>
          )}
        </div>
      </Scrollbars>
      <PageControls className={classNames('start-screen-page-controls', {touched: stateExists})}>
        {stateExists && (
          <RefreshButton bsSize="lg" refreshHandler={refresh} onRefreshed={doContinue}>
            <FormattedMessage id="Trainer.StartOver" />
          </RefreshButton>
        )}
        <Button
          autoFocus={true}
          bsStyle="success"
          bsSize="lg"
          onClick={stateExists ? doContinue : refresh}
        >
          {stateExists ? (
            <FormattedMessage id="Trainer.Continue" />
          ) : (
            <FormattedMessage id="Trainer.Start" />
          )}
        </Button>
      </PageControls>
    </div>
  );
};
