import React, {type FC, useCallback, useEffect} from 'react';
import {useDrag} from 'react-dnd';
import classNames from 'classnames';
import {type RawWord, stripWordCurlyBrackets} from '@englex/trainer';

import {useDndEmptyImage} from 'components/dnd/useDndEmptyImage';
import DragGap from 'components/XPlayer/components/DragGap';
import {DndTypes} from 'components/dnd/interface';

import {type SelectionState} from './interface';

interface Props {
  id: string;
  setDragged(id?: string): void;
  selectionState: SelectionState;
  sourceId?: string;
  words: Record<string, RawWord>;
}

export const DragItem: FC<Props> = React.memo(
  ({id, selectionState, setDragged, sourceId, words}) => {
    const [selection, select] = selectionState;
    const [{isDragging}, drag, preview] = useDrag({
      type: DndTypes.ASSESSMENT_ITEM,
      item: {
        type: DndTypes.ASSESSMENT_ITEM,
        id,
        sourceId,
        answer: words[id].original,
        considerClientOffset: true
      },
      end: () => setDragged(undefined),
      collect: monitor => ({
        isDragging: monitor.isDragging()
      })
    });

    const onClick = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (!selection) select({itemId: id, sourceId});
        if (selection?.itemId === id) select(undefined);
      },
      [id, select, selection, sourceId]
    );

    useDndEmptyImage(preview);

    useEffect(() => {
      if (isDragging) {
        setDragged(id);
        select(undefined);
      }
    }, [id, isDragging, select, setDragged]);

    const original = stripWordCurlyBrackets(words[id].original);

    return (
      <DragGap
        onSelect={onClick}
        drag={drag}
        flexHandle={true}
        className={classNames('x-dnd-card', {
          'is-dragging': isDragging,
          selected: selection?.itemId === id
        })}
        answer={original}
      />
    );
  }
);
