import React, {type FC, memo, useCallback, useRef, useState} from 'react';
import ReactResizeDetector from 'react-resize-detector';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import classNames from 'classnames';
import {TrainerType} from '@englex/trainer';

import {push} from 'store/router';
import {type AppState} from 'store/interface';
import {studentDictionaryPath} from 'common/paths';
import {setSelectedStudentTeacher} from 'store/studentTeachers/actions';
import BreadcrumbsPanel from 'components/Breadcrumbs/BreadcrumbsPanel';
import {StudentSelector} from 'components/Dictionary/shared/StudentSelector';
import {Breadcrumb} from 'components/Breadcrumbs/Breadcrumb';
import {useDictionaryContext} from 'components/Dictionary/shared/contexts';
import {DictionarySearchPanel} from 'components/Dictionary/components/DictionarySearchPanel/DictionarySearchPanel';
import {useRole} from 'hooks/user/useRole';
import {enumValues} from 'helpers/enum';

import {Filter} from './Filter/Filter';
import {DictionaryBreadcrumb} from './DictionaryBreadcrumb';
import {ListsBreadcrumb} from './ListsBreadcrumb';
import {TrainerBreadcrumbs} from './TrainerBreadcrumbs/TrainerBreadcrumbs';
import {TeacherDictionariesBreadcrumb} from './TeacherDictionariesBreadcrumb/TeacherDictionariesBreadcrumb';
import {type TrainerUrlParams} from '../../ListTrainer/pages/interface';

import './DictionaryBreadcrumbs.scss';

const WIDTH_LIMITER = 50;

interface MatchRoute {
  all: boolean;
  list: boolean;
  trainer: boolean;
  teacher: boolean;
  dictionary: boolean;
}

export const DictionaryBreadcrumbs: FC = memo(() => {
  const studentTeachers = useSelector((s: AppState) => s.studentTeachers?.studentTeachers);
  const dispatch = useDispatch();
  const {dictionaryOwnerId, hasError} = useDictionaryContext();
  const isStudent = useRole() === 'student';
  const [showTitle, setShowTitle] = useState(true);
  const breadcrumbRef = useRef<HTMLDivElement>(null);
  const emptyBlockRef = useRef<HTMLDivElement>(null);

  const {studentId, teacherId, '*': wildcard, listId} = useParams<TrainerUrlParams>();

  const onResizeTitle = (width: number) => {
    if (width < WIDTH_LIMITER && showTitle) {
      setShowTitle(false);
    }
  };

  const onResizeEmptyBlock = (width: number) => {
    const breadcrumb = breadcrumbRef.current;
    const icon = breadcrumb?.querySelector('.breadcrumb-icon') as HTMLElement;
    const iconWidth = icon?.offsetWidth;
    const space = WIDTH_LIMITER - iconWidth;

    if (width >= space && !showTitle) {
      setShowTitle(true);
    }
  };

  const route: MatchRoute = {
    all: wildcard === 'all',
    list: !!listId,
    trainer: !!wildcard && (wildcard === 'train' || enumValues(TrainerType).includes(wildcard)),
    teacher: !isStudent && (!!teacherId || !!studentId),
    dictionary: !studentId && !teacherId && !listId && wildcard === ''
  };

  const shouldRenderSearch = route.all || (route.list && !route.trainer);

  const changeActiveStudentTeacher = useCallback(
    (id: number) => {
      if (isStudent) return;
      if (dictionaryOwnerId !== id && studentTeachers?.[id]?.student_id) {
        dispatch(setSelectedStudentTeacher(id, isStudent));
        dispatch(push(studentDictionaryPath(studentTeachers[id].student_id)));
      }
    },
    [dictionaryOwnerId, dispatch, isStudent, studentTeachers]
  );

  const clickable = isStudent ? route.all || route.list : !route.dictionary;

  return (
    <BreadcrumbsPanel
      className={classNames('dictionary-breadcrumbs-panel', {'with-search': shouldRenderSearch})}
    >
      <div className="first-line">
        <Breadcrumb>
          <DictionaryBreadcrumb clickable={clickable} />
        </Breadcrumb>
        {route.teacher && (
          <Breadcrumb>
            <TeacherDictionariesBreadcrumb
              studentTeachers={studentTeachers}
              changeActiveStudentTeacher={changeActiveStudentTeacher}
            />
          </Breadcrumb>
        )}
        {!isStudent && (
          <StudentSelector
            changeActiveStudentTeacher={changeActiveStudentTeacher}
            studentTeachers={studentTeachers}
          />
        )}
        {!route.all && !route.list ? null : (
          <>
            <Breadcrumb className={classNames('dictionary', {last: !route.trainer})}>
              <ListsBreadcrumb breadcrumbRef={breadcrumbRef} onResizeTitle={onResizeTitle} />
            </Breadcrumb>

            {!showTitle && (
              <ReactResizeDetector
                onResize={onResizeEmptyBlock}
                refreshRate={16}
                refreshMode="throttle"
              >
                <div className="empty-block" ref={emptyBlockRef}></div>
              </ReactResizeDetector>
            )}
            {!route.trainer && (
              <Filter matchesAllEntries={route.all} matchesList={route.list} disabled={hasError} />
            )}
          </>
        )}
        {route.trainer && <TrainerBreadcrumbs />}
      </div>
      {shouldRenderSearch && (
        <div className="second-line">
          <DictionarySearchPanel />
        </div>
      )}
    </BreadcrumbsPanel>
  );
});
