import React, {type FC} from 'react';
import {FormattedMessage} from 'react-intl';

interface Props {
  pos: number;
  total: number;
}

export const PositionDisplay: FC<Props> = ({pos, total}) => (
  <div className="position-display">
    <FormattedMessage id="Common.XOfY" values={{x: pos + 1, y: total}} />
  </div>
);
