import React, {type FC, useCallback, useMemo} from 'react';
import classNames from 'classnames';
import {
  type LearnWord,
  CompareType,
  useWordState,
  splitWordByBraces,
  stripWordCurlyBrackets
} from '@englex/trainer';

import {longTextBoundary} from '../../../../../static';
import {Footer, IncorrectHeader, IncorrectInfo} from '../../../../shared';
import {Keyboard} from './Keyboard';

interface Props {
  submitAnswer(correct: boolean): void;
  word: LearnWord;
}

export const ScrambledStage: FC<Props> = ({submitAnswer, word}) => {
  const {checkValue, correct, ff, incorrect, setValue, submit, value} = useWordState(
    word.id,
    submitAnswer
  );

  const {textBeforeBraces: original} = useMemo(
    () => splitWordByBraces(stripWordCurlyBrackets(word.original)),
    [word.original]
  );

  const check = useCallback(
    () => checkValue({...word, original}, {compare: CompareType.Original, caseInsensitive: true}),
    [checkValue, word, original]
  );

  const changeValue = useCallback(
    (value: string) => setValue({...word, original: value}),
    [word, setValue]
  );

  const {original: userAnswer = ''} = value || {};

  return (
    <>
      <div className="body">
        {incorrect && <IncorrectHeader />}

        <div className={classNames('task', {long: original.length > longTextBoundary})}>
          <span>{word.translation}</span>
        </div>
        {!incorrect ? (
          <Keyboard original={original} setValue={changeValue} value={userAnswer} />
        ) : (
          <IncorrectInfo original={original} value={userAnswer} />
        )}
      </div>
      <Footer correct={correct} valueIsEmpty={!value} check={check} ff={ff} submit={submit} />
    </>
  );
};
